import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FETCH_POKE_ENDPOINT } from "config/api-endpoints";

export const getPokeDataKey = "@pokeData/get_poke_details";

function getPokeData(id) {
	return client(FETCH_POKE_ENDPOINT, {
		body: {
			id
		}
	});
}

function usePokeData({ id }, options = {}) {
	return useQuery([getPokeDataKey, id], () => getPokeData(id), {
		refetchOnWindowFocus: false,
		enabled: id,
		retry: 0,
		...options
	});
}

export default usePokeData;
