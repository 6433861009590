import React from "react";
import loadable from "loadable-components";
import LastNoteCell from "common/AgTable/components/RenderCellsGlobal/LastNoteCell/LastNoteCell";

export const statusPillConfig = {
	available: {
		label: "Available",
		color: "#04AA46"
	},
	in_mission: {
		label: "On mission",
		color: "#D97706"
	},
	not_available: {
		label: "Not available",
		color: "#D97706"
	}
};

const GenericCell = loadable(() =>
	import("common/AgTable/components/RenderCellsGlobal/GenericCell/GenericCell")
);

const NotesTableCell = props => {
	const cells = {
		_id: <GenericCell data={props} isNumber />,
		created_at: <GenericCell data={props} />,
		user_id: <GenericCell data={props} />,
		created_by: <GenericCell data={props} />,
		note_type: <GenericCell data={props} />,
		job_title: <GenericCell data={props} />,
		description: <LastNoteCell data={props} showButton={false} />,
		visibility: <GenericCell data={props} />
	};
	return cells[props.column.colId];
};

export default NotesTableCell;
