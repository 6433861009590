import create from "zustand";
import { CALENDAR_VIEWS } from "../utils/constants";

const useCalendarStore = create(set => ({
	activeDay: null,
	activeDrawer: null,
	showMyCalendar: true,
	visibleCollaborativeGroup: "",
	toggleWeekends: false,
	currentCalendarView: CALENDAR_VIEWS.week,
	calendarRangeDate: null,
	selectedEventOrInterview: null,
	focusedDate: null,
	setFocusedDate: focusedDate => set(() => ({ focusedDate })),
	setSelectedEventOrInterview: selectedEventOrInterview =>
		set(() => ({ selectedEventOrInterview })),
	setCalendarRangeDate: calendarRangeDate => set(() => ({ calendarRangeDate })),
	setCurrentCalendarView: currentCalendarView =>
		set(() => ({ currentCalendarView })),
	setActiveDrawer: key => set(() => ({ activeDrawer: key })),
	setToggleWeekends: toggleWeekends => set(() => ({ toggleWeekends })),
	setActiveDay: day => set(() => ({ activeDay: day })),
	setShowMyCalendar: () =>
		set(state => ({ showMyCalendar: !state.showMyCalendar })),
	addCollaborativeGroup: groupId =>
		set(() => ({ visibleCollaborativeGroup: groupId })),
	removeCollaborativeGroup: () =>
		set(() => ({ visibleCollaborativeGroup: "" })),
	clearCalendarStore: () =>
		set(() => ({
			activeDay: null,
			activeDrawer: null,
			showMyCalendar: true,
			visibleCollaborativeGroup: "",
			toggleWeekends: false,
			currentCalendarView: CALENDAR_VIEWS.week,
			calendarRangeDate: null,
			selectedEventOrInterview: null,
			focusedDate: null
		}))
}));

export default useCalendarStore;
