import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { CANCEL_INTERVIEW } from "config/api-endpoints";

const cancelInterview = body =>
	client(CANCEL_INTERVIEW, {
		body
	});

export const useCancelInterview = options =>
	useMutation(cancelInterview, {
		...options
	});
