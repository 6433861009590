import React, { useState } from "react";
import { ReactComponent as SendIcon } from "static/icons/send-03.svg";
import { ReactComponent as FileAttachmentIcon } from "static/icons/file-attachment.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-03.svg";
import { ReactComponent as ScheduletIcon } from "static/icons/schedule.svg";
import MessagingToolNewEmailFileUploader from "../MessagingToolNewEmailFileUploader/MessagingToolNewEmailFileUploader";
import Tooltip from "common/Tippy";
import { Button } from "common/Button";
import loadable from "loadable-components";
import MessagingToolNewEmailWarningModal from "../MessagingToolNewEmailWarningModal/MessagingToolNewEmailWarningModal";
import styles from "./messaging-tool-new-email-footer.module.scss";
import cx from "classnames";
import { ALLOWED_FILES_TYPES } from "common/MessagingToolNewEmail/utils/constant";
import useDeleteDraft from "common/MessagingToolNewEmail/api/useDeleteDraft";
import toaster from "common/Toaster";
import { getLocalTimeZone, Time, today } from "@internationalized/date";
import { dateAndTimeToTimestamp } from "common/MessagingToolNewEmail/utils/helpers";
import useNotesStore from "modules/notes/notes.store";
import { queryCache } from "react-query";
import { MESSAGING_TOOL_EMAILS_LIST_KEY } from "common/MessagingToolEmailsList/hooks/useGetEmailsList";
import FieldError from "../FieldError";
import { TOUR_MAILBOX } from "config/onboarding";
import { useMailboxTour } from "modules/user-onboarding/workflows/mailbox";

const MessagingToolScheduleNewMailModal = loadable(() =>
	import(
		"../MessagingToolScheduleNewMailModal/MessagingToolScheduleNewMailModal"
	)
);

const MessagingToolNewEmailFooter = ({
	emailId,
	isLoading,
	onSchedule,
	setFiles,
	onClose,
	isDirty,
	isDraft = false,
	errors,
	disableSave
}) => {
	const [showScheduleModal, setShowScheduleModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isFilesLoading, setIsFilesLoading] = useState(false);

	const [scheduleAt, setScheduleAt] = useState({
		date: today(getLocalTimeZone()),
		time: new Time()
	});

	const [deleteDraft] = useDeleteDraft();

	const { deleteNote: deleteEmail } = useNotesStore();

	const onDelete = event => {
		event.preventDefault();
		if (!isDirty && !isDraft) {
			onClose?.();
			return;
		}
		deleteDraft(
			{ id: emailId },
			{
				onSuccess: () => {
					toaster.success("Draft delete successfully!");
					setShowDeleteModal(false);
					deleteEmail(emailId);
					onClose?.();
					queryCache.invalidateQueries(MESSAGING_TOOL_EMAILS_LIST_KEY);
				}
			}
		);
	};

	useMailboxTour({
		setShowScheduleModal
	});

	return (
		<>
			<MessagingToolNewEmailFileUploader
				allowedTypes={ALLOWED_FILES_TYPES}
				setFiles={setFiles}
				visuallyHiddenClassName={styles.visuallyHiddenClassName}
				setIsFilesLoading={setIsFilesLoading}
				isFilesLoading={isFilesLoading}
			>
				<button className={styles.attachmentButton} type="button">
					<FileAttachmentIcon className={styles.ctaIcon} />
				</button>
			</MessagingToolNewEmailFileUploader>
			<div>
				<FieldError className={styles.contentError} error={errors.content} />
			</div>
			<div className={styles.actions}>
				<Tooltip theme="dark" content="Delete draft">
					<button
						className={styles.cta}
						type="button"
						onClick={event => {
							event.preventDefault();
							setShowDeleteModal(true);
						}}
					>
						<TrashIcon className={cx(styles.ctaIcon, styles.trashIcon)} />
					</button>
				</Tooltip>
				<Tooltip content="Schedule" theme="dark">
					<button
						data-onboarding-step={`${TOUR_MAILBOX}-6`}
						className={styles.cta}
						type="button"
						onClick={() => setShowScheduleModal(true)}
					>
						<ScheduletIcon className={styles.ctaIcon} />
					</button>
				</Tooltip>
				<Button
					data-onboarding-step={`${TOUR_MAILBOX}-7`}
					text="Send"
					icon={<SendIcon className={styles.ctaIcon} />}
					className={styles.sendIcon}
					isLoading={isLoading}
					disabled={disableSave || isFilesLoading}
				/>
			</div>
			{showDeleteModal && (
				<MessagingToolNewEmailWarningModal
					description={"Are you sure you want to discard the draft message?"}
					headerTitle="Discard Draft"
					onClose={() => setShowDeleteModal(false)}
				>
					<Button
						className={cx(styles.confirmButtonClassName)}
						type="button"
						text="Discard"
						onClick={onDelete}
					/>
				</MessagingToolNewEmailWarningModal>
			)}
			{showScheduleModal && (
				<MessagingToolScheduleNewMailModal
					onClose={() => setShowScheduleModal(false)}
					onSchedule={() =>
						onSchedule({
							scheduled_at: dateAndTimeToTimestamp(scheduleAt)
						})
					}
					setScheduleAt={setScheduleAt}
					scheduleAt={scheduleAt}
					isLoading={isLoading}
					errors={errors}
				/>
			)}
		</>
	);
};

export default MessagingToolNewEmailFooter;
