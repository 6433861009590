import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";

const fetchCalendarSettings = () => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body: {
			filter: "general_settings"
		}
	});
};

export const CALENDAR_SETTINGS = "CALENDAR_SETTINGS";

const useFetchCalendarSettings = options => {
	return useQuery([CALENDAR_SETTINGS], fetchCalendarSettings, {
		refetchOnWindowFocus: false,
		retry: false,
		...options
	});
};

export default useFetchCalendarSettings;
