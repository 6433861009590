import React, { useEffect } from "react";
import Layout from "./components/layout/layout";
import {
	BREADCRUMBS_ITEMS,
	BREADCRUMBS_ITEMS_COLLABORATIVE_GROUPS
} from "./utils/constant";
import { withRouter } from "react-router";
import { getCalendarSettingsRoute } from "./getRouteConfig";
import GeneralSettingsPage from "./components/calendar-settings-page/calendar-settings-page";
import CollaborativeGroupsPage from "./components/collaborative-groups-page/collaborative-groups-page";
import useBreadCrumbsLayout from "common/Layouts/breadcrumbs.store";

const CalendarSettings = ({ location }) => {
	const { setItems } = useBreadCrumbsLayout(state => state);

	useEffect(() => {
		setItems(
			location.pathname === getCalendarSettingsRoute()
				? BREADCRUMBS_ITEMS
				: BREADCRUMBS_ITEMS_COLLABORATIVE_GROUPS
		);
	}, [location.pathname]);

	return (
		<Layout>
			{location.pathname === getCalendarSettingsRoute() ? (
				<GeneralSettingsPage />
			) : (
				<CollaborativeGroupsPage />
			)}
		</Layout>
	);
};

export default withRouter(CalendarSettings);
