import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import luxonPlugin, { toLuxonDateTime } from "@fullcalendar/luxon3";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import cx from "classnames";
import Avatar from "common/Avatar";
import { PopoverTrigger } from "common/popover-trigger";
import toaster from "common/Toaster";
import {
	ADD_INTERVIEW,
	MILLISECONDS_IN_SECOND,
	SECONDS_IN_MINUTE,
	TIME_FORMAT_24
} from "config";
import {
	convertDateAndTimeUsingTimezone,
	convertToSeconds,
	renderError
} from "config/helpers";
import { useGetUser } from "hooks/useGetUser";
import loadable from "loadable-components";
import get from "lodash/get";
import toArray from "lodash/toArray";
import { DateTime } from "luxon";
import useGetEventsList, {
	CALENDAR_EVENTS_LIST_KEY
} from "modules/calendar/api/get-events-list";
import useMoveEvent from "modules/calendar/api/useMoveEvent";
import useCalendarStore from "modules/calendar/hooks/useCalendarStore";
import {
	CALENDAR_COLUMN_TIME_FORMAT,
	CALENDAR_VIEWS,
	EVENT_MIN_DURATION,
	FOCUSED_HOUR_DIFF,
	SCHEDULE_MENU,
	SCHEDULE_POPOVER_WIDTH,
	SLOT_MIN_DURATION,
	TIME_SLOTS_FREQUENCY,
	TOUR_GUIDE_FOCUSED_DAY,
	UTC_ISO_STRING,
	WEEKEND
} from "modules/calendar/utils/constants";
import {
	formatEventPayload,
	formatEventsList
} from "modules/calendar/utils/helpers";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { DrawerContainer } from "modules/vacancy/components/vacancyView/CandidateAction/DrawerComponent/style";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import { VancancyViewProposeInterviewDrawer } from "modules/vacancy/components/vacancyView/VacancyViewProposeInterview";
import moment from "moment";
import * as React from "react";
import { queryCache } from "react-query";
import { useOverlayTriggerState } from "react-stately";
import { ReactComponent as MicrophoneIcon } from "static/icons/microphone-01.svg";
import { ReactComponent as MicrophoneOffIcon } from "static/icons/microphone-off-01.svg";
import styles from "./full-calendar-component.module.scss";
import "./full-calendar.css";
import { MINUTES_IN_HOUR } from "config";
import { formatDateUsingTimezone } from "common/Functions";
const CalendarEventInterviewDetailsModal = loadable(() =>
	import(
		"../calendar-event-interview-details-modal/calendar-event-interview-details-modal"
	)
);

const AddCalendarEventDrawer = loadable(() =>
	import("../add-calendar-event-drawer/add-calendar-event-drawer")
);
const FullCalendarComponent = React.forwardRef((_props, ref) => {
	const user = useGetUser();
	const menuState = useOverlayTriggerState({});
	const { drawerState, setDrawerState } = useVacancyStore();
	const [menuOffset, setMenuOffset] = React.useState({ top: 0, left: 0 });
	const [body, setBody] = React.useState(null);
	const [selectedItem, setSelectedItem] = React.useState(null);

	const selectedTimeFromCalendarRef = React.useRef(null);
	const { workflowId } = useOnboardingStore();

	const { data } = useGetEventsList(body, {
		enabled: !!body,
		onError: renderError
	});
	const [moveEvent] = useMoveEvent();
	const {
		toggleWeekends,
		activeDay,
		activeDrawer,
		setActiveDrawer,
		setCurrentCalendarView,
		showMyCalendar,
		visibleCollaborativeGroup,
		currentCalendarView,
		setCalendarRangeDate,
		calendarRangeDate,
		setSelectedEventOrInterview
	} = useCalendarStore();
	const setFocusedDate = useCalendarStore(state => state.setFocusedDate);

	const events = React.useMemo(
		() =>
			formatEventsList({
				events: data?.data,
				eventClassName: styles.eventClassName,
				interviewClassName: styles.interview,
				attendeeClassName: styles.attendee,
				oldEventStyle: styles.oldEventStyle,
				user
			}),
		[data]
	);

	React.useEffect(() => {
		if (calendarRangeDate) {
			ref.current?.getApi().gotoDate(
				moment
					.unix(calendarRangeDate.startTime)
					.utc()
					.format(UTC_ISO_STRING)
			);
		}
	}, []);
	React.useEffect(() => {
		if (ref?.current && !menuState.isOpen) {
			ref.current.calendar.unselect();
		}
	}, [menuState.isOpen]);

	React.useEffect(() => {
		if (calendarRangeDate) {
			setBody({
				start_time: calendarRangeDate.startTime,
				end_time: calendarRangeDate.endTime,
				list_my_events: showMyCalendar,
				group_id: visibleCollaborativeGroup || undefined
			});
			const focusedDate = ref?.current?.getApi().currentData.currentDate;
			setFocusedDate(
				convertDateAndTimeUsingTimezone(
					{
						year: focusedDate.getFullYear(),
						month: focusedDate.getMonth() + 1,
						day: focusedDate.getDate(),
						hour: 0,
						minute: 0
					},
					user.timezone
				).unix()
			);
		}
	}, [showMyCalendar, visibleCollaborativeGroup, calendarRangeDate]);

	React.useEffect(() => {
		if (workflowId) {
			ref.current?.getApi().gotoDate(new Date(TOUR_GUIDE_FOCUSED_DAY));
		} else if (activeDay) {
			setCurrentCalendarView(CALENDAR_VIEWS.day);
			ref.current?.getApi().changeView(CALENDAR_VIEWS.day);

			const dateInCalendarTimeZone = convertDateAndTimeUsingTimezone(
				{
					year: activeDay.year,
					month: activeDay.month,
					day: activeDay.day,
					hour: 0,
					minute: 0
				},
				user.timezone
			)
				.utc()
				.format(UTC_ISO_STRING);
			ref.current?.getApi().gotoDate(dateInCalendarTimeZone);
		}
	}, [activeDay]);

	function selectAllow(selectInfo) {
		const timezone = ref.current.calendar.view.dateEnv.timeZone;

		let startDateTime = toLuxonDateTime(
			selectInfo.start,
			ref.current.calendar
		).setZone(timezone);

		const currentTime = DateTime.now().setZone(timezone);
		if (ref.current.calendar.view.type === CALENDAR_VIEWS.month) {
			return startDateTime.startOf("day") >= currentTime.startOf("day");
		}
		return startDateTime > currentTime;
	}
	function onDateSelect(selectInfo) {
		let calendarApi = selectInfo.view.calendar;
		const timezone = selectInfo.view.dateEnv.timeZone;

		let startDateTime = toLuxonDateTime(
			selectInfo.start,
			ref.current.calendar
		).setZone(timezone);

		let endDateTime = toLuxonDateTime(
			selectInfo.end,
			ref.current.calendar
		).setZone(timezone);

		const currentTime = DateTime.now().setZone(timezone);

		if (
			calendarApi.view.type === CALENDAR_VIEWS.month &&
			startDateTime.hasSame(currentTime, "year") &&
			startDateTime.hasSame(currentTime, "month") &&
			startDateTime.hasSame(currentTime, "day")
		) {
			startDateTime = currentTime.plus({ minutes: 15 });
			endDateTime = startDateTime.plus({ hours: 1 });
		}

		selectedTimeFromCalendarRef.current = {
			start_date: convertToSeconds(startDateTime, user.timezone),
			end_date: convertToSeconds(endDateTime, user.timezone)
		};

		if (
			calendarApi.view.type === CALENDAR_VIEWS.month ||
			endDateTime.diff(startDateTime).get("milliseconds") /
				(MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE) ===
				SLOT_MIN_DURATION
		) {
			menuState.open();
			const left =
				document.body.clientWidth - selectInfo.jsEvent.pageX <
				SCHEDULE_POPOVER_WIDTH
					? selectInfo.jsEvent.pageX - SCHEDULE_POPOVER_WIDTH
					: selectInfo.jsEvent.pageX;
			setMenuOffset({
				top: selectInfo.jsEvent.pageY,
				left
			});
		} else {
			setActiveDrawer(SCHEDULE_MENU.default.key);
		}
	}

	const onClickScheduleOption = key => {
		if (key === SCHEDULE_MENU.interview.key) {
			setDrawerState({
				open: true,
				component: ADD_INTERVIEW,
				interview: {
					availability_slots: selectedTimeFromCalendarRef.current
						? [selectedTimeFromCalendarRef.current]
						: null
				}
			});
		} else setActiveDrawer(key);
	};

	const onEventClick = arg => {
		const id = arg.event._def.extendedProps.event_id;
		const ownerId = arg.event._def.extendedProps.owner._id;

		setSelectedItem({ id, ownerId });
	};

	const onDatesSet = info => {
		setTimeZone(info);
		setCalendarRangeDate({
			startTime: window
				.moment(info.start)
				.tz(user.timezone)
				.unix(),
			endTime: window
				.moment(info.end)
				.tz(info.timeZone)
				.unix()
		});
	};

	const onEditEvent = info => {
		const timezone = info.view.dateEnv.timeZone;
		const currentTime = DateTime.now().setZone(timezone);

		const luxonStartTime = toLuxonDateTime(
			info.event.start,
			ref.current.calendar
		).setZone(timezone);

		if (luxonStartTime < currentTime) {
			toaster.danger(
				"Events can't be scheduled in the past. Please choose a future date."
			);
			info.revert();
			return;
		}

		const startTime = window
			.moment(info.event.start)
			.tz(timezone)
			.utc()
			.unix();
		const endTime = window
			.moment(info.event.end)
			.tz(timezone)
			.utc()
			.unix();

		moveEvent(
			formatEventPayload({
				...info,
				startTime,
				endTime
			}),
			{
				onSuccess: () => {
					toaster.success("Event Scheduled successfully");
					queryCache.invalidateQueries(CALENDAR_EVENTS_LIST_KEY);
				},
				onError: err => {
					renderError(err);
					info.revert();
				}
			}
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.calendar}>
				<FullCalendar
					ref={ref}
					timeZone={user.timezone}
					scrollTime={window.moment
						.tz(user.timezone)
						.add({ hours: FOCUSED_HOUR_DIFF })
						.format(TIME_FORMAT_24)}
					allDaySlot={false}
					slotLabelFormat={CALENDAR_COLUMN_TIME_FORMAT}
					plugins={[
						dayGridPlugin,
						timeGridPlugin,
						interactionPlugin,
						luxonPlugin
					]}
					headerToolbar={false}
					nowIndicator={true}
					initialView={currentCalendarView}
					snapDuration={EVENT_MIN_DURATION}
					slotDuration={TIME_SLOTS_FREQUENCY}
					selectable={true}
					dayMaxEvents={4}
					weekends={toggleWeekends}
					events={events}
					select={onDateSelect}
					selectAllow={selectAllow}
					eventContent={info => renderEventContent(info, user)}
					dayHeaderContent={info => dayHeaderContent(info, user)}
					dayCellClassNames={handleDayCellClassNames}
					moreLinkContent={arg => <span>{arg.num} more</span>}
					moreLinkClassNames={[styles.seeMore]}
					datesSet={onDatesSet}
					eventClick={onEventClick}
					eventResize={onEditEvent}
					eventDrop={onEditEvent}
				/>
			</div>

			<PopoverTrigger
				state={menuState}
				position={{
					top: menuOffset.top,
					left: menuOffset.left
				}}
				hideArrow
				trigger={<button className={styles.hiddenButton}>Hidden button</button>}
			>
				<div>
					{toArray(SCHEDULE_MENU).map(opt => {
						return (
							<button
								key={opt.key}
								className={styles.scheduleMenuItem}
								onClick={() => {
									onClickScheduleOption(opt.key);
									menuState.close();
								}}
							>
								{opt.icon}
								<span className={styles.scheduleMenuItemText}>{opt.label}</span>
							</button>
						);
					})}
				</div>
			</PopoverTrigger>
			{drawerState.open && (
				<DrawerContainer>
					<VancancyViewProposeInterviewDrawer
						onClose={() => {
							setDrawerState({ open: false, component: "" });
							selectedTimeFromCalendarRef.current = null;
							setSelectedEventOrInterview(null);
						}}
						isFromVacanciesTable
						useVacancyAndCandidateFields
					/>
				</DrawerContainer>
			)}
			{!!selectedItem && (
				<CalendarEventInterviewDetailsModal
					id={selectedItem.id}
					ownerId={selectedItem.ownerId}
					onClose={() => {
						setSelectedItem(false);
					}}
				/>
			)}
			{activeDrawer === SCHEDULE_MENU.default.key && (
				<AddCalendarEventDrawer
					onClose={() => {
						setActiveDrawer(null);
						selectedTimeFromCalendarRef.current = null;
						setSelectedEventOrInterview(null);
					}}
					selectedTimeFromCalendar={selectedTimeFromCalendarRef.current}
				/>
			)}
		</div>
	);
});

const setTimeZone = info => {
	const luxonDate = toLuxonDateTime(info.start, info.view.calendar);
	const element = document.querySelectorAll("th.fc-timegrid-axis");
	const offset = luxonDate.offset / MINUTES_IN_HOUR;
	if (element.length === 1) {
		element[0].innerHTML = `<span class=${styles.timezone}>${
			offset > 0 ? `UTC +${offset}` : `UTC ${offset}`
		}</span>`;
	}
};

const handleDayCellClassNames = arg => {
	const luxonDate = toLuxonDateTime(arg.date, arg.view.calendar);
	if (luxonDate.isWeekend) {
		return [styles.weekend];
	}
	const isCurrentDay = luxonDate.hasSame(
		DateTime.local({
			zone: luxonDate.zoneName
		}),
		"day"
	);

	if (isCurrentDay) {
		return [styles.currentDay];
	}
	return [];
};

function dayHeaderContent(dateInfo, user) {
	const luxonDate = toLuxonDateTime(dateInfo.date, dateInfo.view.calendar);
	const today = window.moment.tz(user.timezone);
	const currentCalendarDate = window
		.moment(dateInfo.view.currentStart)
		.tz(user.timezone);
	const currentCalendarMonth = currentCalendarDate.get("month");
	const currentCalendarYear = currentCalendarDate.get("year");

	const headerText =
		dateInfo.view.type === CALENDAR_VIEWS.month
			? dateInfo.text
			: formatDateUsingTimezone(
					dateInfo.date,
					false,
					"D ddd",
					false,
					user.timezone
			  );
	const isCurrentDay =
		dateInfo.view.type === CALENDAR_VIEWS.month
			? today.format("ddd") === headerText &&
			  today.get("month") === currentCalendarMonth &&
			  today.get("year") === currentCalendarYear
			: luxonDate.hasSame(
					DateTime.local({
						zone: luxonDate.zoneName
					}),
					"day"
			  );

	const isWeekend =
		dateInfo.view.type === CALENDAR_VIEWS.month
			? WEEKEND.includes(dateInfo.text)
			: luxonDate.isWeekend;
	return (
		<span
			className={cx(styles.columnHeader, {
				[styles.isCurrentDay]: isCurrentDay,
				[styles.weekend]: isWeekend
			})}
		>
			{headerText}
		</span>
	);
}

function renderEventContent(info, user) {
	const title = get(info, "event._def.title", "");
	const owner = get(info, "event._def.extendedProps.owner", {});
	const view = get(info, "view.type", "");
	const isAttendee = get(info, "event._def.extendedProps.isAttendee");
	const isNotAttendee = get(info, "event._def.extendedProps.isNotAttendee");
	const isOrganizer = user.id === owner?._id || user.email === owner?.email;
	const isOld = get(info, "event._def.extendedProps.isOld");
	const startTime = window.moment.tz(
		get(info, "event.start", ""),
		user.timezone
	);
	const eventStartHour = String(startTime.get("hours")).padStart(2, "0");
	const eventStartMinute = String(startTime.get("minutes")).padStart(2, "0");
	return (
		<div className={styles.eventInfo}>
			{view === CALENDAR_VIEWS.month && (
				<span className={cx(styles.eventTime, isOld && styles.old)}>
					{eventStartHour}:{eventStartMinute}
				</span>
			)}
			{isAttendee ? (
				<div className={cx(styles.icon, isOld && styles.old)}>
					<MicrophoneIcon width={14} height={14} />
				</div>
			) : isNotAttendee ? (
				<div className={cx(styles.icon, isOld && styles.old)}>
					<MicrophoneOffIcon width={14} height={14} />
				</div>
			) : (
				!isOrganizer && (
					<div className={cx(styles.icon, isOld && styles.old)}>
						<Avatar
							className={styles.avatar}
							avatar={owner.avatar}
							name={owner.name || `${owner.first_name} ${owner.last_name}`}
							size={17}
						/>
					</div>
				)
			)}
			<span className={cx(styles.eventTitle, isOld && styles.old)}>
				{title}
			</span>
		</div>
	);
}

FullCalendarComponent.displayName = "FullCalendar";

export default FullCalendarComponent;
