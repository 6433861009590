import { client } from "lib/api-client";
import { useMutation } from "react-query";
import { SAVE_CALENDAR_GROUP_SETTINGS_ENDPOINT } from "config/api-endpoints";

const saveCalendarGroupSettings = body => {
	return client(SAVE_CALENDAR_GROUP_SETTINGS_ENDPOINT, {
		body
	});
};

function useSaveCalendarGroupSettings(options) {
	return useMutation(saveCalendarGroupSettings, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
}

export default useSaveCalendarGroupSettings;
