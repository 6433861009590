import { PopoverTrigger } from "common/Popover";
import React from "react";
import styles from "./collaborative-group-actions.module.scss";
import { ReactComponent as DotsIcon } from "static/icons/3-dots.svg";
import toArray from "lodash/toArray";
import {
	COLLABORATIVE_GROUP_ACTIONS,
	GROUP_ACTIONS
} from "modules/calendar/utils/constants";
import cx from "classnames";
import { historyPush, renderError } from "config/helpers";
import { getCollaborativeGroupsRoute } from "modules/calendar-settings/getRouteConfig";
import GroupsConfirmModal from "modules/calendar-settings/components/groups-confirm-modal/groups-confirm-modal";
import { Button } from "common/Button";
import useLeaveGroup from "modules/calendar/api/useLeaveGroup";
import { useOverlayTriggerState } from "react-stately";
import { queryCache } from "react-query";
import { GET_CALENDAR_COLLABORATIVE_GROUPS_KEY } from "modules/calendar/api/use-get-collaborative-groups";
import { getCollaborativeGroupInvitationRoute } from "../../../collaborative-group-invitation/collaborative-group-invitation";
import { stringify } from "query-string";
import { COLLABORATOR_GROUP_ROLES } from "modules/calendar-settings/components/collaborative-groups-page/collaborators-list/utils/constant";

const CollaborativeGroupActions = ({ group, isGroupDisabled }) => {
	const state = useOverlayTriggerState({});
	const [warningActionModal, setWarningActionModal] = React.useState(null);
	const [leaveGroup, { isLoading: isLeaveGroupLoading }] = useLeaveGroup({
		onError: renderError
	});

	const isActionDisabled = action => {
		if (
			action === COLLABORATIVE_GROUP_ACTIONS.leave_group.key &&
			group.role_name === COLLABORATOR_GROUP_ROLES.admin.value
		) {
			const adminMembers = group.members.filter(
				item => item.role_name === COLLABORATOR_GROUP_ROLES.admin.value
			);
			return adminMembers.length === 1;
		}
		return false;
	};

	const onClickGroupAction = key => {
		if (key === COLLABORATIVE_GROUP_ACTIONS.settings.key)
			historyPush(
				`${getCollaborativeGroupsRoute()}?${stringify({
					group: group._id
				})}`
			);
		else if (key === COLLABORATIVE_GROUP_ACTIONS.leave_group.key) {
			setWarningActionModal(GROUP_ACTIONS.leave);
		}
		state.close();
	};

	const onJoin = () => {
		historyPush(
			getCollaborativeGroupInvitationRoute(group._id, group.invitation._id)
		);
	};

	const onConfirmLeave = () => {
		leaveGroup(
			{
				group_id: group._id
			},
			{
				onSuccess: () => {
					setWarningActionModal(null);
					queryCache.invalidateQueries(GET_CALENDAR_COLLABORATIVE_GROUPS_KEY);
				}
			}
		);
	};
	if (isGroupDisabled)
		return (
			<Button
				text="Join"
				className={styles.actionButton}
				onClick={onJoin}
				variant="text"
			/>
		);

	return (
		<>
			<PopoverTrigger
				state={state}
				placement="bottom left"
				hideArrow
				trigger={
					<button
						className={cx(styles.dots, {
							[styles.active]: state.isOpen
						})}
						onClick={() => state.open()}
					>
						<DotsIcon className={styles.dotsIcon} width={20} height={20} />
					</button>
				}
			>
				<div>
					{toArray(COLLABORATIVE_GROUP_ACTIONS).map(opt => {
						return (
							<button
								key={opt.key}
								className={styles.actionsMenuItem}
								onClick={() => onClickGroupAction(opt.key)}
								disabled={isActionDisabled(opt.key)}
							>
								{opt.icon}
								<span className={styles.actionsMenuItemText}>{opt.label}</span>
							</button>
						);
					})}
				</div>
			</PopoverTrigger>
			{warningActionModal === GROUP_ACTIONS.leave && (
				<GroupsConfirmModal
					onClose={() => setWarningActionModal(null)}
					title={group.group_name}
					description={`Are you sure you want to leave this collaborative group? You will no longer have access to the calendar's events and availability information.`}
					btnText={"Leave Group"}
					isLoading={isLeaveGroupLoading}
					onConfirm={onConfirmLeave}
				/>
			)}
		</>
	);
};

export default CollaborativeGroupActions;
