import React from "react";
import styles from "../collaborators-list.module.scss";
import { ReactComponent as EmptyStateIcon } from "static/icons/empty-state-collaborators.svg";

const EmptyState = () => {
	return (
		<div className={styles.emptyState}>
			<div className={styles.icon}>
				<EmptyStateIcon />
			</div>
			<div className={styles.title}>No Members in the group!</div>
			<div className={styles.description}>
				Your collaborative group is currently empty.
				<br />
				Invite members to start collaborating and sharing events.
			</div>
		</div>
	);
};

export default EmptyState;
