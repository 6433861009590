import React, { useMemo } from "react";
import styles from "./calendar-timezone.module.scss";
import { Controller, useFormContext } from "react-hook-form";
import { ComboBox } from "common/ComboBox";
import { TIMEZONES_LIST } from "config/timezones";
import { Item } from "react-stately";
import Skeleton from "react-loading-skeleton";
import FieldError from "common/MessagingToolNewEmail/components/FieldError";

const CalendarTimezone = ({ isLoading }) => {
	const {
		control,
		watch,
		formState: { errors }
	} = useFormContext();
	const timezones = useMemo(
		() =>
			TIMEZONES_LIST.map(item => ({
				label: item,
				value: item
			})),
		[]
	);

	const onSelectionTimezoneChange = (key, onChange, data) => {
		const value = {
			label: data,
			value: key
		};

		onChange(value);
	};

	const onInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("timezone").value
		};
		onChange(valueObject);
	};

	if (isLoading) return <Skeleton height={44} width={243} />;
	return (
		<>
			<Controller
				name="timezone"
				control={control}
				render={({ field: { onChange, value } }) => {
					return (
						<ComboBox
							inputRootClassName={styles.combobox}
							inputValue={value?.label}
							selectedKey={value?.value}
							placeholder={"Select a timezone"}
							onChange={onChange}
							onInputChange={value => onInputChange(value, onChange)}
							onSelectionChange={value =>
								onSelectionTimezoneChange(value, onChange, value)
							}
						>
							{timezones.map(option => {
								return <Item key={option.value}>{option.label}</Item>;
							})}
						</ComboBox>
					);
				}}
			/>
			<FieldError error={errors?.timezone} className={styles.error} />
		</>
	);
};

export default CalendarTimezone;
