import toaster from "common/Toaster";

import { UPDATE_USER_TIMEZONE } from "config/api-endpoints";
import { client } from "lib/api-client";
import { getMyProfile } from "modules/user/actions/authActions";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

const updateTimezone = body => {
	return client(UPDATE_USER_TIMEZONE, { body });
};

const useUpdateTimezone = options => {
	const dispatch = useDispatch();
	return useMutation(updateTimezone, {
		onSuccess: () => {
			dispatch(getMyProfile());
			toaster.success("Timezone updated successfully!");
		},
		...options
	});
};

export default useUpdateTimezone;
