import React from "react";
import VerticalDivider from "modules/home/components/VerticalDivider.jsx";
import { ReactComponent as CalendarIcon } from "static/icons/green-calendar.svg";
import { ReactComponent as ClockIcon } from "static/icons/green-clock.svg";
import style from "modules/home/components/styles.module.scss";
import { formatDateUsingTimezone } from "common/Functions";
import { TIME_FORMAT_SYMBOL } from "config";
import { DATE_FORMAT } from "config";
import { useGetUser } from "hooks/useGetUser";

const DateTimeDisplayer = ({
	createdAt,
	interviewStartDate,
	interviewEndDate
}) => {
	const user = useGetUser();
	return (
		<div className={style.dateTimeDisplayer}>
			<VerticalDivider /> <CalendarIcon />
			<span className={style.time}>
				{formatDateUsingTimezone(
					createdAt,
					true,
					DATE_FORMAT,
					false,
					user.timezone
				)}{" "}
			</span>{" "}
			<ClockIcon />{" "}
			<span className={style.time}>
				{`${formatDateUsingTimezone(
					interviewStartDate,
					true,
					TIME_FORMAT_SYMBOL,
					false,
					user.timezone
				)} - ${formatDateUsingTimezone(
					interviewEndDate,
					true,
					TIME_FORMAT_SYMBOL,
					false,
					user.timezone
				)}`}{" "}
			</span>
		</div>
	);
};

export default DateTimeDisplayer;
