import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export const useInvitationForm = () => {
	return useForm({
		resolver: yupResolver(
			yup.object().shape({
				acceptPolicy: yup
					.boolean()
					.oneOf([true], "Please accept the Privacy Policy")
			})
		),
		shouldUnregister: false,
		defaultValues: {
			acceptPolicy: false,
			collaborators: []
		}
	});
};
