import React, { useEffect, useRef, useState } from "react";
import styles from "./vacancy-view-propose-interview-vacancy-field.module.scss";
import { ReactComponent as BriefCaseIcon } from "static/icons/quick-add/briefcase.svg";
import { ReactComponent as CheckIcon } from "static/icons/checkIcon2.svg";
import { Controller, useFormContext } from "react-hook-form";
import { OverlayProvider } from "react-aria";
import { DropDownWithSearch } from "common/DropDownWithSearch";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash/debounce";
import { FieldError } from "common/FieldError";
import useGetVacancies from "../api/useGetVacancies";
import { MAX_VACANCIES } from "../../VacancyViewProposeOffer/utils/constant";

const VacancyViewProposeInterviewVacancyField = () => {
	const ref = useRef();

	const [displayPaper, setDisplayPaper] = useState(false);
	const [debouncedSearch, setDebouncedSearch] = useState("");
	const [options, setOptions] = useState([]);

	const {
		control,
		formState: { errors },
		setValue
	} = useFormContext();
	const { data, isLoading } = useGetVacancies({
		filter: "vacancy_list",
		search: debouncedSearch,
		limit: MAX_VACANCIES
	});

	useEffect(() => {
		if (data) {
			if (debouncedSearch) {
				const regex = new RegExp(debouncedSearch, "i");
				const newOptions = data
					.filter(item => regex.test(item.title))
					.map(item => ({
						value: item._id,
						label: item.title,
						...item
					}));
				setOptions(newOptions);
			} else {
				const options = data.map(item => ({
					value: item._id,
					label: item.title,
					...item
				}));
				setOptions(options);
			}
		}
	}, [debouncedSearch, data]);

	const debouncedOnChange = React.useCallback(
		debounce(value => {
			setDebouncedSearch(value);
		}, 500),
		[]
	);

	const handleSearchChange = e => {
		let value = e.target.value;
		debouncedOnChange(value);
	};

	return (
		<div ref={ref}>
			<label className={styles.label}>
				<BriefCaseIcon />
				Vacancy
			</label>
			<Controller
				name="vacancy"
				control={control}
				render={({ field: { onChange, value } }) => (
					<OverlayProvider>
						<DropDownWithSearch
							popoverContainer={ref.current}
							variant="parent-width"
							paperClassName={styles.paperClassName}
							rootClassname={styles.rootDropDown}
							dropDownInputClassName={styles.dropDownInputClassName}
							displaySelectedValues
							onClose={() => setDisplayPaper(false)}
							displayPaper={displayPaper}
							displayFooter={false}
							onSearchChange={handleSearchChange}
							onInputClick={() => setDisplayPaper(prev => !prev)}
							value={value?.label}
							hideResetButton
							content={
								isLoading ? (
									<LoadingSekeleton />
								) : (
									<div className={styles.dropdownContent}>
										{options.map(item => (
											<div
												className={styles.entityContainer}
												key={item.value}
												onClick={() => {
													onChange(item);
													setDisplayPaper(false);
													setValue("candidate", null);
												}}
											>
												<div className={styles.checkIcon}>
													{item.value === value?.value && (
														<CheckIcon className={styles.icon} />
													)}
												</div>
												{item.label}
											</div>
										))}
									</div>
								)
							}
							placement="bottom start"
						/>
					</OverlayProvider>
				)}
			/>
			<FieldError className={styles.error} error={errors.vacancy} />
		</div>
	);
};

const LoadingSekeleton = () => {
	return Array(3)
		.fill(0)
		.map(item => (
			<div className={styles.entityContainer} key={item}>
				<Skeleton width={113} height={11} />
			</div>
		));
};

export default VacancyViewProposeInterviewVacancyField;
