/* eslint-disable no-unused-vars */
import Calendar from "common/DatePicker/Calendar/Calendar";
import * as React from "react";
import { useDatePicker } from "react-aria";
import { useDatePickerState } from "@react-stately/datepicker";
import useCalendarStore from "modules/calendar/hooks/useCalendarStore";
import styles from "./calendar-sidebar-small-calendar.module.scss";
import {
	convertDateAndTimeUsingTimezone,
	timestampToDateCalendar
} from "config/helpers";

const SidebarCalendar = ({ timezone }) => {
	const activeDay = useCalendarStore(state => state.activeDay);
	const setActiveDay = useCalendarStore(state => state.setActiveDay);
	const focusedDate = useCalendarStore(state => state.focusedDate);
	const setFocusedDate = useCalendarStore(state => state.setFocusedDate);

	let propsUpdated = {
		onChange: value => setActiveDay(value),
		value: activeDay
	};

	let state = useDatePickerState(propsUpdated);
	let ref = React.useRef();
	let { calendarProps } = useDatePicker(propsUpdated, state, ref);
	return (
		<Calendar
			{...calendarProps}
			className={styles.calendar}
			focusedValue={
				focusedDate ? timestampToDateCalendar(focusedDate, timezone) : undefined
			}
			onFocusChange={date => {
				setFocusedDate(
					convertDateAndTimeUsingTimezone(
						{
							year: date.year,
							month: date.month,
							day: date.day,
							hour: 0,
							minute: 0
						},
						timezone
					).unix()
				);
			}}
		/>
	);
};

export default SidebarCalendar;
