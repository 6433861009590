import React from "react";
import Calendar from "common/DatePicker/Calendar/Calendar";
import { useDatePicker } from "react-aria";
import { useDatePickerState } from "@react-stately/datepicker";

const ProposeInterviewCalendarMultipleSelect = ({
	onChange,
	isDateUnavailable,
	list,
	isMultiple,
	...props
}) => {
	let propsUpdated = {
		onChange,
		value: null
	};

	const ref = React.useRef();
	let state = useDatePickerState(propsUpdated);
	let { calendarProps } = useDatePicker(propsUpdated, state, ref);

	return (
		<Calendar
			{...props}
			{...calendarProps}
			isMultiple={isMultiple}
			list={list}
			isDateUnavailable={isDateUnavailable}
		/>
	);
};

export default ProposeInterviewCalendarMultipleSelect;
