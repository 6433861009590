import React, { useRef } from "react";
import styles from "./calendar.module.scss";
import FullCalendarComponent from "./components/full-calendar-component/full-calendar-component";
import CalendarHeader from "./components/calendar-header/calendar-header";
import CalendarSidebar from "./components/calendar-sidebar/calendar-sidebar";
import { useBreadCrumbs } from "hooks/useBreadCrumbs";
export const getCalendarRoute = () => {
	return "/calendar";
};

export const getCalendarPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
const Calendar = () => {
	useBreadCrumbs([{ name: "Calendar" }]);
	const calendarRef = useRef();

	return (
		<div className={styles.container}>
			<div className={styles.layout}>
				<header className={styles.header}>
					<CalendarHeader calendarRef={calendarRef} />
				</header>
				<div className={styles.contentLayout}>
					<div className={styles.sidebar}>
						<CalendarSidebar calendarRef={calendarRef} />
					</div>
					<div className={styles.content}>
						<FullCalendarComponent ref={calendarRef} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Calendar;
