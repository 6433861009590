import { renderError } from "config/helpers";
import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import fakeData from "modules/user-onboarding/mockData/calendar-collaborative-groups-list.json";

const getCollaborativeGroups = () =>
	client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body: {
			filter: "calendar_groups_with_members"
		}
	});

export const GET_CALENDAR_COLLABORATIVE_GROUPS_KEY =
	"GET_CALENDAR_COLLABORATIVE_GROUPS_KEY";

const useGetCollaborativeGroups = options => {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery(GET_CALENDAR_COLLABORATIVE_GROUPS_KEY, () => {
			return Promise.resolve(fakeData.data);
		});
	}
	return useQuery(
		[GET_CALENDAR_COLLABORATIVE_GROUPS_KEY],
		getCollaborativeGroups,
		{
			refetchOnWindowFocus: false,
			retry: 0,
			onError: renderError,
			...options
		}
	);
};

export default useGetCollaborativeGroups;
