import React, { useMemo } from "react";
import styles from "./collaborators-list.module.scss";
import { Controller } from "react-hook-form";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import { ReactComponent as CloseIcon } from "static/icons/close-icon-grey.svg";
import { CustomAvatar } from "common/CustomAvatar";
import LoadingState from "./loading-state/loading-state";
import EmptyState from "./empty-state/empty-state";
import {
	COLLABORATOR_GROUP_PERMISSIONS,
	COLLABORATOR_GROUP_ROLES
} from "./utils/constant";
import { toArray } from "lodash";
import cx from "classnames";
import {
	ADMIN_ROLE,
	STATUS_PENDING
} from "modules/calendar-settings/utils/constant";
import Tooltip from "common/Tippy";
import { DRAFT } from "config";
import { useGetUser } from "hooks/useGetUser";
import { ACCEPTED } from "config";

const CollaboratorsList = ({
	control,
	fields,
	remove,
	isLoading,
	adminAccess
}) => {
	const user = useGetUser();
	const permissionsList = toArray(COLLABORATOR_GROUP_PERMISSIONS);
	const rolesList = toArray(COLLABORATOR_GROUP_ROLES);
	const onPermissionSelectChange = (key, onChange) => {
		onChange(permissionsList.filter(el => el.value === key)[0]);
	};
	const onRoleSelectChange = (key, onChange) => {
		onChange(rolesList.filter(el => el.value === key)[0]);
	};

	const showDelete = element => {
		return (
			element.id !== user?.id &&
			element?.role?.value !== ADMIN_ROLE &&
			adminAccess
		);
	};

	const collaboratorsCount = useMemo(() => {
		return fields.filter(el => el.status === ACCEPTED).length;
	}, [fields]);

	return isLoading ? (
		<LoadingState />
	) : fields.length === 0 ? (
		<EmptyState />
	) : (
		<table className={styles.container}>
			<thead>
				<tr className={styles.titles}>
					<th className={styles.colUser}>
						Collaborators ({collaboratorsCount})
					</th>
					<th className={styles.colPermission}>Permissions</th>
					{adminAccess && <th className={styles.colRole}>Roles</th>}
					{adminAccess && <th></th>}
				</tr>
			</thead>

			<tbody className={styles.list}>
				{fields.map((element, index) => {
					const name =
						element.label || `${element.firstName} ${element.lastName}`;
					return (
						<tr className={styles.row} key={`${element.id}-${index}`}>
							<td>
								<div className={styles.colUser}>
									<div className={styles.collaborator}>
										<CustomAvatar
											src={element.avatar}
											fullName={
												element.label ||
												`${element.firstName} ${element.lastName}`
											}
											avatarClassName={styles.avatarImg}
											imgClassName={styles.avatarImg}
										/>
										<div className={styles.info}>
											<div className={styles.nameFlex}>
												<Tooltip content={name} theme="dark" overflow="hidden">
													<div className={styles.name}>{name}</div>
												</Tooltip>
												{user.id === element.id && (
													<div className={styles.meIndicator}>{`(Me)`}</div>
												)}
												{element.status === STATUS_PENDING && (
													<div className={styles.pendingBadge}>Pending</div>
												)}
											</div>
											<Tooltip
												content={element.headline}
												theme="dark"
												overflow="hidden"
											>
												<div className={styles.headline}>
													{element.headline}
												</div>
											</Tooltip>
										</div>
									</div>
								</div>
							</td>

							<td>
								{element.id !== user?.id ? (
									<div className={styles.colPermission}>
										<Controller
											name={`collaborators.${index}.permission`}
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<ComboBox
														isReadonlyInput={true}
														inputValue={value?.label}
														selectedKey={value?.value}
														inputRootClassName={cx(
															styles.select,
															styles.permissionSelect,
															{
																[styles.pending]: [
																	DRAFT,
																	STATUS_PENDING
																].includes(element.status)
															}
														)}
														onSelectionChange={key =>
															onPermissionSelectChange(key, onChange)
														}
														disabledInput={[DRAFT, STATUS_PENDING].includes(
															element.status
														)}
													>
														{permissionsList.map(({ value, label }) => {
															return <Item key={value}>{label}</Item>;
														})}
													</ComboBox>
												);
											}}
										/>
									</div>
								) : (
									<div className={styles.permissionsPlaceholder}></div>
								)}
							</td>
							<td>
								{adminAccess && element.id !== user?.id ? (
									<div className={styles.colRole}>
										<Controller
											name={`collaborators.${index}.role`}
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<ComboBox
														isReadonlyInput={true}
														inputValue={value?.label}
														selectedKey={value?.value}
														inputRootClassName={cx(
															styles.select,
															styles.roleSelect,
															{
																[styles.pending]:
																	(element.status === STATUS_PENDING ||
																		element?.role?.value === ADMIN_ROLE) &&
																	adminAccess
															}
														)}
														onSelectionChange={key =>
															onRoleSelectChange(key, onChange)
														}
														disabledInput={
															(element.status === STATUS_PENDING ||
																element?.role?.value === ADMIN_ROLE) &&
															adminAccess
														}
													>
														{rolesList.map(({ value, label }) => {
															return <Item key={value}>{label}</Item>;
														})}
													</ComboBox>
												);
											}}
										/>
									</div>
								) : (
									<div className={styles.rolesPlaceholder}></div>
								)}
							</td>
							<td>
								{showDelete(element) && (
									<div className={styles.colClose}>
										<CloseIcon
											className={styles.pointer}
											onClick={() => {
												remove({ index, element });
											}}
										/>
									</div>
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default CollaboratorsList;
