import React, { useState } from "react";
import styles from "./collaborative-group-invitation-page.module.scss";
import { useInvitationForm } from "../../hooks/useInvitationForm";
import { Controller, useFieldArray } from "react-hook-form";
import Checkbox from "common/AgTable/components/Checkbox/Checkbox";
import { Link } from "react-router";
import { getPolicyRoute } from "common/privacyPolicy/components/PolicyContainer";
import cx from "classnames";
import useFetchInvitationDetails from "modules/collaborative-group-invitation/hooks/api/useFetchInvitationDetails";
import { renderError } from "config/helpers";
import { COLLABORATOR_GROUP_PERMISSIONS } from "modules/calendar-settings/components/collaborative-groups-page/collaborators-list/utils/constant";
import loadable from "loadable-components";
import useAcceptInvitation from "../../hooks/api/useAcceptInvitation";
import useDeclineInvitation from "../../hooks/api/useDeclineInvitation";
import { invitationOptions } from "modules/calendar-settings/utils/constant";
import { getCalendarRoute } from "modules/calendar/calendar";
import { FieldError } from "common/FieldError";
import LeaveHook from "common/LeaveHook";
import LoadingState from "../loading-state/loading-state";
import { historyPush } from "config/helpers";
import toaster from "common/Toaster";
import MembersList from "../members-list/members-list";
import { INVITATION_STATUS_OPTIONS } from "modules/collaborative-group-invitation/utils/contant";
import InvitationNotFound from "../invitation-not-found/invitation-not-found";

const DeclineGroupInvitationModal = loadable(() =>
	import("../decline-group-invitation-modal/decline-group-invitation-modal")
);

const CollaborativeGroupInvitationPage = ({ groupId, invitationId }) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [groupName, setGroupName] = useState("");
	const [acceptInvitation, { isLoading: isJoinLoading }] = useAcceptInvitation({
		onSuccess: () => {
			toaster.success(`You've joined "${groupName}"`);
			historyPush(getCalendarRoute());
		},
		onError: renderError
	});

	const [
		declineInvitation,
		{ isLoading: isDeclineLoading }
	] = useDeclineInvitation({
		onSuccess: () => {
			toaster.success(`You've declined "${groupName}"'s invitation`);
			historyPush(getCalendarRoute());
		},
		onError: renderError
	});

	const {
		control,
		handleSubmit,
		formState: { errors, isDirty },
		reset,
		getValues
	} = useInvitationForm();
	const { isLoading: isLoadingDetails, data } = useFetchInvitationDetails(
		{
			group_id: groupId,
			invitation_id: invitationId
		},
		{
			enabled: groupId && invitationId,
			onSuccess: res => {
				if (res.group_name) setGroupName(res.group_name);
				if (res.members) {
					reset({
						acceptPolicy: false,
						collaborators: res.members.map(member => {
							return {
								id: member.id,
								firstName: member.first_name,
								lastName: member.last_name,
								avatar: member.avatar || null,
								headline: member.user_function,
								permission: COLLABORATOR_GROUP_PERMISSIONS.freeBusy,
								role: null
							};
						})
					});
				}
			}
		}
	);

	const { fields } = useFieldArray({
		control,
		name: "collaborators",
		keyName: "userId"
	});

	const onSubmit = values => {
		acceptInvitation({
			group_id: groupId,
			invitation_id: invitationId,
			action: invitationOptions.join,
			permissions: values.collaborators.map(el => {
				return { target_user: el.id, permission_level: el.permission.value };
			})
		});
		reset({ ...values });
	};

	const onDecline = () => {
		setShowConfirmationModal(true);
	};
	const onDiscard = () => {
		reset();
	};

	const invitationStatus =
		data?.length === 0
			? INVITATION_STATUS_OPTIONS.notFound
			: data?.status === INVITATION_STATUS_OPTIONS.accepted
			? INVITATION_STATUS_OPTIONS.accepted
			: null;
	return (
		<div className={styles.container}>
			<LeaveHook
				dirty={isDirty}
				confirmationModal={{
					onDiscard,
					isLoading: false,
					disabled: false
				}}
			/>

			{isLoadingDetails ? (
				<LoadingState />
			) : invitationStatus &&
			  Object.keys(INVITATION_STATUS_OPTIONS).includes(invitationStatus) ? (
				<InvitationNotFound
					invitationStatus={invitationStatus}
					groupName={groupName}
				/>
			) : (
				<div className={styles.content}>
					<div className={styles.title}>
						{`Join “${groupName}” Collaborative Group`}
					</div>

					<div className={styles.description}>
						Choose the level of access you&apos;d like to grant to members of
						this Collaborative Group. Your selection will determine what
						information others can see on your calendar.
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<MembersList control={control} fields={fields} />
						<Controller
							name="acceptPolicy"
							control={control}
							render={({ field: { value, onChange } }) => {
								return (
									<>
										<div
											className={styles.checkContainer}
											onClick={() => onChange(!value)}
										>
											<Checkbox
												className={styles.check}
												checked={value}
												height={"22px"}
												width={"22px"}
												onChange={() => onChange(!value)}
											/>
											<label className={styles.text} htmlFor="acceptPolicy">
												By joining this collaborative group, you are granting
												permission to other members to view certain details of
												your calendar events. Please review our{" "}
												<Link target="_blank" to={getPolicyRoute()}>
													Privacy Policy
												</Link>{" "}
												for more information.
											</label>
										</div>
										<FieldError
											error={errors?.acceptPolicy}
											className={styles.policyError}
										/>
									</>
								);
							}}
						/>

						<div className={styles.actions}>
							<button
								type="button"
								className={cx(styles.btn, styles.discard)}
								onClick={onDecline}
								disabled={isDeclineLoading}
							>
								Decline
							</button>
							<button className={styles.btn} disabled={isJoinLoading}>
								{isJoinLoading ? "Joining..." : "Join Group"}
							</button>
						</div>
					</form>
				</div>
			)}

			{showConfirmationModal && (
				<DeclineGroupInvitationModal
					onClose={() => setShowConfirmationModal(false)}
					title={`Decline "${groupName}"?`}
					description={`Are you sure you want to decline joining this collaborative group? You will no longer have access to the calendar's events and availability information.`}
					btnText={"Decline"}
					isLoading={isDeclineLoading}
					onConfirm={() => {
						declineInvitation({
							group_id: groupId,
							invitation_id: invitationId,
							action: invitationOptions.decline,
							permissions: []
						});
						reset({ ...getValues() });
					}}
				/>
			)}
		</div>
	);
};

export default CollaborativeGroupInvitationPage;
