import { OPERATORS } from "common/FilterDrawer/utils";
import { CustomInput } from "common/CustomInput";
import { CustomMap } from "common/CustomMap";
import { Picker } from "common/Picker";

import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { FreelancersBooleanSelect } from "modules/freelancers/components/FreelancersBooleanSelect";
import { FreelancersCurrencyInput } from "modules/freelancers/components/FreelancersCurrencyInput";

export const COMPANY_DEFAULT_AVATAR =
	"https://mcusercontent.com/88c83061cea4f39823efacefc/images/b3b4f65b-96e6-4614-bf86-0bd0350d4861.png";

export const NOTES_MAX_SELECT = 24;

export const NOTES_PAGE_SIZES = [50, 24, 12];

export const NOTES_FILTER_ID = "NOTES_FILTER_ID";

export const NOTES_MODULE_ID = "NOTES_MODULE_ID";

export const API_DATA_TYPE = "search_notes_table";

export const NOTES_PAGE_SIZE = 24;

export const TYPES = {
	text: "text",

	postcode: "postcode",
	timeCommitment: "timeCommitment",
	number: "number",
	array: "array",
	boolean: "boolean",
	date: "date",
	address: "address"
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.postcode]: [
		OPERATORS.contains,
		OPERATORS.notContains,
		OPERATORS.startWith
	],
	[TYPES.timeCommitment]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.number]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.boolean]: [OPERATORS.is, OPERATORS.isNot],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[TYPES.address]: [OPERATORS.is]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.address]: {
		default: CustomMap
	},
	[TYPES.postcode]: {
		default: CustomInput
	},
	[TYPES.timeCommitment]: {
		default: Picker
	},
	[TYPES.number]: {
		default: FreelancersCurrencyInput,
		[OPERATORS.isEmpty.value]: FreelancersBooleanSelect
	},
	[TYPES.array]: {
		default: Picker
	},
	[TYPES.boolean]: {
		default: FreelancersBooleanSelect
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	}
};

export const columnSortMap = {
	_id: "_id",
	user_id: "user_id",
	created_by: "created_by",
	created_at: "created_at",
	note_type: "note_type",
	visibility: "visibility",
	description: "description",
	job_title: "job_title"
};

export const columnFiltersMap = {
	_id: "_id",
	user_id: "user_id",
	created_by: "created_by",
	created_at: "created_at",
	note_type: "note_type",
	visibility: "visibility",
	description: "description",
	job_title: "job_title"
};

export const CHECK_FILTER_COLUMNS = ["job_title", "description"];
