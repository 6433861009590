//TODO fix popover display/reselect same value
import React, { memo, forwardRef } from "react";
import { useComboBox, useFilter } from "react-aria";
import { useComboBoxState } from "react-stately";

// Reuse the ListBox, Popover, and Button from your component library. See below for details.
import { ComboBoxListBox } from "./components/ComboBoxListBox";
import { ComboBoxPopover } from "./components/ComboBoxPopover";
import { ComboBoxInput } from "./components/ComboBoxInput";
import styles from "./combo-box.module.scss";
// import cx from "classnames";

function ComboBox(
	{
		popoverContainer,
		button,
		popoverClassName,
		inputClassName,
		buttonClassName,
		displayButton = false,
		disabledInput,
		inputRootClassName,
		inputPlaceholder,
		isReadonlyInput,
		...propsRest
	},
	inputRef2
) {
	const propRestUpdated = {
		...propsRest
	};

	let { contains } = useFilter({ sensitivity: "base" });
	let state = useComboBoxState({ ...propRestUpdated, defaultFilter: contains });

	// Setup refs and get props for child elements.
	let buttonRef = React.useRef(null);
	let inputRef = React.useRef(null);
	let listBoxRef = React.useRef(null);
	let popoverRef = React.useRef(null);
	let inputContainerRef = React.useRef(null);

	let { buttonProps, inputProps, listBoxProps } = useComboBox(
		{
			...propRestUpdated,
			inputRef,
			buttonRef,
			listBoxRef,
			popoverRef
		},
		state
	);

	const popoverStyle = {
		width: `${inputContainerRef.current?.offsetWidth}px`,
		marginTop: "5px",
		zIndex: 2147483649
	};

	const hasEmptyList = state.isFocused && !state.isOpen;

	return (
		<div>
			<div>
				<ComboBoxInput
					inputProps={inputProps}
					buttonProps={buttonProps}
					ref={[inputRef, inputContainerRef, inputRef2]}
					buttonRef={buttonRef}
					inputClassName={inputClassName}
					buttonClassName={buttonClassName}
					isFocused={state.isFocused}
					disabledInput={disabledInput}
					className={inputRootClassName}
					placeHolder={inputPlaceholder}
					isReadOnly={isReadonlyInput}
				/>
				{state.isOpen && (
					<ComboBoxPopover
						state={state}
						triggerRef={inputContainerRef}
						popoverRef={popoverRef}
						isNonModal
						placement="bottom start"
						className={popoverClassName}
						style={popoverStyle}
						popoverContainer={popoverContainer}
					>
						<ComboBoxListBox
							{...listBoxProps}
							listBoxRef={listBoxRef}
							state={state}
							button={button}
						/>
					</ComboBoxPopover>
				)}
				{hasEmptyList &&
					displayButton &&
					(propsRest.children.length === 0 || state.inputValue) && (
						<ComboBoxPopover
							state={{ ...state, isOpen: true }}
							triggerRef={inputContainerRef}
							popoverRef={popoverRef}
							isNonModal
							placement="bottom start"
							className={popoverClassName}
							style={popoverStyle}
						>
							<div>
								<div className={styles.noResult}>
									{propsRest.children.length === 0
										? "No options found"
										: state.inputValue && "No matching results"}
								</div>
								<div className={styles.separator} />
								<div className={styles.buttonContainer}>{button}</div>
							</div>
						</ComboBoxPopover>
					)}
			</div>
		</div>
	);
}

export default memo(forwardRef(ComboBox));
