import { HOURS_IN_DAY, MINUTES_IN_HOUR } from "config";

export function addMinutes(timeObj, minutesToAdd) {
	let { hour, minute } = timeObj;

	minute += minutesToAdd;

	if (minute >= MINUTES_IN_HOUR) {
		hour += Math.floor(minute / MINUTES_IN_HOUR);

		minute = minute % MINUTES_IN_HOUR;
	}

	if (hour >= HOURS_IN_DAY) {
		hour = hour % HOURS_IN_DAY;
	}

	return { hour, minute };
}

export function subtractMinutes(timeObj, minutesToSubtract) {
	let { hour, minute } = timeObj;

	let totalMinutes = hour * MINUTES_IN_HOUR + minute;
	totalMinutes -= minutesToSubtract;

	// to handle negative  minutes
	const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;
	totalMinutes =
		((totalMinutes % MINUTES_IN_DAY) + MINUTES_IN_DAY) % MINUTES_IN_DAY;

	hour = Math.floor(totalMinutes / MINUTES_IN_HOUR);
	minute = totalMinutes % MINUTES_IN_HOUR;

	return { hour, minute };
}

export const displayAttendeeName = element => {
	if (element.first_name && element.last_name) {
		return `${element.first_name} ${element.last_name}`;
	} else if (element.name) {
		return element.name;
	} else if (element.email) {
		return element.email;
	}
};
