import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";

const fetchGroups = () => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body: {
			filter: "calendar_groups_by_user"
		}
	});
};

export const CALENDAR_COLLABORATIVE_GROUPS = "CALENDAR_COLLABORATIVE_GROUPS";

const useFetchCollaborativeGroups = options => {
	return useQuery([CALENDAR_COLLABORATIVE_GROUPS], fetchGroups, {
		refetchOnWindowFocus: false,
		retry: false,
		...options
	});
};

export default useFetchCollaborativeGroups;
