import React from "react";
import styles from "./invitation-not-found.module.scss";
import { ReactComponent as HappyCalendarIcon } from "static/icons/happy-calendar-icon.svg";
import { ReactComponent as SleepingCalendarIcon } from "static/icons/sleeping-calendar-icon.svg";
import { ReactComponent as CalendarIcon } from "static/icons/sidebar-icons/calendar.svg";
import { INVITATION_STATUS_OPTIONS } from "modules/collaborative-group-invitation/utils/contant";
import { Link } from "react-router";
import { getCalendarRoute } from "modules/calendar/calendar";

const InvitationNotFound = ({ invitationStatus, groupName }) => {
	return (
		<div className={styles.content}>
			<div className={styles.icon}>
				{invitationStatus === INVITATION_STATUS_OPTIONS.accepted ? (
					<HappyCalendarIcon />
				) : (
					<SleepingCalendarIcon />
				)}
			</div>
			<div className={styles.title}>
				{invitationStatus === INVITATION_STATUS_OPTIONS.accepted
					? `Already a Member of "${groupName}"`
					: "Invalid Group Invitation"}
			</div>
			<div className={styles.description}>
				{invitationStatus === INVITATION_STATUS_OPTIONS.accepted
					? "You’re already a member of this group. Head over to the group to get started!"
					: "It looks like this invitation is no longer valid. If you have any questions, please reach out to the group admin."}
			</div>
			<Link to={getCalendarRoute()} className={styles.btn}>
				<CalendarIcon />
				Back to my Calendar
			</Link>
		</div>
	);
};

export default InvitationNotFound;
