import Drawer from "rc-drawer";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { Button } from "common/Button";
import cx from "classnames";
import styles from "./cancel-interview-drawer.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { Controller, FormProvider, useForm } from "react-hook-form";
import LeaveHook from "common/LeaveHook";
import MessagingToolNewEmailBody from "common/MessagingToolNewEmail/components/MessagingToolNewEmailBody/MessagingToolNewEmailBody";
import { SEND_VACANCY_EMAIL } from "../../../../../../SearchCandidate/components/SendVacancy/utils/constant";
import MesssagingToolNewEmailWarnings from "common/MessagingToolNewEmail/components/MesssagingToolNewEmailWarnings/MesssagingToolNewEmailWarnings";
import { generateId, renderError } from "config/helpers";
import MessagingToolNewEmailTemplates from "common/MessagingToolNewEmail/components/MessagingToolNewEmailTemplates/MessagingToolNewEmailTemplates";
import { useGetEmailSignature } from "common/MessagingToolNewEmail/api/useGetEmailSignature";
import { useGetEmailTemplate } from "common/MessagingToolNewEmail/api/useGetEmailTemplate";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore/index.js";
import TextArea from "common/text-area";
import usePokeData from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";
import { useCancelInterview } from "./api/useCancelInterview";
import { queryCache } from "react-query";
import toaster from "common/Toaster";
import { KEY_FETCH_TEMP_INTERVIEWS } from "modules/Requests/RequestsInterviewsList/hooks/useFilterApplications";
import { INTERNAL_NOTE_MAX_LENGTH } from "config";
import _get from "lodash/get";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";

const CancelInterviewDrawer = ({
	isOpen,
	onClose,
	onSuccess,
	isFromVacanciesTable
}) => {
	const placeholdersRef = useRef();
	const { drawerState } = useVacancyStore();
	const { data: pokeData } = usePokeData({
		id: drawerState.application_id
	});

	const [showDiscardWarning, setShowDiscardWarning] = useState(false);
	const [secondPortal, setSecondPortal] = useState(null);
	const [sendVacancyTemplate, setSendVacancyTemplate] = useState(null);
	const [signature, setSignature] = useState(null);
	const [showSubjectWarning, setShowSubjectWarning] = useState(false);
	const [isPlaceholdersExtracted, setIsPlaceholdersExtracted] = useState(false);
	const [drawerVisibility, setDrawerVisibility] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setDrawerVisibility(true);
		});
	}, []);
	useEffect(() => {
		if (!isOpen) {
			setShowDiscardWarning(false);
			reset();
		}
	}, [isOpen]);

	const methods = useForm({
		defaultValues: { ...EMPTY_EMAIL, note: "" }
	});
	const {
		formState: { dirtyFields },
		reset,
		setValue,
		watch,
		getValues,
		control,
		handleSubmit
	} = methods;
	const isDirty = Object.keys(dirtyFields).length > 0;

	const contentPlaceholders = watch("content_placeholders");
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();

	const [cancelInterview, { isLoading }] = useCancelInterview({
		onSuccess: () => {
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			toaster.success("Interview Cancelled Successfully.");
			queryCache.invalidateQueries(KEY_FETCH_TEMP_INTERVIEWS);
			queryCache.invalidateQueries("getDataPipeline");
			setApplicationId(_get(drawerState, "application_id"));
			onSuccess?.();

			drawerClose();
		},
		onError: renderError
	});

	const drawerClose = () => {
		setDrawerVisibility(false);
	};

	useEffect(() => {
		if (isOpen && drawerState?.candidateEmail) {
			setValue(
				"to",
				drawerState?.candidateEmail.map(el => {
					return { email: el };
				})
			);
		}
	}, [isOpen, drawerState]);

	const onClickCancel = () => {
		if (isDirty) {
			setShowDiscardWarning(true);
		} else {
			if (!isFromCardActions) {
				if (!isFromVacanciesTable) {
					setIsOpen(true);
				}
				setApplicationId(_get(drawerState, "application_id"));
			}
			drawerClose();
		}
	};

	const emailId = useMemo(() => generateId(), []);
	const { data: templateDetails } = useGetEmailTemplate({
		event_type: "permanent_interview_canceled_email"
	});
	useGetEmailSignature({
		enabled: !!templateDetails,
		onSuccess: data => {
			setSignature({
				signature_avatar: data.avatar || "",
				signature_address: data.address || "",
				signature_email: data.email || "",
				signature_full_name: data.full_name || "",
				signature_phone: data.phone || "",
				signature_position: data.position || "",
				signature_social_media: data.social_media || []
			});
		}
	});

	const updateNewEntity = useCallback(
		placeholders => {
			return placeholders.map(item => {
				if (item.entity === "candidate") {
					return {
						...item,
						label: `${_get(pokeData, "profile.headline.first_name", "")} ${_get(
							pokeData,
							"profile.headline.last_name",
							""
						)}`,
						value: item.value,
						_id: _get(pokeData, "profile.headline._id", ""),
						readOnly: true
					};
				}
				if (item.entity === "client") {
					return {
						...item,
						label: `${_get(
							pokeData,
							"interview.user_sender.first_name",
							""
						)} ${_get(pokeData, "interview.user_sender.last_name", "")}`,
						value: item.value,
						_id: _get(pokeData, "interview.user_sender._id", "")
					};
				}
				if (item.entity === "vacancy") {
					return {
						...item,
						label: _get(pokeData, "job.title", ""),
						value: item.value,
						_id: _get(pokeData, "job._id", "")
					};
				}
				return item;
			});
		},
		[pokeData]
	);

	useEffect(() => {
		if (
			!isPlaceholdersExtracted &&
			contentPlaceholders.length > 0 &&
			pokeData
		) {
			const newContentPlaceholders = updateNewEntity(contentPlaceholders);
			setValue("content_placeholders", newContentPlaceholders);
			setIsPlaceholdersExtracted(true);
		}
	}, [contentPlaceholders, isPlaceholdersExtracted, pokeData]);

	useEffect(() => {
		if (templateDetails) {
			const mainVersion = Object.keys(templateDetails.versions)[0];
			const template = templateDetails.versions[mainVersion];
			reset({
				...getValues(),
				subject: template.subject,
				content: template.content
			});
			setSendVacancyTemplate(template);
		}
	}, [templateDetails]);

	const onSend = values => {
		const payload = {
			cancel_reason: values.note,
			interview_id: _get(pokeData, "interview._id", ""),
			poke_id: _get(pokeData, "_id", ""),
			token_invitation_id: _get(pokeData, "interview.token_invitation_id", ""),
			subject: values.subject,
			content: values.content,
			content_placeholders: values.content_placeholders.map(placeholder => ({
				key: placeholder.value,
				value: placeholder._id || ""
			})),
			attachments: []
		};
		cancelInterview(payload);
	};

	return (
		<Drawer
			open={drawerVisibility}
			width="998px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000, backgroundColor: "transparent" }}
			level={"root"}
			maskClosable={true}
			onClose={onClickCancel}
			afterVisibleChange={show => {
				if (!show) onClose();
			}}
		>
			<FormProvider {...methods}>
				<form className={styles.container} onSubmit={handleSubmit(onSend)}>
					<header className={styles.header}>
						<h3 className={styles.title}>Cancel Interview</h3>
						<button
							className={styles.closeBtn}
							type="button"
							onClick={onClickCancel}
						>
							<CloseIcon />
						</button>
					</header>
					{isOpen && (
						<div className={styles.body}>
							<div className={styles.container}>
								<div className={styles.row}>
									<label className={styles.label}>
										Internal Note (Visible only to members of the department)
									</label>

									<Controller
										name="note"
										control={control}
										render={({ field: { value, onChange } }) => (
											<TextArea
												value={value}
												onChange={onChange}
												maxLength={INTERNAL_NOTE_MAX_LENGTH}
											/>
										)}
									/>
								</div>
								<div className={styles.row}>
									<label className={styles.label}>Compose Message</label>
									<div className={styles.banner}>
										ℹ️ Please review and customize the email below. Ensure all
										details are correct before sending
									</div>
								</div>
								<div className={styles.row}>
									<div className={styles.newMailContainer}>
										<div className={styles.body}>
											<MessagingToolNewEmailBody
												setSecondPortal={setSecondPortal}
												placeholdersRef={placeholdersRef}
												isActive={true}
												secondPortal={secondPortal}
												activeTemplate={sendVacancyTemplate}
												path={SEND_VACANCY_EMAIL}
												emailSignature={signature}
												useEmailSignaturePlugin
												readOnly
											/>
										</div>
										<MesssagingToolNewEmailWarnings
											showSubjectWarning={showSubjectWarning}
											setShowSubjectWarning={setShowSubjectWarning}
											emailId={emailId}
										/>
										<div
											ref={placeholdersRef}
											className={cx(styles.placeholdersAndTemplates, {
												[styles.hide]: secondPortal !== "placeholders"
											})}
										/>
										{secondPortal === "templates" && (
											<div className={styles.placeholdersAndTemplates}>
												<div className={styles.header}>
													<span className={styles.title}>{secondPortal}</span>
													<button
														className={styles.headerButton}
														type="button"
														onClick={() => setSecondPortal("")}
													>
														<CloseIcon className={styles.icon} />
													</button>
												</div>
												<MessagingToolNewEmailTemplates
													setActiveTemplate={setSendVacancyTemplate}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					<footer className={styles.footer}>
						<Button
							className={styles.btn}
							text="Cancel Interview"
							isLoading={isLoading}
						/>
						<Button
							className={cx(styles.btn, styles.outlined)}
							text="Cancel"
							variant="outlined"
							type="button"
							onClick={onClickCancel}
						/>
					</footer>
				</form>

				{showDiscardWarning && (
					<LeaveHook
						dirty={isDirty}
						enforceWarning={showDiscardWarning}
						confirmationModal={{
							onDiscard: () => {
								if (!isFromCardActions) {
									if (!isFromVacanciesTable) {
										setIsOpen(true);
									}
									setApplicationId(_get(drawerState, "application_id"));
								}
								drawerClose();
							},
							onClose: () => setShowDiscardWarning(false)
						}}
					/>
				)}
			</FormProvider>
		</Drawer>
	);
};

export default CancelInterviewDrawer;
