import React, { useState } from "react";
import styles from "./calendar-settings-page.module.scss";
import { ReactComponent as ClockIcon } from "static/icons/reminder-clock.svg";
import { ReactComponent as BellIcon } from "static/icons/notification-bell.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-one.svg";
import { ReactComponent as GlobeIcon } from "static/icons/globe-01.svg";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import { Controller, FormProvider } from "react-hook-form";
import { useCalendarSettingsForm } from "../../hooks/useCalendarSettingsForm";
import Checkbox from "common/AgTable/components/Checkbox/Checkbox";
import useFetchCalendarSettings, {
	CALENDAR_SETTINGS
} from "../../hooks/api/useFetchCalendarSettings";
import useSaveCalendarSettings from "../../hooks/api/useSaveCalendarSettings";
import Skeleton from "react-loading-skeleton";
import {
	generateId,
	renderError,
	timeStampToCalendarTime,
	timeToUTC
} from "config/helpers";
import Header from "../header/header";
import LeaveHook from "common/LeaveHook";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { REMINDER_BEFORE } from "config";
import CalendarTimezone from "../calendar-timezone/calendar-timezone";
import cx from "classnames";
import CalendarWorkingDays from "../calendar-working-days/calendar-working-days";
import { useDispatch } from "react-redux";
import { getMyProfile } from "modules/user/actions/authActions";

const CalendarSettingsPage = () => {
	const dispatch = useDispatch();
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const methods = useCalendarSettingsForm();
	const {
		control,
		reset,
		handleSubmit,
		formState: { isDirty }
	} = methods;

	const { isLoading } = useFetchCalendarSettings({
		onError: renderError,
		onSuccess: res => {
			reset({
				timezone: { label: res.timezone, value: res.timezone },
				reminder: REMINDER_BEFORE.find(
					el => parseInt(el.value) === parseInt(res.reminder)
				),
				emailNotification: res.email,
				pushNotification: res.push_notification,
				work_days: (res.work_days || []).map(day => {
					return {
						...day,
						time_slots: day.time_slots.map(slot => {
							return {
								id: generateId(),
								start_time: timeStampToCalendarTime(
									slot.start_time,
									res.timezone
								),
								end_time: timeStampToCalendarTime(slot.end_time, res.timezone)
							};
						})
					};
				})
			});
		}
	});

	const [
		saveCalendarSettings,
		{ isLoading: isSaving }
	] = useSaveCalendarSettings({
		onError: renderError,
		onSuccess: () => {
			dispatch(getMyProfile());
			queryCache.invalidateQueries(CALENDAR_SETTINGS);
			toaster.success("Calendar settings saved successfully.");
		}
	});

	const onSelectionChange = ({ key, onChange }) => {
		onChange(REMINDER_BEFORE.filter(el => el.value === key)[0]);
	};

	const onSubmit = values => {
		saveCalendarSettings({
			type: "general_user_settings",
			tag: "reminder_settings",
			settings: {
				reminder: parseInt(values.reminder.value),
				push_notification: values.pushNotification,
				notification_email: values.emailNotification,
				timezone: values.timezone.value,
				work_days: values.work_days.map(day => {
					return {
						...day,
						time_slots: day.time_slots.map(slot => {
							return {
								start_time: timeToUTC(slot.start_time, values.timezone.value),
								end_time: timeToUTC(slot.end_time, values.timezone.value)
							};
						})
					};
				})
			}
		});
	};

	const onDiscard = () => {
		reset();
	};

	return (
		<>
			<LeaveHook
				dirty={isDirty || showUnsavedChangesModal}
				confirmationModal={{
					onDiscard,
					onClose: () => setShowUnsavedChangesModal(false),
					isLoading: isSaving,
					disabled: isSaving
				}}
			/>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Header
						onDiscard={() => reset()}
						isSaving={isSaving}
						allowSave={isDirty}
					/>
					<div className={styles.wrapper}>
						<div className={styles.container}>
							<div className={styles.row}>
								<div className={styles.section}>
									<div className={styles.title}>
										<GlobeIcon />
										Timezone
									</div>
									<span className={styles.description}>
										Select your preferred timezone
									</span>
									<CalendarTimezone isLoading={isLoading} />
								</div>
							</div>
							<div className={cx(styles.row, styles.divider)}>
								<div className={styles.section}>
									<div className={styles.title}>
										<ClockIcon />
										Reminders
									</div>

									{isLoading ? (
										<Skeleton height={44} width={243} />
									) : (
										<Controller
											name="reminder"
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<ComboBox
														isReadonlyInput={true}
														inputValue={value?.label}
														selectedKey={value?.value}
														inputRootClassName={styles.reminderSelect}
														popoverClassName={styles.reminderSelectPopOver}
														onSelectionChange={key =>
															onSelectionChange({ key, onChange })
														}
													>
														{REMINDER_BEFORE.map(({ value, label }) => {
															return <Item key={value}>{label}</Item>;
														})}
													</ComboBox>
												);
											}}
										/>
									)}
								</div>
								<div className={styles.section}>
									<div className={styles.title}>
										<BellIcon />
										Notifications
									</div>
									<div className={styles.subtitle}>
										Receive my notifications
									</div>
									<div className={styles.checks}>
										{isLoading ? (
											<Skeleton height={21} width={170} />
										) : (
											<Controller
												name="emailNotification"
												control={control}
												render={({ field: { value, onChange } }) => {
													return (
														<div className={styles.checkContainer}>
															<Checkbox
																className={styles.check}
																checked={value}
																height={"22px"}
																width={"22px"}
																onChange={() => {
																	onChange(!value);
																}}
															/>

															<label
																className={styles.text}
																htmlFor="emailNotification"
															>
																Email
															</label>
														</div>
													);
												}}
											/>
										)}
										{isLoading ? (
											<Skeleton height={21} width={170} />
										) : (
											<Controller
												name="pushNotification"
												control={control}
												render={({ field: { value, onChange } }) => {
													return (
														<div className={styles.checkContainer}>
															<Checkbox
																className={styles.check}
																checked={value}
																height={"22px"}
																width={"22px"}
																onChange={() => {
																	onChange(!value);
																}}
															/>
															<label
																className={styles.text}
																htmlFor="pushNotification"
															>
																Push notification
															</label>
														</div>
													);
												}}
											/>
										)}
									</div>
								</div>
							</div>
							<div className={cx(styles.row, styles.divider)}>
								<div className={styles.section}>
									<div className={styles.title}>
										<CalendarIcon />
										Working Days & Hours
									</div>
									<span className={styles.description}>
										Set your working days and hours to help optimize scheduling
										for interviews and events.
									</span>
									<CalendarWorkingDays isLoading={isLoading} />
								</div>
							</div>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	);
};

export default CalendarSettingsPage;
