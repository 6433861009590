import React from "react";
import styles from "./calendar-collaborative-groups.module.scss";
import { Checkbox } from "common/AgTable/components/Checkbox";
import { useGetUser } from "hooks/useGetUser";
import useCalendarStore from "modules/calendar/hooks/useCalendarStore";
import { ReactComponent as PlusIcon } from "static/icons/plus.svg";
import { CustomAvatar } from "common/CustomAvatar";
import cx from "classnames";
import { MAX_VISIBLE_USERS } from "modules/calendar/utils/constants";
import useGetCollaborativeGroups, {
	GET_CALENDAR_COLLABORATIVE_GROUPS_KEY
} from "modules/calendar/api/use-get-collaborative-groups";
import CollaborativeGroupActions from "../collaborative-group-actions/collaborative-group-actions";
import EmptyState from "../calendar-collaborative-groups-empty-state/calendar-collaborative-groups-empty-state";
import Skeleton from "react-loading-skeleton";
import loadable from "loadable-components";
import { PENDING } from "config";
import { TOUR_CALENDAR } from "config/onboarding";
import { useCalendarTour } from "modules/user-onboarding/workflows/calendar";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import Tooltip from "common/Tippy";
import { PopoverTrigger } from "common/popover-trigger";
import { useOverlayTriggerState } from "react-stately";
import { queryCache } from "react-query";

const AddCollaborativeGroupDrawer = loadable(() =>
	import("../add-collaborative-group-drawer/add-collaborative-group-drawer")
);
const CalendarCollaborativeGroups = () => {
	const menuState = useOverlayTriggerState({});
	const [
		showAddCollaborativeGroupDrawer,
		setShowAddCollaborativeGroupDrawer
	] = React.useState(false);
	const [triggeredGroup, setTriggeredGroup] = React.useState(null);
	const user = useGetUser();
	const showMyCalendar = useCalendarStore(state => state.showMyCalendar);
	const setShowMyCalendar = useCalendarStore(state => state.setShowMyCalendar);
	const visibleCollaborativeGroup = useCalendarStore(
		state => state.visibleCollaborativeGroup
	);
	const removeCollaborativeGroup = useCalendarStore(
		state => state.removeCollaborativeGroup
	);
	const addCollaborativeGroup = useCalendarStore(
		state => state.addCollaborativeGroup
	);

	const { setDrawerState } = useVacancyStore();
	const { data, isLoading } = useGetCollaborativeGroups();
	const onCheckCollaborativeGroup = groupId => {
		if (visibleCollaborativeGroup === groupId) removeCollaborativeGroup();
		else addCollaborativeGroup(groupId);
	};

	useCalendarTour({
		onCreateCollaborativeGroup: () => {
			setShowAddCollaborativeGroupDrawer(true);
			setDrawerState({
				open: false,
				component: ""
			});
		},
		onClose: () => setShowAddCollaborativeGroupDrawer(false)
	});

	const onCollaborativeGroupCreate = () => {
		queryCache.invalidateQueries(GET_CALENDAR_COLLABORATIVE_GROUPS_KEY);
	};

	return (
		<div className={styles.container}>
			<div className={styles.myCalendar}>
				<h3 className={styles.title}>My Calendar</h3>
				<div className={styles.checkboxWrapper}>
					<Checkbox
						id={"my-calendar"}
						checked={showMyCalendar}
						onChange={setShowMyCalendar}
						className={styles.checkbox}
						width={19}
						height={19}
					/>
					<span className={styles.username}>
						{user.first_name} {user.last_name}
					</span>
				</div>
			</div>
			<div className={styles.collaborativeGroups}>
				<div className={styles.titleContainer}>
					<h3 className={styles.title}>Collaborative Groups</h3>
					<button
						className={styles.addGroup}
						onClick={() => setShowAddCollaborativeGroupDrawer(true)}
						data-onboarding-step={`${TOUR_CALENDAR}-6`}
					>
						<PlusIcon width={20} height={20} />
					</button>
				</div>

				<div className={styles.groups}>
					{isLoading ? (
						<>
							<LoadingState />
							<LoadingState />
							<LoadingState />
						</>
					) : data?.length === 0 ? (
						<EmptyState />
					) : (
						data?.map(group => {
							const isGroupDisabled = group.invitation.status === PENDING;

							return (
								<div
									key={group._id}
									className={cx(styles.group, {
										[styles.disabled]: isGroupDisabled
									})}
								>
									<Checkbox
										id={"group"}
										checked={visibleCollaborativeGroup.includes(group._id)}
										onChange={() => onCheckCollaborativeGroup(group._id)}
										className={styles.checkbox}
										checkboxIconClassName={styles.checkboxIconClassName}
										width={19}
										height={19}
									/>
									<div className={styles.groupDetails}>
										<div className={styles.groupName}>
											<Tooltip
												content={group.group_name}
												theme="dark"
												overflow="hidden"
											>
												<span className={styles.name}>{group.group_name}</span>
											</Tooltip>
											{isGroupDisabled && (
												<span className={styles.status}>Pending</span>
											)}
											<div className={styles.groupActionsButtons}>
												<CollaborativeGroupActions
													group={group}
													isGroupDisabled={isGroupDisabled}
												/>
											</div>
										</div>
										<div className={styles.collaborators}>
											{group.members.map(
												(member, index) =>
													index < MAX_VISIBLE_USERS && (
														<Tooltip
															key={index}
															content={`${member.first_name} ${member.last_name}`}
															theme="dark"
														>
															<div key={index} className={styles.avatarWrapper}>
																<CustomAvatar
																	src={member.avatar}
																	variant="small"
																	fullName={`${member.first_name} ${member.last_name}`}
																	rootClassName={cx(
																		styles.collaboratorPicture,
																		{
																			[styles.firstCollaboratorPicture]:
																				index === 0
																		}
																	)}
																	imgClassName={styles.picture}
																	avatarClassName={cx(
																		styles.avatarWithBorder,
																		styles.avatar,
																		{
																			[styles.firstCollaboratorPicture]:
																				index === 0
																		}
																	)}
																	avatarSize={34}
																	rootStyle={{
																		zIndex: index
																	}}
																	avatarStyle={{
																		zIndex: index
																	}}
																/>
															</div>
														</Tooltip>
													)
											)}

											{group.members.length > MAX_VISIBLE_USERS && (
												<PopoverTrigger
													state={menuState}
													hideArrow
													popoverClassName={styles.popoverClassName}
													trigger={
														<div
															className={styles.remainingUsers}
															onClick={() => setTriggeredGroup(group._id)}
														>
															+{group.members.length - MAX_VISIBLE_USERS}
														</div>
													}
												>
													{group._id === triggeredGroup ? (
														<div className={styles.remainingAttendeesList}>
															{group.members
																.slice(MAX_VISIBLE_USERS)
																.map((member, index) => (
																	<div key={index} className={styles.attendee}>
																		<CustomAvatar
																			src={member.avatar}
																			variant="small"
																			fullName={`${member.first_name} ${member.last_name}`}
																			avatarSize={30}
																		/>
																		<Tooltip
																			key={index}
																			content={`${member.first_name} ${member.last_name}`}
																			theme="dark"
																			overflow="hidden"
																		>
																			<span className={styles.attendeeName}>
																				{`${member.first_name} ${member.last_name}`}
																			</span>
																		</Tooltip>
																	</div>
																))}
														</div>
													) : (
														<></>
													)}
												</PopoverTrigger>
											)}
										</div>
									</div>
								</div>
							);
						})
					)}
				</div>
			</div>
			{showAddCollaborativeGroupDrawer && (
				<AddCollaborativeGroupDrawer
					onClose={() => setShowAddCollaborativeGroupDrawer(false)}
					onSave={() => onCollaborativeGroupCreate()}
				/>
			)}
		</div>
	);
};

const LoadingState = () => {
	return (
		<div className={styles.group}>
			<Skeleton width={22} height={22} />
			<div className={styles.groupDetails}>
				<Skeleton width={100} height={21} />
			</div>
		</div>
	);
};

export default CalendarCollaborativeGroups;
