import { LEAVE_CALENDAR_GROUP_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

const leaveGroup = body =>
	client(LEAVE_CALENDAR_GROUP_ENDPOINT, {
		body
	});

const useLeaveGroup = options => {
	return useMutation(leaveGroup, {
		...options
	});
};

export default useLeaveGroup;
