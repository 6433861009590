import React from "react";
import PlannedInterviewsUpcoming from "./PlannedInterviewsUpcoming/PlannedInterviewsUpcoming";
import PlannedInterviewsHistory from "./PlannedInterviewsHistory/PlannedInterviewsHistory";
import styles from "./vacancy-view-planned-interviews.module.scss";

export default function VacancyViewPlannedInterviews({ job = {}, timezone }) {
	return (
		<div className={styles.container}>
			<PlannedInterviewsUpcoming timezone={timezone} />
			<PlannedInterviewsHistory job={job} timezone={timezone} />
		</div>
	);
}
