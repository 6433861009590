import React from "react";
import {
	today,
	getLocalTimeZone,
	Time,
	isSameDay
} from "@internationalized/date";
import styles from "./vacancy-view-propose-interview-first-step.module.scss";
import { ReactComponent as ClockIcon } from "static/icons/clock-1.svg";
import { ReactComponent as UsersIcon } from "static/icons/users.svg";
import { ReactComponent as StarsIcon } from "static/icons/stars-02.svg";
import { ReactComponent as CalendarPlusIcon } from "static/icons/calendar-plus-1.svg";
import { ReactComponent as MapPinIcon } from "static/icons/map-pin.svg";
import { ReactComponent as CommentIcon } from "static/icons/comment-1.svg";
import { ReactComponent as LinkExternalIcon } from "static/icons/link-external-02.svg";
import Avatar from "common/Avatar/index";
import { VacancyViewAttendeesPicker } from "../../VacancyViewAttendeesPicker";
import { Controller, useFormContext } from "react-hook-form";
import {
	convertCalendarDateToTimestamp,
	convertCalendarObjectToTimestampUsingTimezone,
	generateId,
	renderError,
	sortCalendarDates
} from "config/helpers";
import { VacancyViewTimesList } from "../../VacancyViewTimesList";
import { VacancyViewLocationPicker } from "../../VacancyViewLocationPicker";
import { FieldError } from "common/FieldError";
import VacancyViewPlannedInterviews from "../../VacancyViewPlannedInterviews/VacancyViewPlannedInterviews";
import useVacancyStore from "../../vacancyStore";
import VacancyViewProposeInterviewVacancyField from "../VacancyViewProposeInterviewVacancyField/VacancyViewProposeInterviewVacancyField";
import VacancyViewProposeInterviewCandidateField from "../VacancyViewProposeInterviewCandidateField/VacancyViewProposeInterviewCandidateField";
import { Link } from "react-router";
import { Button } from "common/Button";
import useFindBestTimes from "../api/useFindBestTimes";
import Skeleton from "react-loading-skeleton";
import {
	formatAvailabilitySlots,
	getAttendees,
	getCalendarEventAttendees
} from "../../helper/utils";
import { useGetUser } from "hooks/useGetUser";
import flatten from "lodash/flatten";
import cx from "classnames";
import Tooltip from "common/Tippy";
import {
	ANOTHER_DAY_START_HOUR,
	MAX_DAYS_SELECTED
} from "../../helper/constants";
import CalendarMultipleSelect from "../ProposeInterviewCalendarMultipleSelect/ProposeInterviewCalendarMultipleSelect";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import TextArea from "common/text-area";

export default function VacancyViewProposeInterviewFirstStep({
	isVisible,
	job = {},
	useVacancyAndCandidateFields,
	timezone
}) {
	const connectedUser = useGetUser();
	const { worklowId } = useOnboardingStore();
	const {
		control,
		watch,
		setValue,
		getValues,
		formState: { errors }
	} = useFormContext();
	const candidate = watch("candidate");
	const vacancy = watch("vacancy");
	const dates = watch("dates");
	const attendees = watch("attendees");
	const { drawerState } = useVacancyStore();
	const [findBestTimesEnabled, setFindBestTimesEnabled] = React.useState(false);
	const [focusedDate, setFocusedDate] = React.useState(undefined);
	const [isFocused, setIsFocused] = React.useState(false);
	const { isLoading: isFindBestTimesLoading } = useFindBestTimes(
		{
			selected_dates: dates.map(date =>
				convertCalendarObjectToTimestampUsingTimezone(
					date,
					connectedUser.timezone
				)
			),
			attendees: flatten(
				attendees.map(({ children }) => children.map(({ id }) => id))
			)
		},
		{
			enabled: findBestTimesEnabled && attendees.length > 0,
			onSuccess: data => {
				setFindBestTimesEnabled(false);
				setValue(
					"times",
					formatAvailabilitySlots(data, connectedUser.timezone)
				);
			},
			onError: renderError
		}
	);

	React.useEffect(() => {
		if (dates?.length && !isFocused) {
			setFocusedDate(dates[0]);
			setIsFocused(true);
		}
	}, [dates, isFocused]);

	const onDateChange = (date, dates = [], onChange) => {
		const index = dates.findIndex(item => isSameDay(item, date));

		if (index !== -1) {
			dates.splice(index, 1);
			const timesResult = getValues("times").filter(
				time => !isSameDay(time.date, date)
			);
			setValue("times", timesResult);
			onChange([...dates]);
		} else if (dates.length === MAX_DAYS_SELECTED) {
			return;
		} else {
			addTime(date);
			const datesResult = [...dates, date];
			datesResult.sort(sortCalendarDates);
			onChange(datesResult);
		}
	};

	const filterTimes = date => {
		return getValues("times").filter(item => isSameDay(item.date, date));
	};

	const onAdd = date => addTime(date);
	const onRemove = id => {
		const timesResult = getValues("times").filter(time => time.id !== id);
		setValue("times", timesResult);
	};

	const addTime = date => {
		const today = window.moment.tz(timezone);
		const hours =
			today.get("year") === date.year &&
			today.get("month") + 1 === date.month &&
			today.get("date") === date.day
				? today.get("hours")
				: ANOTHER_DAY_START_HOUR;
		const time = {
			id: generateId(),
			date,
			startTime: new Time(hours + 1, 0),
			endTime: new Time(hours + 2, 0)
		};
		setValue("times", [...getValues("times"), time], {
			shouldValidate: true
		});
	};

	const isDateUnavailable = date => {
		return date.compare(today(getLocalTimeZone())) < 0;
	};

	const onFindBestTimes = () => {
		setFindBestTimesEnabled(true);
	};

	return (
		<>
			<div
				className={styles.form}
				style={{ display: isVisible ? "block" : "none" }}
			>
				{!useVacancyAndCandidateFields ? (
					<>
						<div className={styles.formTitle}>
							Plan and Notify {drawerState.candidateName} with the interview
							details
						</div>
						<div className={styles.candidateInfos}>
							<Avatar
								className={styles.avatar}
								size={42}
								src={drawerState.avatar}
								name={drawerState.candidateName}
							/>
							<div>
								<div className={styles.candidateName}>
									{drawerState.candidateName}
								</div>
								<div className={styles.candidatePosition}>
									{drawerState.mainFunction}
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className={styles.row}>
							<div className={styles.field}>
								<VacancyViewProposeInterviewVacancyField />
							</div>
							<div className={styles.field}>
								<VacancyViewProposeInterviewCandidateField />
							</div>
						</div>
						{candidate && (
							<div className={styles.candidateInfos}>
								<Avatar
									className={styles.avatar}
									size={42}
									src={candidate.avatar}
									name={`${candidate.first_name} ${candidate.last_name}`}
								/>
								<div>
									<div className={styles.candidateName}>
										{`${candidate.first_name} ${candidate.last_name}`}
									</div>
									<div className={styles.candidatePosition}>
										{candidate.user_function}
									</div>
								</div>
								<Link
									to={`/vacancy/${drawerState.job?._id}?application_id=${drawerState.applicationId}`}
									target="_blank"
									className={styles.viewApplication}
								>
									View Application
									<LinkExternalIcon />
								</Link>
							</div>
						)}
					</>
				)}
				<div className={styles.fields}>
					<div>
						<div className={styles.label}>
							<UsersIcon />
							<span>Invite attendees</span>
						</div>
						<VacancyViewAttendeesPicker
							selectedAttendees={watch("attendees")}
							queryFn={() =>
								useVacancyAndCandidateFields
									? getCalendarEventAttendees(
											connectedUser,
											vacancy?.value,
											worklowId
									  )
									: getAttendees(connectedUser, job._id)
							}
							queryId={vacancy?.value}
						/>
						<FieldError className={styles.error} error={errors.attendees} />
					</div>
					<div>
						<div className={styles.label}>
							<CalendarPlusIcon />
							<span>Date & Time</span>
						</div>
						<div className={styles.calendarContainer}>
							<Controller
								name="dates"
								control={control}
								render={({ field: { onChange, value } }) => {
									return (
										<div>
											<CalendarMultipleSelect
												onChange={day => onDateChange(day, value, onChange)}
												isMultiple
												className={styles.calendar}
												list={value}
												cellClassName={styles.cellClassName}
												isDateUnavailable={isDateUnavailable}
												focusedValue={focusedDate}
												onFocusChange={date => setFocusedDate(date)}
											/>
										</div>
									);
								}}
							/>

							<Controller
								control={control}
								name="times"
								render={({ field: { onChange } }) => (
									<div>
										<div className={styles.slotsPicker}>
											<div className={styles.slotsHeader}>
												<ClockIcon />
												<span>Select time</span>
												{dates.length === 0 ? (
													<Tooltip
														content="Please select at least one day"
														theme="dark"
													>
														<Button
															variant="outlined"
															text="Find best Times"
															icon={
																<StarsIcon
																	className={
																		dates.length === 0
																			? styles.disbaledIcon
																			: ""
																	}
																/>
															}
															rootClassName={styles.findBestTimesRootButton}
															className={cx(styles.findBestTimesButton, {
																[styles.disabled]: dates.length === 0
															})}
															onClick={onFindBestTimes}
														/>
													</Tooltip>
												) : (
													<Button
														variant="outlined"
														text="Find best Times"
														icon={<StarsIcon />}
														rootClassName={styles.findBestTimesRootButton}
														className={styles.findBestTimesButton}
														onClick={onFindBestTimes}
													/>
												)}
											</div>
											<div className={styles.timesList}>
												{isFindBestTimesLoading ? (
													<Skeleton height={40} />
												) : (
													<>
														{watch("dates")
															.sort(
																(a, b) =>
																	convertCalendarDateToTimestamp(a).getTime() -
																	convertCalendarDateToTimestamp(b).getTime()
															)
															.map(date => (
																<VacancyViewTimesList
																	times={filterTimes(date)}
																	date={date}
																	key={date}
																	onChange={onChange}
																	onAdd={onAdd}
																	onRemove={onRemove}
																	allTimes={watch("times")}
																/>
															))}
													</>
												)}
											</div>
										</div>
									</div>
								)}
							/>
						</div>
						<FieldError className={styles.error} error={errors.dates} />
						{!Array.isArray(errors.times) && (
							<FieldError className={styles.error} error={errors.times} />
						)}
					</div>
					<div>
						<div className={styles.label}>
							<MapPinIcon />
							<span>Location</span>
						</div>
						<div className={styles.locationPicker}>
							<VacancyViewLocationPicker />
							<FieldError
								className={styles.error}
								error={errors.locationValue}
							/>
						</div>
					</div>
					<div className={styles.noteContainer}>
						<div className={styles.label}>
							<CommentIcon />
							<span>Description</span>
						</div>

						<Controller
							name="note"
							control={control}
							render={({ field: { value, onChange } }) => (
								<TextArea value={value} onChange={onChange} />
							)}
						/>
						<FieldError className={styles.error} error={errors.note} />
					</div>
				</div>
			</div>
			<div
				className={styles.sidebar}
				style={{ display: isVisible ? "flex" : "none" }}
			>
				<VacancyViewPlannedInterviews job={job} timezone={timezone} />
			</div>
		</>
	);
}
