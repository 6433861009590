import React from "react";
import PropTypes from "prop-types";
import { REMOTE, REMOTE_LABEL } from "config";
import { addressObjectToString } from "config/helpers";
import cx from "classnames";
const AddressComposer = ({
	addressData,
	addressData: { street, number, box, zip, city, country },
	noClass,
	className
}) => {
	return (
		<span
			className={cx(className, noClass ? "" : "address-formatter")}
			style={{
				color: "black",
				fontSize: "14px",
				fontFamily: "BasierCircle",
				display: "block"
			}}
		>
			{addressData &&
				addressData.location_type !== REMOTE &&
				(city || country ? (
					<span>
						{addressObjectToString({
							street,
							number,
							box,
							zip,
							city,
							country
						})}
					</span>
				) : (
					"-"
				))}
			{addressData.location_type === REMOTE && (
				<span className="new-line">{REMOTE_LABEL}</span>
			)}
		</span>
	);
};

AddressComposer.propTypes = {
	addressData: PropTypes.shape({
		street: PropTypes.string,
		number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		box: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		zip: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string
	})
};

AddressComposer.defaultProps = {
	addressData: {}
};

export default AddressComposer;
