import LeaveHook from "common/LeaveHook";
import { isFilesValid } from "common/MessagingToolNewEmail/utils/helpers";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import { generateId, uploadToS3 } from "config/helpers";
import { client } from "lib/api-client";
import React, { useRef } from "react";
import { VisuallyHidden } from "react-aria";

const MessagingToolNewEmailFileUploader = ({
	children,
	wrapperClassName,
	allowedTypes,
	setFiles,
	visuallyHiddenClassName,
	setIsFilesLoading,
	isFilesLoading
}) => {
	const inputRef = useRef();
	const onChange = e => {
		const newFiles = Array.from(e.target.files);
		if (isFilesValid(newFiles)) {
			upload(newFiles);
			e.target.value = "";
		}
	};

	const upload = async filesToUpload => {
		setIsFilesLoading(true);
		const promises = await filesToUpload.map(async file => {
			const id = generateId();
			setFiles(files => [
				...files,
				{
					id,
					name: file.name,
					size: file.size,
					content_type: file.type,
					progress: 0
				}
			]);

			const response = await preSignS3(file);
			const result = await uploadToS3({
				response,
				file,
				onProgress(progress) {
					setFiles(files => {
						return files.map(f =>
							f.id === id ? { ...f, progress: Math.floor(progress) } : f
						);
					});
				}
			});

			return {
				url: result.url,
				id,
				name: file.name,
				size: file.size,
				content_type: file.type
			};
		});

		const newFiles = await Promise.all(promises);
		setFiles(files => {
			const newState = files.map(item => {
				const uploadFile = newFiles.find(file => file.id === item.id);
				if (uploadFile) return { ...item, url: uploadFile.url };
				else return item;
			});

			return newState;
		});
		setIsFilesLoading(false);
	};

	const preSignS3 = file => {
		return client(PRE_SIGNED_URL, {
			body: {
				name: file.name,
				folder: "mailbox",
				size: file.size,
				type: file.type
			}
		});
	};

	return (
		<LeaveHook dirty={isFilesLoading}>
			<div
				className={wrapperClassName}
				onClick={() => inputRef.current.click()}
			>
				{children}
				<VisuallyHidden className={visuallyHiddenClassName}>
					<input
						type="file"
						multiple
						ref={inputRef}
						onChange={onChange}
						accept={allowedTypes.join(", ")}
					/>
				</VisuallyHidden>
			</div>
		</LeaveHook>
	);
};

export default MessagingToolNewEmailFileUploader;
