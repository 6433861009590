import React from "react";
import styles from "./side-bar.module.scss";
import { Link } from "react-router";
import { routes } from "../../utils/constant";

const SideBar = () => {
	return (
		<div className={styles.container}>
			<div className={styles.title}>Calendar Settings</div>
			<ul className={styles.menu}>
				{routes.map((el, i) => (
					<Link key={i} to={el.url} activeClassName={styles.active}>
						<li>{el.label}</li>
					</Link>
				))}
			</ul>
		</div>
	);
};

export default SideBar;
