import _reduce from "lodash/reduce";
import _get from "lodash/get";

import { getNotificationsSettingsRoute } from "modules/notifications/components/settings/NotificationsSettingsContainer";
import { getMissionsContainerRoute } from "modules/contract/components/MissionsContainer";
import { getCompanyRoute } from "modules/company/components/MyCompany";
import { getMyConsultantsRoute } from "modules/consultancy/components/MyConsultants";
import { getFreelancerBidsListRoute } from "modules/bids/components/freelancerBidsList/FreelancerBidsListContainer";
import { getConsultancyBidsListRoute } from "modules/bids/components/consultancyBidList/ConsultancyBidListContainer";
import { getResumeRoute } from "modules/cv/components/ResumeContainer";
import { PROJECT_MANAGER, CLIENT } from ".";
import { getCandidateJobListRoute } from "modules/candidate/components/JobList";
import { APPROVED, OFFERED } from "config";
import { getSupplierCompanyRoute } from "../modules/consultancy/settings/company";
import { getSupplierInvitationsRoute } from "../modules/consultancy/invitations";
import { getGeneralSettingsRoute } from "modules/user/components/GeneralSettings";
import { getFollowedCompaniesRoute } from "modules/permanent/companies";
import { getPermanentJobDetailsRoute } from "modules/permanent/jobDetailsPage";
import { getCalendarRoute } from "modules/calendar/calendar";

const Links = (role, { isClientActivated }) => ({
	unconnected: [
		{
			label: "log in",
			route: "/sign-in",
			feature: "signin",
			hideIn: ["/sign-up", "/sign-in"]
		},
		{
			label: "sign up",
			route: "/sign-up",
			feature: "signup",
			hideIn: ["/sign-up"]
		}
	],

	freelancer: [
		{
			label: "job board",
			route: "/jobs/search",
			feature: "search_job",
			hideIn: ["/company"],
			children: [
				{
					label: "search",
					route: "/jobs/search",
					feature: "search_job",
					subRoutes: ["/jobs/view"]
				},
				{
					label: "my jobs",
					route: getFreelancerBidsListRoute(),
					feature: "my_list_bids",
					subRoutes: [getFreelancerBidsListRoute()]
				},
				{
					label: "search",
					route: "/jobs/view",
					hidden: true
				}
			]
		},
		{
			label: "missions",
			route: getMissionsContainerRoute(),
			feature: "read_mission",
			hideIn: ["/company"],
			children: [
				{
					label: "missions",
					route: getMissionsContainerRoute(),
					feature: "read_mission",
					subRoutes: ["/mission-proposal"]
				},
				{
					label: "timesheets",
					route: "/reporting/timesheets",
					subRoutes: ["/timesheet"],
					feature: "read_timesheet"
				},
				{
					label: "timesheet",
					route: "/timesheet",
					hidden: true
				}
			]
		},
		{
			label: "settings",
			route: getResumeRoute(),
			feature: "read_my_profile",
			hideIn: ["/company"],
			children: [
				{
					label: "my profile",
					route: getResumeRoute(),
					feature: "read_my_profile"
				},
				{
					label: "company",
					route: getCompanyRoute(),
					feature: "update_company_additional_info"
				},
				{
					label: "notifications",
					route: getNotificationsSettingsRoute(),
					feature: "update_company_additional_info"
				},
				{
					label: "Password",
					route: "/settings/change-password",
					feature: "change_password"
				},
				{
					label: "files",
					route: "/settings/files",
					feature: "setting_files"
				},
				{
					label: "Notifications center",
					route: "/notifications",
					hidden: true
				}
			]
		}
	],
	permanent: [
		{
			label: "my profile",
			route: getResumeRoute(),
			feature: "read_my_profile"
		},
		{
			label: "explore",
			route: "/jobs/search",
			feature: "search_job",
			hideIn: ["/company"],
			children: [
				{
					label: "search jobs",
					route: "/jobs/search",
					feature: "search_job",
					subRoutes: [getPermanentJobDetailsRoute(false)]
				},
				{
					label: "job details",
					route: getPermanentJobDetailsRoute(false),
					feature: "job_detail",
					hidden: true
				},
				{
					label: "my jobs",
					route: getCandidateJobListRoute(),
					feature: "my_list_bids",
					subRoutes: ["/pokes"]
				},
				{
					label: "companies",
					route: getFollowedCompaniesRoute(),
					feature: "followed_companies"
				},
				{
					label: "my jobs",
					route: "/pokes",
					hidden: true
				}
			]
		},
		{
			label: "settings",
			route: getGeneralSettingsRoute(),
			feature: "settings_general",
			children: [
				{
					label: "general",
					route: getGeneralSettingsRoute(),
					feature: "settings_general"
				},
				{
					label: "files",
					route: "/settings/files",
					feature: "settings_files"
				},
				{
					label: "Notifications center",
					route: "/notifications",
					hidden: true
				}
			]
		}
	],
	consultancy: [
		role !== PROJECT_MANAGER && {
			label: "job board",
			route: "/jobs/search",
			feature: "search_job",
			hideIn: ["/company"],
			children: [
				{
					label: "search",
					route: "/jobs/search",
					feature: "search_job",
					subRoutes: ["/jobs/view"]
				},
				{
					label: "My jobs",
					route: getConsultancyBidsListRoute(),
					feature: "my_list_bids",
					subRoutes: [getConsultancyBidsListRoute()]
				},
				{
					label: "jobs",
					route: "/job",
					hidden: true
				}
			]
		},
		{
			label: "missions",
			route: getMissionsContainerRoute(),
			feature: "read_mission",
			hideIn: ["/company"],
			children: [
				{
					label: "missions",
					route: getMissionsContainerRoute(),
					feature: "read_mission",
					subRoutes: ["/mission-proposal"]
				},
				{
					label: "timesheets",
					route: "/reporting/timesheets",
					subRoutes: ["/timesheet"],
					feature: "read_timesheet"
				},
				{
					label: "timesheet",
					route: "/timesheet",
					hidden: true
				}
			]
		},
		role !== PROJECT_MANAGER && {
			label: "My tasks",
			route: "/list-tasks",
			feature: "my_tasks",
			hideIn: ["/company"]
		},
		{
			label: "settings",
			route:
				role === PROJECT_MANAGER
					? getNotificationsSettingsRoute()
					: getMyConsultantsRoute(),
			feature: "read_my_profile",
			hideIn: ["/company"],
			children: [
				role !== PROJECT_MANAGER && {
					label: "my consultants",
					route: getMyConsultantsRoute(),
					feature: "read_my_profile",
					subRoutes: ["/user/profile"]
				},
				role !== PROJECT_MANAGER && {
					label: "my profile",
					route: "/settings/myprofile",
					feature: "get_my_profile"
				},
				role !== PROJECT_MANAGER && {
					label: "company",
					route: getSupplierCompanyRoute(),
					feature: "update_company_additional_info"
				},
				role !== PROJECT_MANAGER && {
					label: "Client Invitations",
					route: getSupplierInvitationsRoute(),
					feature: "update_company_additional_info"
				},
				{
					label: "notifications",
					route: getNotificationsSettingsRoute(),
					feature: "update_company_additional_info"
				},
				{
					label: "Password",
					route: "/settings/change-password",
					feature: "change_password"
				},
				role !== PROJECT_MANAGER && {
					label: "files",
					route: "/settings/files",
					feature: "setting_files"
				},
				{
					label: "Notifications center",
					route: "/notifications",
					hidden: true
				},
				{
					label: "profile",
					route: "/user/profile",
					hidden: true
				}
			]
		}
	],

	client: [
		{
			label: "home",
			route: "/home",
			hideIn: ["/company"]
		},
		{
			label: "permanent",
			route: "/vacancies/list",
			hideIn: ["/company"],
			children: [
				{
					label: "vacancies",
					route: "/vacancies/list",
					subRoutes: ["/vacancy/", "/vacancies/client/poke/"]
				},
				isClientActivated && {
					label: "search",
					route: "/vacancies/search",
					subRoutes: ["/search/", "/permanent/view"]
				},
				{
					label: "vacancies",
					route: "/vacancy",
					hidden: true
				},
				isClientActivated && {
					label: "search",
					route: "/permanent/view",
					hidden: true
				},
				isClientActivated && {
					label: "search",
					route: "/vacancies/client/poke/",
					hidden: true
				}
			]
		},
		{
			label: "temporary",
			route: "/jobs/list",
			hideIn: ["/company"],
			children: [
				{
					label: "requests",
					route: "/jobs/list",
					subRoutes: [
						"/job/",
						"/jobs/post",
						"/jobs/client/bids",
						"/user/profile"
					]
				},
				{
					label: "Missions",
					route: getMissionsContainerRoute(),
					subRoutes: ["/mission-proposal"]
				},
				{
					label: "timesheets",
					route: "/reporting/timesheets",
					subRoutes: ["/timesheet"]
				},
				{
					label: "timesheet",
					route: "/timesheet",
					hidden: true
				},
				{
					label: "requests",
					route: "/job",
					hidden: true
				},
				{
					label: "profile",
					route: "/user/profile",
					hidden: true
				}
			]
		},
		{
			label: "calendar",
			route: getCalendarRoute()
		},
		{
			label: "settings",
			route: "/settings/myprofile",
			hideIn: ["/company"],
			children: [
				{
					label: "my profile",
					route: "/settings/myprofile"
				},
				{
					label: "company",
					route: getCompanyRoute()
				},
				{
					label: "users",
					route: "/settings/users",
					subRoutes: ["settings/users"]
				},
				{
					label: "groups",
					route: "/settings/groups",
					hidden: true
				},
				{
					label: "notifications",
					route: getNotificationsSettingsRoute()
				},
				{
					label: "change password",
					route: "/settings/change-password"
				},
				{
					label: "files",
					route: "/settings/files"
				},
				{
					label: "Notifications center",
					route: "/notifications",
					hidden: true
				}
			]
		}
	]
});

export function getUserLinks(type, role) {
	const user = JSON.parse(localStorage.getItem("current_user")) || {};
	const userFeatures = user.features;

	const idStatus = _get(user, "company_identity_status");
	const paymentStatus = _get(user, "company_payment_status");
	const companyInformationStatus = _get(user, "company_information_status");

	const isClientActivated =
		idStatus === APPROVED ||
		(paymentStatus === APPROVED && paymentStatus === OFFERED) ||
		companyInformationStatus === APPROVED;

	return _reduce(
		Links(role, { isClientActivated })[type || "unconnected"],
		(list, el) => {
			if (el) {
				// FIXME: temp fix for hiding dashboard link in header
				if (type === CLIENT) {
					if (
						el.feature === undefined ||
						userFeatures.indexOf(el.feature) !== -1
					) {
						list.push(el);
					}
				} else {
					list.push(el);
				}
			}
			return list;
		},
		[]
	);
}

export default Links;
