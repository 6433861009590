import React from "react";
import CalendarSidebarSmallCalendar from "../calendar-sidebar-small-calendar/calendar-sidebar-small-calendar";
import CalendarCollaborativeGroups from "../calendar-collaborative-groups/calendar-collaborative-groups";

const CalendarSidebar = ({ calendarRef }) => {
	const timezone = calendarRef?.current
		? calendarRef.current.getApi().view.dateEnv.timeZone
		: null;

	if (!timezone) return null;
	return (
		<>
			<CalendarSidebarSmallCalendar timezone={timezone} />
			<CalendarCollaborativeGroups />
		</>
	);
};

export default CalendarSidebar;
