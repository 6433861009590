import React, { useEffect, useMemo, useState } from "react";
import styles from "./collaborative-groups-page.module.scss";
import { ReactComponent as UsersIcon } from "static/icons/multi-users.svg";
import { ReactComponent as LeaveIcon } from "static/icons/leave-icon.svg";
import { useCollaborativeGroupsForm } from "../../hooks/useCollaborativeGroupsForm";
import { Controller, useFieldArray } from "react-hook-form";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import CollaboratorsList from "./collaborators-list/collaborators-list";
import Delete from "common/AgTable/icons/Delete";
import Header from "../header/header";
import LeaveHook from "common/LeaveHook";
import useFetchCollaborativeGroupsSettings, {
	CALENDAR_COLLABORATIVE_GROUPS_SETTINGS_DETAILS
} from "../../hooks/api/useFetchCollaborativeGroupsSettings";
import {
	COLLABORATOR_GROUP_PERMISSIONS,
	COLLABORATOR_GROUP_ROLES
} from "modules/calendar-settings/components/collaborative-groups-page/collaborators-list/utils/constant";
import { FieldError } from "common/FieldError";
import loadable from "loadable-components";
import useFetchCollaborativeGroups, {
	CALENDAR_COLLABORATIVE_GROUPS
} from "modules/calendar-settings/hooks/api/useFetchCollaborativeGroups";
import { ReactComponent as FolderIcon } from "static/icons/folder-icon-grey.svg";
import useDeleteGroup from "modules/calendar-settings/hooks/api/useDeleteGroup";
import { renderError } from "config/helpers";
import { queryCache } from "react-query";
import useLeaveGroup from "modules/calendar-settings/hooks/api/useLeaveGroup";
import get from "lodash/get";
import toArray from "lodash/toArray";
import useSaveCalendarGroupSettings from "../../hooks/api/useSaveCalendarGroupSettings";
import toaster from "common/Toaster";
import { useSearchParam } from "react-use";
import {
	ADMIN_ROLE,
	STATUS_PENDING
} from "modules/calendar-settings/utils/constant";
import LoadingState from "./loading-state/loading-state";
import CollaboratorPicker from "./collaborator-picker/collaborator-picker";
import { DRAFT } from "config";
import { SEARCH_SETTINGS_CALENDAR_COLLABORATORS } from "modules/calendar-settings/hooks/api/useSearchCollaborators";
import { ReactComponent as PlusIcon } from "static/icons/plus-border.svg";
import { useGetUser } from "hooks/useGetUser";
import Tooltip from "common/Tippy";
import cx from "classnames";
import { historyPush } from "config/helpers";
import { getCollaborativeGroupsRoute } from "modules/calendar-settings/getRouteConfig";
import { stringify } from "query-string";

const GroupsConfirmModal = loadable(() =>
	import("../groups-confirm-modal/groups-confirm-modal")
);
const AddCollaborativeGroupDrawer = loadable(() =>
	import(
		"modules/calendar/components/add-collaborative-group-drawer/add-collaborative-group-drawer"
	)
);

const CollaborativeGroupsPage = () => {
	const user = useGetUser();
	const urlGroupId = useSearchParam("group");
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [leaveGroupModal, setLeaveGroupModal] = useState(false);
	const [deleteGroupModal, setDeleteGroupGroupModal] = useState(false);
	const [originalMembers, setOriginalMembers] = useState([]);
	const [
		showAddCollaborativeGroupDrawer,
		setShowAddCollaborativeGroupDrawer
	] = useState(false);
	const [nextSelectedGroup, setNextSelectedGroup] = useState(null);

	const setGroup = id => {
		historyPush(
			`${getCollaborativeGroupsRoute()}?${stringify({
				group: id
			})}`
		);
	};

	const {
		control,
		formState: { isDirty, errors },
		reset,
		handleSubmit,
		watch
	} = useCollaborativeGroupsForm();

	const {
		data: userGroups,
		isLoading: isGroupsLoading
	} = useFetchCollaborativeGroups();

	const [saveSettings, { isLoading: isSaving }] = useSaveCalendarGroupSettings({
		onError: renderError,
		onSuccess: () => {
			toaster.success("Group Successfully Updated.");
			queryCache.invalidateQueries(CALENDAR_COLLABORATIVE_GROUPS);
			queryCache.invalidateQueries(
				CALENDAR_COLLABORATIVE_GROUPS_SETTINGS_DETAILS
			);
			queryCache.invalidateQueries(SEARCH_SETTINGS_CALENDAR_COLLABORATORS);
		}
	});

	const [leaveGroup, { isLoading: isLeaveGroupLoading }] = useLeaveGroup({
		onError: renderError,
		onSuccess: () => {
			toaster.success(`You left “${selectedGroup?.name}” `);
			queryCache.invalidateQueries(CALENDAR_COLLABORATIVE_GROUPS);
			setLeaveGroupModal(false);
		}
	});

	const [deleteGroup, { isLoading: isDeleteGroupLoading }] = useDeleteGroup({
		onError: renderError,
		onSuccess: () => {
			toaster.success(`“${selectedGroup?.name}” has been deleted`);
			queryCache.invalidateQueries(CALENDAR_COLLABORATIVE_GROUPS);
			setDeleteGroupGroupModal(false);
		}
	});

	const formattedGroups = useMemo(() => {
		if (userGroups) {
			return userGroups.map(el => ({
				id: el._id,
				name: el.group_name
			}));
		}
		return [];
	}, [userGroups]);

	useEffect(() => {
		if (formattedGroups.length > 0) {
			const urlGroup = formattedGroups.find(el => el.id === urlGroupId);
			setSelectedGroup(urlGroup || formattedGroups[0]);
		}
	}, [formattedGroups, urlGroupId]);

	const {
		data: settingsData,
		isLoading: isLoadingGroupSettings
	} = useFetchCollaborativeGroupsSettings(
		{
			group_id: selectedGroup?.id
		},
		{
			enabled: selectedGroup && selectedGroup.id
		}
	);

	useEffect(() => {
		if (settingsData) {
			const membersList = get(settingsData, "members", []).map(member => {
				return {
					id: member.user_id,
					label: `${member.user.first_name} ${member.user.last_name}`,
					firstName: member.user.first_name,
					lastName: member.user.last_name,
					avatar: member.user.avatar.length > 0 ? member.user.avatar : null,
					headline: member.user.user_function,
					status: member.status,
					role: toArray(COLLABORATOR_GROUP_ROLES).filter(
						el => el.value === member.role
					)[0],
					permission:
						member.permissions.length > 0
							? toArray(COLLABORATOR_GROUP_PERMISSIONS).find(
									el => el.value === member.permissions[0].permission_level
							  )
							: COLLABORATOR_GROUP_PERMISSIONS.freeBusy
				};
			});

			reset({
				groupName: settingsData.group_name,
				userRole: settingsData.current_user_role,
				collaborators: [
					...membersList.filter(el => el.id !== user.id),
					...membersList.filter(el => el.id === user.id)
				]
			});
			setOriginalMembers(membersList);
		}
	}, [settingsData]);

	const { fields, remove, prepend } = useFieldArray({
		control,
		name: "collaborators",
		keyName: "fieldId"
	});

	const onRemove = ({ index }) => {
		remove(index);
	};

	const onAdd = val => {
		const items = val.map(el => {
			return {
				...el,
				status: DRAFT,
				permission: COLLABORATOR_GROUP_PERMISSIONS.freeBusy,
				role: COLLABORATOR_GROUP_ROLES.viewer
			};
		});

		items.forEach(el => prepend(el));
	};

	const onGroupChange = ({ key }) => {
		if (selectedGroup?.id === key) return;
		if (isDirty && !nextSelectedGroup) {
			setNextSelectedGroup(key);
			return;
		}
		setGroup(key);
	};

	const onDiscard = () => {
		if (nextSelectedGroup) {
			setGroup(nextSelectedGroup);
			setNextSelectedGroup(null);
		} else {
			reset();
		}
	};

	const onClose = () => {
		if (nextSelectedGroup) {
			setNextSelectedGroup(null);
		} else {
			setShowUnsavedChangesModal(false);
		}
	};

	const onSubmit = values => {
		const collaboratorsList = get(values, "collaborators", []);
		const collaboratorsListIds = collaboratorsList.map(col => col.id);
		const originalMembersIds = originalMembers.map(col => col.id);

		const removedMembers = originalMembers
			.filter(el => !collaboratorsListIds.includes(el.id))
			.map(el => el.id);

		const addedMembers = collaboratorsList
			.filter(el => !originalMembersIds.includes(el.id))
			.map(el => {
				return {
					user_id: el.id,
					role_name: el.role.value
				};
			});

		const data = {
			group_id: selectedGroup.id,
			group_name: values.groupName,
			new_members: addedMembers,
			deleted_members: removedMembers,
			updated_members: collaboratorsList
				.filter(el => {
					const dbMember = originalMembers.find(
						element => element.id === el.id
					);
					if (
						dbMember &&
						(dbMember.permission.value !== el.permission.value ||
							dbMember.role.value !== el.role.value)
					) {
						return el;
					}
				})
				.filter(Boolean)
				.map(el => {
					return {
						user_id: el.id,
						role_name: el.role.value,
						permission: el.permission.value
					};
				})
		};
		saveSettings(data);
	};

	const isAdmin = watch("userRole") === ADMIN_ROLE;

	const groupHasOneAdmin = useMemo(() => {
		return (
			originalMembers.filter(
				el =>
					el.role.value === ADMIN_ROLE &&
					el.status !== STATUS_PENDING &&
					el.id !== user.id
			).length === 0
		);
	}, [originalMembers]);

	const onCollaborativeGroupCreate = groupId => {
		queryCache.invalidateQueries(CALENDAR_COLLABORATIVE_GROUPS);
		setGroup(groupId);
	};

	return (
		<>
			<LeaveHook
				dirty={isDirty || showUnsavedChangesModal}
				enforceWarning={nextSelectedGroup}
				confirmationModal={{
					onDiscard,
					onClose,
					isLoading: isSaving,
					disabled: isSaving,
					description:
						nextSelectedGroup &&
						"You have unsaved changes. Are you sure you want to change the group without saving?"
				}}
			/>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Header
					onDiscard={onDiscard}
					isSaving={isSaving}
					allowSave={isDirty}
					disableActions={
						!userGroups || isGroupsLoading || isLoadingGroupSettings
					}
				/>
				{isGroupsLoading || isLoadingGroupSettings ? (
					<LoadingState />
				) : (
					<div className={styles.wrapper}>
						<div className={styles.collaboratorsTitle}>
							<div className={styles.title}>
								<UsersIcon />
								Collaborative Groups
							</div>
							<Tooltip
								content={
									"Unsaved changes. Please save before creating a new group."
								}
								theme="dark"
								overflow={"hidden"}
								addTooltip={isDirty}
								fromBtn
							>
								{/* using disabled on the button disables the tooltip as well */}
								<button
									className={cx(styles.addBtn, { [styles.disabled]: isDirty })}
									type="button"
									onClick={() => {
										if (!isDirty) setShowAddCollaborativeGroupDrawer(true);
									}}
								>
									<PlusIcon />
									New Group
								</button>
							</Tooltip>
						</div>
						{userGroups && userGroups.length === 0 ? (
							<div className={styles.emptyState}>
								<FolderIcon />
								<div className={styles.title}>No Group Found</div>
								<div className={styles.description}>
									{`It looks like you're not a member of any collaborative group
							yet.`}
									<br /> Create a new group or join an existing one.
								</div>
							</div>
						) : (
							<>
								<div className={styles.flex}>
									<div className={styles.formInput}>
										<label className={styles.label}>Select a group</label>

										<ComboBox
											isReadonlyInput={true}
											inputValue={selectedGroup?.name}
											selectedKey={selectedGroup?.id}
											inputRootClassName={styles.groupNameSelect}
											onSelectionChange={key => onGroupChange({ key })}
										>
											{formattedGroups.map(({ id, name }) => {
												return <Item key={id}>{name}</Item>;
											})}
										</ComboBox>
									</div>
									{isAdmin && (
										<div className={styles.formInput}>
											<label className={styles.label}>Group Name</label>

											<Controller
												name="groupName"
												control={control}
												render={({ field: { value, onChange } }) => {
													return (
														<input
															className={styles.nameInput}
															type="text"
															value={value}
															onChange={e => onChange(e.target.value)}
														/>
													);
												}}
											/>

											<FieldError
												error={errors.groupName}
												className={styles.marginTop}
											/>
										</div>
									)}
								</div>
								<div className={styles.collaboratorsTitle}>
									<div className={styles.title}>
										<UsersIcon />
										Group Members
									</div>
									{isAdmin && (
										<CollaboratorPicker onAdd={onAdd} fields={fields} />
									)}
								</div>
								<CollaboratorsList
									control={control}
									remove={onRemove}
									fields={fields}
									showHeader={true}
									adminAccess={isAdmin}
									classNames={
										!isAdmin && {
											role: styles.roleCol,
											close: styles.closeCol
										}
									}
								/>

								<div className={styles.actions}>
									{(!groupHasOneAdmin || !isAdmin) && (
										<button
											type="button"
											className={styles.leave}
											onClick={() => setLeaveGroupModal(true)}
										>
											<LeaveIcon />
											Leave Group
										</button>
									)}
									{isAdmin && (
										<button
											type="button"
											className={styles.delete}
											onClick={() => setDeleteGroupGroupModal(true)}
										>
											<Delete height={20} width={20} color={"#fff"} />
											Delete Group
										</button>
									)}
								</div>
							</>
						)}
					</div>
				)}
			</form>

			{leaveGroupModal && (
				<GroupsConfirmModal
					onClose={() => setLeaveGroupModal(false)}
					title={`Leave "${watch("groupName")}"`}
					description={`Are you sure you want to leave this collaborative group? You will no longer have access to the calendar's events and availability information.`}
					btnText={"Leave Group"}
					isLoading={isLeaveGroupLoading}
					onConfirm={() => {
						leaveGroup({ group_id: selectedGroup?.id });
					}}
				/>
			)}

			{deleteGroupModal && (
				<GroupsConfirmModal
					onClose={() => setDeleteGroupGroupModal(false)}
					title={`Delete "${watch("groupName")}"`}
					description={`Are you sure you want to delete this collaborative group? This action cannot be undone, and all members will lose access to the collaborative group.`}
					btnText={"Delete Group"}
					isLoading={isDeleteGroupLoading}
					onConfirm={() => {
						deleteGroup({ group_id: selectedGroup?.id });
					}}
				/>
			)}
			{showAddCollaborativeGroupDrawer && (
				<AddCollaborativeGroupDrawer
					onClose={() => setShowAddCollaborativeGroupDrawer(false)}
					onSave={onCollaborativeGroupCreate}
				/>
			)}
		</>
	);
};

export default CollaborativeGroupsPage;
