import React, { useMemo } from "react";
import styles from "./messaging-tool-email-item.module.scss";
import Avatar from "common/Avatar";
import cx from "classnames";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";
import { generateId, historyReplace } from "config/helpers";
import useNotesStore from "modules/notes/notes.store";
import { Checkbox } from "common/AgTable/components/Checkbox";
import { getMessagingToolRoute } from "modules/MessagingTool/MessagingTool";
import { NEW_MAIL } from "modules/notes/constants";

const MessagingToolEmailItem = ({
	children,
	unread = false,
	className,
	emailId,
	email,
	hash,
	onRedirectToEmailView: onRedirectToEmailViewProp = null,
	selected = false
}) => {
	const { addNote: addEmail, notes: emails } = useNotesStore();
	const { setActiveNote } = useNotesStore(); //TODO
	const isEmailActive = useMemo(
		() => emails.find(item => item.id === emailId),
		[emails, emailId]
	);

	const onRedirectToEmailView = () => {
		if (isEmailActive) return;
		if (onRedirectToEmailViewProp) {
			onRedirectToEmailViewProp();
			return;
		}
		if (hash === MESSAGING_TOOL_LINKS.draft.slug) {
			if (email.parent_id && email.send_failed) {
				historyReplace(getMessagingToolRoute(`${hash}/${email.parent_id}`));
				return;
			}
			const id = generateId();
			setActiveNote(id);
			addEmail({
				id: email._id,
				type: NEW_MAIL,
				_id: email._id,
				to: email.to.map(user => ({ email: user.email })),
				cc: email.cc.map(user => ({ email: user.email })),
				bcc: email.bcc.map(user => ({ email: user.email })),
				subject: email.subject,
				content: email.content,
				content_placeholders: email.content_placeholders,
				attachments: email.attachments.map(file => ({
					url: file.url,
					id: generateId(),
					name: file.filename,
					size: file.size,
					content_type: file.content_type
				})),
				scheduled_at: email.scheduled_at
			});
		} else {
			historyReplace(
				getMessagingToolRoute(`${hash}/${email.parent_id || emailId}`)
			);
		}
	};

	return (
		<button
			className={cx(
				styles.wrapper,
				className,
				{
					[styles.unread]: unread
				},
				selected
					? {
							[styles.selected]: selected
					  }
					: {
							[styles.unread]: unread
					  }
			)}
			onClick={onRedirectToEmailView}
		>
			{children}
		</button>
	);
};

export const UserInfo = ({ username, proficiency, hash, status, avatar }) => (
	<div className={styles.userInfo}>
		{hash === MESSAGING_TOOL_LINKS.sent.slug && (
			<span className={styles.to}>To: </span>
		)}
		{hash === MESSAGING_TOOL_LINKS.draft.slug ? (
			<span className={styles.draft}>{status}</span>
		) : (
			<div>
				<Avatar
					name={username.split(",")[0]}
					radius={100}
					size={32}
					avatar={avatar}
				/>
			</div>
		)}
		<div className={styles.info}>
			<div className={styles.username}>{username}</div>
			<div className={styles.proficiency}>{proficiency}</div>
		</div>
	</div>
);

export const EmailDetails = ({ subject, message, countReplies }) => (
	<div className={styles.emailInfo}>
		<div className={styles.subject}>
			{subject}
			{countReplies > 0 && (
				<span className={styles.countReplies}>{countReplies}</span>
			)}
		</div>
		<div className={styles.emailText}>{message}</div>
	</div>
);

export const EmailCheckbox = ({ onChange, checked }) => {
	return (
		<Checkbox
			width={22}
			height={22}
			className={styles.checkbox}
			checked={checked}
			onChange={onChange}
		/>
	);
};

MessagingToolEmailItem.Checkbox = EmailCheckbox;
MessagingToolEmailItem.UserInfo = UserInfo;
MessagingToolEmailItem.EmailDetails = EmailDetails;

export default MessagingToolEmailItem;
