import { get } from "lodash";
import { EVENT_STYLE, LOCATION_TYPES, SCHEDULE_MENU } from "./constants";
import { generateId, timestampToDateCalendar } from "config/helpers";
import { composeAddress } from "modules/vacancy/components/vacancyView/helper/utils";
import moment from "moment-timezone";
import { displayAttendeeName } from "../components/add-calendar-event-drawer/utils/helpers";
import { REMINDER_BEFORE } from "config";
import cx from "classnames";

export const formatEventsList = ({
	events,
	eventClassName,
	interviewClassName,
	attendeeClassName,
	oldEventStyle,
	user
}) => {
	if (!events) return [];

	return events.map(event => {
		const isOwner =
			(user.id === event.owner?._id || user.email === event.owner?.email) &&
			(user.id === event.organizer?._id ||
				user.email === event.organizer?.email);
		const isAttendee = event.participants?.find(item => item._id === user.id);
		const now = window
			.moment()
			.tz(user.timezone)
			.unix();
		const eventStartTime = moment
			.unix(event.when.start_time)
			.tz(user.timezone)
			.unix();

		const isOld = eventStartTime < now;
		return {
			id: generateId(),
			event_id: event._id,
			title: event.title,
			start: new Date(event.when.start_time * 1000),
			end: new Date(event.when.end_time * 1000),
			color: EVENT_STYLE.color,
			textColor: EVENT_STYLE.textColor,
			backgroundColor: EVENT_STYLE.backgroundColor,
			borderColor: EVENT_STYLE.borderColor[event.type],
			editable: event.type !== SCHEDULE_MENU.interview.key && isOwner,
			className: cx(
				eventClassName,
				event.type === SCHEDULE_MENU.interview.key ? interviewClassName : "",
				!isOwner && attendeeClassName,
				isOld && oldEventStyle
			),
			isAttendee:
				event.type === SCHEDULE_MENU.interview.key &&
				(!!isAttendee || (!!isAttendee && !isOwner)),
			isNotAttendee: event.type === SCHEDULE_MENU.interview.key && !isAttendee,
			isOld,
			...event
		};
	});
};

export const formatEventPayload = eventInfo => {
	const event = eventInfo.event._def.extendedProps;
	return {
		id: event._id,
		start_time: eventInfo.startTime,
		end_time: eventInfo.endTime
	};
};

export const formatEventTime = (time, timezone) => {
	let startDate = moment.unix(time.start_time).tz(timezone);
	let endDate = moment.unix(time.end_time).tz(timezone);

	const startTime = {
		hour: startDate.hour(),
		minute: startDate.minute()
	};

	const endTime = {
		hour: endDate.hour(),
		minute: endDate.minute()
	};

	return { startTime, endTime };
};
export const formatCalendarEventForEdit = (currentEvent, timezone) => {
	const startDate = timestampToDateCalendar(
		currentEvent.when.start_time,
		timezone
	);

	const { startTime, endTime } = formatEventTime(currentEvent.when, timezone);

	const addressObject = {
		number: currentEvent?.event_location?.number || "",
		box: currentEvent?.event_location?.box || "",
		street: currentEvent?.event_location?.street || "",
		zip: currentEvent?.event_location?.zip || "",
		city: currentEvent?.event_location?.city || "",
		country: currentEvent?.event_location?.country || "",
		iso_country: currentEvent?.event_location?.iso_country || "",
		latitude: currentEvent?.event_location?.latitude
			? parseFloat(currentEvent?.event_location?.latitude)
			: null,
		longitude: currentEvent?.event_location?.longitude
			? parseFloat(currentEvent?.event_location?.longitude)
			: null,
		viewport:
			currentEvent?.event_location?.viewport &&
			currentEvent?.event_location?.viewport?.northeast
				? currentEvent?.event_location?.viewport
				: {
						northeast: {},
						southwest: {}
				  },
		is_main: true
	};

	const eventReminder = currentEvent.reminders?.[0]?.value;

	return {
		title: get(currentEvent, "title", ""),
		note: get(currentEvent, "description", ""),
		attendees: [
			{
				id: 1,
				label: "Collaborators",
				children: get(currentEvent, "participants", []).map(el => {
					return {
						id: el._id,
						label: displayAttendeeName(el),
						email: el.email,
						avatar: el.avatar,
						rating: 1
					};
				})
			}
		],
		locationType:
			get(currentEvent, "location_type", LOCATION_TYPES.online.value) ||
			LOCATION_TYPES.online.value,
		date: startDate,
		time: {
			startTime,
			endTime
		},
		tmpLocationValue:
			currentEvent.location_type === LOCATION_TYPES.companyAddress.value
				? {
						value: JSON.stringify(addressObject),
						label: composeAddress(addressObject)
				  }
				: currentEvent.location_type === LOCATION_TYPES.anotherLocation.value
				? JSON.stringify(addressObject)
				: "",
		reminder: eventReminder
			? REMINDER_BEFORE.find(
					el => parseInt(el.value) === parseInt(eventReminder)
			  )
			: REMINDER_BEFORE[0]
	};
};
