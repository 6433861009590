import React from "react";
import { ReactComponent as CalendarOneIcon } from "static/icons/calendar-one.svg";
import { ReactComponent as UsersIcon } from "static/icons/users.svg";
import { ReactComponent as ClockIcon } from "static/icons/clock-1.svg";
import styles from "./planned-interviews-upcoming.module.scss";
import cx from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useGetAttendeesSchedule from "../api/use-get-attendees-schedule";
import { useFormContext } from "react-hook-form";
import flatten from "lodash/flatten";
import { convertCalendarObjectToTimestampUsingTimezone } from "config/helpers";
import { formatDateUsingTimezone } from "common/Functions";
import { DATE_FORMAT } from "config";

export default function PlannedInterviewsUpcoming({ timezone }) {
	const { watch } = useFormContext();
	const dates = watch("dates");
	const attendees = watch("attendees");
	const { data, isLoading } = useGetAttendeesSchedule(
		{
			selected_dates: dates.map(date =>
				convertCalendarObjectToTimestampUsingTimezone(date, timezone)
			),
			attendees: flatten(
				attendees.map(({ children }) => children.map(({ id }) => id))
			)
		},
		{
			enabled: dates.length > 0 && attendees.length > 0
		}
	);
	return (
		<div>
			<div className={styles.scheduleHeader}>
				<UsersIcon />
				<div>
					<div className={styles.scheduleTitle}>Attendee Schedules</div>
				</div>
			</div>
			{isLoading ? (
				<div className={styles.scheduleList}>
					<SkeletonTheme color="#E2E8F0">
						{Array(4)
							.fill(0)
							.map(item => (
								<Skeleton key={item} height={47} className={styles.skeleton} />
							))}
					</SkeletonTheme>
				</div>
			) : (
				<div className={styles.scheduleList}>
					{!data || data?.length === 0 ? (
						<span>No available schedules</span>
					) : (
						data?.map(item => {
							return (
								<div
									className={cx(styles.scheduleItem, styles.yellow)}
									key={item._id}
								>
									<div className={styles.headline}>
										<span className={styles.name}>
											{item.first_name} {item.last_name}
										</span>
										<div className={styles.badge}>Busy</div>
									</div>
									<div className={styles.datetime}>
										<div className={styles.date}>
											<CalendarOneIcon width={18} height={18} />
											<span>
												{formatDateUsingTimezone(
													item.start_date,
													true,
													DATE_FORMAT,
													false,
													timezone
												)}
											</span>
										</div>
										<div className={styles.time}>
											<ClockIcon width={18} height={18} />
											<span>
												{formatDateUsingTimezone(
													item.start_date,
													true,
													"HH:mm",
													false,
													timezone
												)}{" "}
												-{" "}
												{formatDateUsingTimezone(
													item.end_date,
													true,
													"HH:mm",
													false,
													timezone
												)}
											</span>
										</div>
									</div>
								</div>
							);
						})
					)}
				</div>
			)}
		</div>
	);
}
