import React, { useEffect, useRef, useState } from "react";
import styles from "./messaging-tool-new-email-placeholders-decorator-component.module.scss";
import cx from "classnames";
import loadable from "loadable-components";
import { useFormContext } from "react-hook-form";
const MessagingToolNewEmailPlaceholdersDecoratorComponentModal = loadable(() =>
	import(
		"../MessagingToolNewEmailPlaceholdersDecoratorComponentModal/MessagingToolNewEmailPlaceholdersDecoratorComponentModal"
	)
);

const MessagingToolNewEmailPlaceholdersDecoratorComponent = ({
	placeholder
}) => {
	const shouldUpdatePlaceholder = useRef(true);
	const [showPlaceholderModal, setShowPlaceholderModal] = useState(false);
	const { watch, setValue } = useFormContext();
	const contentPlaceholders = watch("content_placeholders");
	const hasValue = contentPlaceholders.find(
		item => item?.entity === placeholder?.entity
	)?._id;
	const isReadOnly = contentPlaceholders.find(
		item => item?.entity === placeholder?.entity
	)?.readOnly;
	useEffect(() => {
		const participants = watch("to");
		if (
			participants.length === 1 &&
			["candidate", "recipient"].includes(placeholder.entity) &&
			contentPlaceholders.length > 0 &&
			shouldUpdatePlaceholder.current
		) {
			const placeholders = contentPlaceholders.map(item => {
				if (item.value === placeholder?.value)
					return {
						...item,
						entity: placeholder.entity,
						value: placeholder.value,
						_id: participants[0]._id,
						label: participants[0].full_name
					};
				return item;
			});
			setValue("content_placeholders", placeholders);
			shouldUpdatePlaceholder.current = false;
		}
	}, [watch("to"), contentPlaceholders]);

	return (
		<>
			<button
				className={cx(styles.placeholder, {
					[styles.hasValue]: hasValue,
					[styles.readOnly]: isReadOnly
				})}
				type="button"
				onClick={() => setShowPlaceholderModal(true)}
			>
				<span className={styles.placeholderLabel}>
					{`${placeholder.entity}.${placeholder.key}`}
				</span>
			</button>

			{showPlaceholderModal && (
				<MessagingToolNewEmailPlaceholdersDecoratorComponentModal
					setShowPlaceholderModal={setShowPlaceholderModal}
					placeholders={contentPlaceholders}
				/>
			)}
		</>
	);
};

export default MessagingToolNewEmailPlaceholdersDecoratorComponent;
