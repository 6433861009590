import { DELETE_CALENDAR_GROUP_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

const deleteGroup = body =>
	client(DELETE_CALENDAR_GROUP_ENDPOINT, {
		body
	});

const useDeleteGroup = options => {
	return useMutation(deleteGroup, {
		...options
	});
};

export default useDeleteGroup;
