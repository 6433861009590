import React from "react";
import cx from "classnames";
import style from "./mission-details-header.module.scss";
import { ReactComponent as BackArrow } from "static/icons/IconBack.svg";
import { goBack } from "config/helpers";
import { ReactComponent as CalendarPlus } from "static/icons/calendar-plus.svg";
import { ReactComponent as SlashCircle } from "static/icons/slash-circle.svg";
import { IconButton } from "common/IconButton";
import { ReactComponent as EditIcon } from "static/icons/edit-grey.svg";
import Tooltip from "common/Tippy";

const MissionDetailsHeader = ({
	isExtensionEnabled,
	onOpenExtensionDrawer,
	onOpenInterruptDrawer,
	setOpenEditMissionDrawer,
	isInterruptDisable,
	tooltipContent,
	canUserEditMission,
	canUserExtendMission,
	canUserInterruptMission
}) => {
	return (
		<div className={style.header}>
			<div className={style.headerTitle}>
				<IconButton className={style.btn} onClick={goBack}>
					<BackArrow />
				</IconButton>
				<span className={style.title}>Mission Details</span>
			</div>

			<div className={style.btnContainer}>
				{canUserExtendMission && (
					<div>
						<button
							className={cx(style.extend, {
								[style.extendDisable]: !isExtensionEnabled
							})}
							onClick={onOpenExtensionDrawer}
							disabled={!isExtensionEnabled}
						>
							<CalendarPlus
								className={cx({
									[style.iconDisabled]: !isExtensionEnabled
								})}
								width="1.125rem"
							/>
							Extend mission
						</button>
					</div>
				)}

				{canUserInterruptMission && (
					<Tooltip
						key="interruptContent"
						content={
							<span className={style.tooltipContent}>
								{tooltipContent?.interruptContent}
							</span>
						}
						placement="bottom"
						disabled={!tooltipContent?.interruptContent}
						theme="dark"
					>
						<IconButton
							className={cx(style.interrupt, {
								[style.interruptDisable]: isInterruptDisable
							})}
							onClick={onOpenInterruptDrawer}
							isDisabled={isInterruptDisable}
						>
							<SlashCircle
								className={cx({
									[style.iconDisabled]: isInterruptDisable
								})}
							/>
						</IconButton>
					</Tooltip>
				)}
				{canUserEditMission && (
					<IconButton
						className={style.editManager}
						onClick={() => setOpenEditMissionDrawer(true)}
					>
						<EditIcon />
					</IconButton>
				)}
			</div>
		</div>
	);
};
export default MissionDetailsHeader;
