import React from "react";
import CollaborativeGroupInvitationPage from "./components/collaborative-group-invitation-page/collaborative-group-invitation-page";

export const getCollaborativeGroupInvitationRoute = (groupId, invitationId) => {
	return `/group-invitation/view/${groupId || ":groupId"}/${invitationId ||
		":invitationId"}`;
};

export const collaborativeGroupInvitationRouteRegex = /^\/group-invitation\/view\/([^/]+)\/([^/]+)$/;

export const getCollaborativeGroupInvitationPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const CollaborativeGroupInvitation = ({
	params: { groupId, invitationId }
}) => {
	return (
		<CollaborativeGroupInvitationPage
			groupId={groupId}
			invitationId={invitationId}
		/>
	);
};

export default CollaborativeGroupInvitation;
