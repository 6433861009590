import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getCandidates = body => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body
	});
};

const useGetCandidates = (body, options) => {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery([body], () => {
			return Promise.resolve([]);
		});
	}
	return useQuery([body], () => getCandidates(body), {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
};

export default useGetCandidates;
