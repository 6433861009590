import cx from "classnames";
import { Paper } from "common/Paper";
import React, { forwardRef, memo } from "react";
import {
	FocusScope,
	mergeProps,
	useDialog,
	useModal,
	useOverlay
} from "react-aria";
import styles from "./popover.module.scss";

const Popover = (
	{
		children,
		isOpen,
		onClose,
		style,
		paperClassName,
		paperStyle,
		multiSite = false,
		isDismissable = true,
		...otherProps
	},
	ref
) => {
	// Handle interacting outside the dialog and pressing
	// the Escape key to close the modal.
	let { overlayProps } = useOverlay(
		{
			onClose,
			isOpen,
			isDismissable
		},
		ref
	);

	// Hide content outside the modal from screen readers.
	let { modalProps } = useModal();

	// Get props for the dialog and its title
	let { dialogProps } = useDialog({}, ref);

	return (
		<FocusScope restoreFocus>
			<Paper
				className={cx(
					paperClassName,
					multiSite ? styles.multiSitePaper : styles.paper
				)}
				{...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
				ref={ref}
				style={{
					...style,
					...paperStyle
				}}
			>
				{children}
			</Paper>
		</FocusScope>
	);
};

export default memo(forwardRef(Popover));
