import Tooltip from "common/Tippy";
import React from "react";
import styles from "./vacancy-view-attendees-picker.module.scss";
import cx from "classnames";
import { ReactComponent as AddIcon } from "static/icons/add-icon-circle.svg";
import { CustomAvatar } from "common/CustomAvatar";
import { Picker } from "common/Picker";
import { Controller, useFormContext } from "react-hook-form";
import get from "lodash/get";
import { useGetUser } from "hooks/useGetUser";
import { COLLABORATOR_GROUP_ROLES } from "modules/calendar-settings/components/collaborative-groups-page/collaborators-list/utils/constant";
import { generateId } from "config/helpers";

const VacancyViewAttendeesPicker = ({
	queryFn,
	selectedAttendees,
	queryId
}) => {
	const connectedUser = useGetUser();
	const { control, watch, setValue } = useFormContext();
	const vacancy = watch("vacancy");

	React.useEffect(() => {
		const groupId = get(vacancy, "group._id");
		if (groupId) {
			const newAttendees = get(selectedAttendees, "[0].children", []).filter(
				item => {
					if (item.groups)
						return (
							item.groups.some(group => group.group_id === groupId) ||
							item.active_role === COLLABORATOR_GROUP_ROLES.admin.value
						);
				}
			);
			const user = {
				id: connectedUser.id,
				label: `${connectedUser.first_name} ${connectedUser.last_name}`,
				avatar: connectedUser.avatar
			};
			setValue("attendees", [
				{
					id: 1,
					label: "Collaborators",
					children: [user, ...newAttendees]
				}
			]);
		}
	}, [vacancy]);
	return (
		<div className={styles.container}>
			<div>
				<div className={styles.collaboratorsContainer}>
					{!!selectedAttendees?.[0]?.children?.length && (
						<div className={styles.images}>
							{selectedAttendees[0].children.map(({ avatar, id, label }, i) => {
								return (
									<Tooltip key={id} content={label} theme="dark">
										<div className={styles.avatarWrapper}>
											<CustomAvatar
												src={avatar}
												variant="small"
												fullName={label}
												rootClassName={cx(styles.collaboratorPicture, {
													[styles.firstCollaboratorPicture]: i === 0
												})}
												imgClassName={cx(styles.picture)}
												avatarClassName={cx(
													styles.avatarWithBorder,
													styles.avatar,
													{
														[styles.firstCollaboratorPicture]: i === 0
													}
												)}
												avatarSize={30}
												rootStyle={{
													zIndex: i
												}}
												avatarStyle={{
													zIndex: i
												}}
											/>
										</div>
									</Tooltip>
								);
							})}
						</div>
					)}

					<Controller
						control={control}
						name="attendees"
						render={({ field: { onChange, value } }) => (
							<div>
								<Picker
									PrimaryHeaderContent={() => "collaborators remaining"}
									SecondaryHeaderContent={() => "collaborators selected"}
									displayRating={false}
									displaySelectedValues={false}
									addButtonComponent={props =>
										!vacancy?.value ? (
											<Tooltip
												content="Please select a vacancy first!"
												theme="dark"
											>
												<button
													className={cx(styles.pickerAddButton, {
														[styles.disabled]: !vacancy?.value
													})}
												>
													<AddIcon
														{...props}
														className={cx(styles.editIcon, {
															[styles.disabled]: !vacancy?.value
														})}
													/>
												</button>
											</Tooltip>
										) : (
											<AddIcon {...props} className={styles.editIcon} />
										)
									}
									placement="bottom"
									isMultipleSelect
									onConfirm={onChange}
									queryId={queryId}
									queryFn={queryFn}
									value={value}
									key={generateId()}
									useServerFilter={false}
									inlineChips
								/>
							</div>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default VacancyViewAttendeesPicker;
