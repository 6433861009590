import { MOVE_CALENDAR_EVENT_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

const moveEvent = body => {
	return client(MOVE_CALENDAR_EVENT_ENDPOINT, {
		body
	});
};

const useMoveEvent = options => {
	return useMutation(moveEvent, options);
};

export default useMoveEvent;
