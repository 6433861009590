import marked from "marked";
import toaster from "common/Toaster";
import moment from "moment";
import embed from "embed-video";
import { CalendarDate, Time } from "@internationalized/date";
import _capitalize from "lodash/capitalize";
import _get from "lodash/get";
import pick from "lodash/pick";
import axios from "axios";
import QS from "query-string";
import { browserHistory } from "react-router";
import { v4 as uuid } from "uuid";
import {
	DATE_FORMAT,
	JOB_SELECT_DAYS,
	JOB_SELECT_WEEKS,
	ROLES,
	REQUEST_STATUS,
	VACANCY_STATUS,
	LOCALSTORAGE_KEY,
	LOCALSTORAGE_CUMULIO_KEY,
	TIMEZONE,
	GOOGLE_API_KEY,
	IP_DATA_API_KEY,
	TIME_FORMAT_24,
	TIMESHEET_FREQUENCY_MONTHLY,
	TIMESHEET_FREQUENCY_WEEKLY,
	EXTERNAL_ACCESS_TOKEN,
	DAILY,
	HOURLY
} from "./index";
import { AVAILABLE_FEATURES } from "./features/actions";
import { getFileExtension } from "common/Functions";
import { JPEG, DOCX, DOC, PNG, JPG } from "config";
import { ReactComponent as PdfIcon } from "static/icons/download-pdf.svg";
import { ReactComponent as DocIcon } from "static/icons/icon-doc.svg";
import { ReactComponent as ImageIcon } from "static/icons/icon-image.svg";
import { DateTime } from "luxon";
import * as momentTimezone from "moment-timezone";

export const addressObjectToString = value => {
	//value doesn't have only street, box number, zip, city, country, it has keys like fullName...
	const { street, number, box, zip, city, country } = value;
	const address = { street, number, box, zip, city, country };
	const addressList = [];

	for (const key in address) {
		if (address[key]) {
			addressList.push(address[key]);
		}
		if (key === "number" && address.number && address.box) {
			addressList.push("/");
		}
	}

	return addressList.join(" ");
};

export const getCumulioConfig = () => {
	const cumulioConfig = localStorage.getItem(LOCALSTORAGE_CUMULIO_KEY);
	return cumulioConfig !== undefined && JSON.parse(cumulioConfig);
};

export const setCumulioConfig = cumulioConfig => {
	localStorage.setItem(LOCALSTORAGE_CUMULIO_KEY, JSON.stringify(cumulioConfig));
};

export const getLocalUser = () => {
	const user = localStorage.getItem(LOCALSTORAGE_KEY);
	return user !== undefined && JSON.parse(user);
};

export function convertToSeconds(dateTime, timezone) {
	const { year, month, day, hour, minute, second, millisecond } = dateTime;

	const momentInstance = window.moment.tz(
		{
			year,
			month: month - 1, // Adjust for zero-based months in moment
			day,
			hour,
			minute,
			second,
			millisecond
		},
		timezone
	);

	// Convert the moment instance to UNIX seconds
	return momentInstance.unix();
}

export function convertDateAndTimeUsingTimezone(date, timezone) {
	const { year, month, day, hour, minute } = date;
	const formattedMonth = String(month).padStart(2, "0");
	const formattedDay = String(day).padStart(2, "0");
	const formattedDate = window.moment
		.tz(`${year}-${formattedMonth}-${formattedDay}`, timezone)
		.set({
			hour,
			minute
		});
	return formattedDate;
}
export const setLocalUser = user => {
	localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(user));
};

export function removeLocalUser(removeAll) {
	if (getLocalUser()) {
		localStorage.removeItem(LOCALSTORAGE_KEY);
		removeAll && localStorage.removeItem(AVAILABLE_FEATURES);
		getCumulioConfig() && localStorage.removeItem(LOCALSTORAGE_CUMULIO_KEY);
	}
}

export const removeTags = (html = "") => {
	const tagBody = "(?:[^\"'>]|\"[^\"]*\"|'[^']*')*";
	const tagOrComment = new RegExp(
		"<(?:" +
			"!--(?:(?:-*[^->])*--+|-?)" +
			"|script\\b" +
			tagBody +
			">[\\s\\S]*?</script\\s*" +
			"|style\\b" +
			tagBody +
			">[\\s\\S]*?</style\\s*" +
			"|/?[a-z]" +
			tagBody +
			")>",
		"gi"
	);
	let oldHtml;
	do {
		oldHtml = html || "";
		html = html?.replace(tagOrComment, "");
	} while (html !== oldHtml);
	return html.replace(/</g, "&lt;");
};

export const createMarkup = content => {
	return {
		__html: marked(removeTags(content), {
			sanitize: true,
			smartLists: true,
			breaks: true
		})
	};
};

export const createMarkupHtml = content => {
	return {
		__html: content
	};
};

export const createMarkupPreview = content => {
	return {
		__html: marked(content, {
			sanitize: false,
			smartLists: true,
			breaks: true
		})
	};
};

export const removeMarkdown = content => {
	// smartypants: Use "smart" typograhic punctuation for things like quotes and dashes.
	return removeTags(marked(content, { smartypants: true }));
};

export function capitalize(value) {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

export const isBusinessDay = (day, weekDays = [1, 2, 3, 4, 5]) => {
	return weekDays.indexOf(day) >= 0;
};

export const getBusinessDays = (start, end) => {
	if (!start || !end) return 0;
	const startMoment = window.moment(start, DATE_FORMAT);
	const endMoment = window.moment(end, DATE_FORMAT);
	let done = false;
	let businessDaysCount = 0;
	const current = startMoment;
	while (!done) {
		if (isBusinessDay(current.day())) businessDaysCount++;
		if (current >= endMoment) done = true;
		current.add(1, "day");
	}
	return businessDaysCount;
};

const daysToWeeks = days => {
	return days / 5;
};

const daysToMonths = days => {
	return days / 22;
};

const weeksToMonths = weeks => {
	return weeks / 4;
};

const monthsToWeeks = months => {
	return months * 4;
};

const weeksToDays = weeks => {
	return weeks * 5;
};

const monthsToDays = months => {
	return months * 22;
};

const findCountryAddress = (addresses, country) => {
	return addresses.filter(location => {
		return location.formatted_address.includes(country);
	});
};

export const getDateDifference = (
	from = JOB_SELECT_DAYS,
	to = JOB_SELECT_WEEKS,
	value
) => {
	if (!from || !to) return;
	const methods = {
		daysToWeeks,
		daysToMonths,
		weeksToMonths,
		monthsToWeeks,
		weeksToDays,
		monthsToDays
	};
	from = typeof from === "object" ? from.value : from;
	to = typeof to === "object" ? to.value : to;
	const computedMethod = `${from}To${capitalize(to)}`;

	if (Object.prototype.hasOwnProperty.call(methods, computedMethod)) {
		return methods[computedMethod](value);
	}
};

export const midNightEndDate = date => {
	if (window.moment.isMoment(date)) {
		return date.endOf("day");
	}
	return `${date}T23:59:59`;
};

// format Date using given timezone
export const utcTimeStampUsingTimezone = ({
	date,
	isStart = true,
	manageTime = true,
	format = DATE_FORMAT,
	timezone
}) => {
	if (window.moment.isMoment(date)) {
		date = window.moment(date.clone()).tz(timezone);
	} else {
		date = window.moment(date, format).tz(timezone);
	}
	if (manageTime) {
		if (!isStart) date = midNightEndDate(date);
		else date.set({ hour: 0, minute: 0 });
	}
	return date.utc().unix();
};

// format Date using Europe/Brussels
export const utcTimeStamp = ({
	date,
	isStart = true,
	manageTime = true,
	format = DATE_FORMAT
}) => {
	if (window.moment.isMoment(date)) {
		date = window.moment(date.clone()).tz(TIMEZONE);
	} else {
		date = window.moment(date, format).tz(TIMEZONE);
	}
	if (manageTime) {
		if (!isStart) date = midNightEndDate(date);
		else date.set({ hour: 0, minute: 0 });
	}
	return date.utc().unix();
};

export const getTrueType = element => {
	return {}.toString
		.call(element)
		.match(/\s(\w+)/)[1]
		.toLowerCase();
};

export const getLabel = (array, status, key = "value") => {
	const element = array.find(element => element[key] === status);
	return element && _capitalize(element.label);
};

export const getStatusLabel = (status, key = "value", type) => {
	const statusArray = type ? VACANCY_STATUS : REQUEST_STATUS;
	return getLabel(statusArray, status, key);
};

export const getRolesLabel = (role, key = "name") => {
	return getLabel(ROLES, role, key);
};

export const prepareForSelect = (data, label = "name", value = "_id") =>
	data.map(item => ({
		label: item[label],
		value: item[value]
	}));

export function getLanguageTooltip(score) {
	switch (score) {
		case 1:
			return "Elementary Proficiency";
		case 2:
			return "Limited Working Proficiency";
		case 3:
			return "Professional Working Proficiency";
		case 4:
			return "Full Professional Proficiency";
		case 5:
			return "Bilingual Proficiency";
		default:
			return score;
	}
}

export function getSkillsTooltip(score) {
	switch (score) {
		case 1:
			return "Novice";
		case 2:
			return "Advanced Beginner";
		case 3:
			return "Competent";
		case 4:
			return "Proficient";
		case 5:
			return "Expert";
		default:
			return score;
	}
}

export const isValidAddress = (address, requiredFields = []) => {
	let valid = true;
	if (requiredFields.length === 0) {
		requiredFields = ["street", "number", "city", "zip", "country"];
	}
	requiredFields.forEach(field => {
		if (!address[field]) {
			valid = false;
		}
	});
	return valid;
};

export const isEmptyAddress = address => {
	let isEmpty = true;
	Object.keys(address).forEach(key => {
		if (address[key] && isEmpty) isEmpty = false;
	});
	return isEmpty;
};

export const getAddressOnly = (data = {}) => {
	return {
		street: data.street,
		country: data.country,
		zip: data.zip,
		city: data.city,
		number: data.number,
		box: data.box,
		latitude: Number(data.latitude),
		longitude: Number(data.longitude),
		iso_country: data.iso_country,
		geometry: data.geometry
	};
};

export const getHours = (time, options = { symbol: "h", spaces: true }) => {
	const representation = String(time || 0).split(".");
	const hours = _get(representation, "0", "0").padStart(2, 0);
	const m = Number(`0.${_get(representation, "1", "0")}`) * 60;
	const minutes = m
		.toFixed(0)
		.padStart(2, 0)
		.padEnd(2, 0);

	if (options.spaces) {
		return `${hours} ${options.symbol} ${minutes}`;
	}
	return `${hours}${options.symbol}${minutes}`;
};

//Sort array of objects based on another array.
export const mapOrder = (array, order, key) => {
	let sortedOrder = [...order].reverse();
	return array.sort((a, b) => {
		return sortedOrder.indexOf(a[key]) > sortedOrder.indexOf(b[key]) ? -1 : 1;
	});
};

export const bytesToSize = bytes => {
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	if (bytes === 0) return "0 Byte";
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const getMentions = text => {
	return text.match(/\?##([\w\s|(\w*)À-ÖØ-öø-ÿ]+),([\w\S]+)##\?/gi);
};

export const handleAddressObject = async (address, newAddress) => {
	const street = address?.street || address?.company_street,
		number = address?.number || address?.company_number,
		zip = address?.zip || address?.company_zip,
		city = address?.city || address?.company_city,
		country = address?.country || address?.company_country;

	if ((!address?.latitude && !address?.longitude) || newAddress) {
		let addressString = "";

		if (street) {
			addressString += `${street} `;
		}
		if (number) {
			addressString += `${number} `;
		}
		if (zip) {
			addressString += `${zip} `;
		}
		if (city) {
			addressString += `${city} `;
		}
		if (country) {
			addressString += `${country}`;
		}

		const resp = await axios(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${GOOGLE_API_KEY}`
		);

		const results = _get(resp, "data.results", []);

		let result = findCountryAddress(results, country);

		result = result[0] ? result[0] : results[0];

		if (!result) return;

		let isSendViewport = false;

		const typesSupportedAutoComplete = [
			"locality",
			"sublocality",
			"postal_code",
			"country",
			"administrative_area_level_1",
			"administrative_area_level_2",
			"administrative_area_level_3"
		];

		typesSupportedAutoComplete.forEach(type => {
			if (result.types.includes(type)) isSendViewport = true;
		});

		let iso_country;

		_get(result, "address_components", []).forEach(item => {
			if (item.types.includes("country")) {
				iso_country = _get(item, "short_name");
			}
		});

		if (
			_get(result, `geometry.location.lat`) &&
			_get(result, `geometry.location.lng`)
		) {
			return {
				addressTypo: false,
				error: false,
				address: {
					...address,
					iso_country,
					latitude: _get(result, `geometry.location.lat`),
					longitude: _get(result, `geometry.location.lng`),
					viewport: isSendViewport ? _get(result, `geometry.viewport`) : []
				}
			};
		} else {
			return {
				addressTypo: true
			};
		}
	}
	return {
		addressTypo: false,
		error: false,
		address
	};
};

export function searchParser() {
	return QS.parse(window.location.search);
}

export function searchStringify(value = "") {
	return QS.stringify(value);
}

export async function getUserCountryData() {
	try {
		const resp = await axios(
			`https://api.ipdata.co/?api-key=${IP_DATA_API_KEY}`
		);

		return {
			countryCode: _get(resp, "data.country_code"),
			countryNumber: _get(resp, "data.calling_code"),
			countryCity: _get(resp, "data.city")
		};
	} catch (error) {
		return { error };
	}
}

export function generateListBacks({
	backs = [],
	defaultBack,
	isConsultancy = false
}) {
	const search = searchParser();
	const isFromList = search?.source === "list";
	return isConsultancy
		? search.from
		: isFromList
		? backs[search?.list]
		: defaultBack;
}

export const generateHistoryPath = () => {
	const search = searchParser();
	search.history = `${window.location.pathname}${window.location.search}`;
	return searchStringify({ history: search.history });
};

export const goBack = () => {
	const search = searchParser();
	const hashLink = search?.hash ? "#" + search.hash : "";
	search.history
		? browserHistory.push(search.history + hashLink)
		: browserHistory.goBack();
};
export const historyPush = (path, isNewTabe) => {
	const isContainsSearchParams = path.includes("?");
	if (!isNewTabe) {
		browserHistory.push(
			`${path}${isContainsSearchParams ? "&" : "?"}${generateHistoryPath()}`
		);
	} else {
		window.open(
			`${path}${isContainsSearchParams ? "&" : "?"}${generateHistoryPath()}`,
			"_blank"
		);
	}
};

export const historyReplace = path => {
	browserHistory.replace(path);
};

export const socialWindow = url => {
	var left = (window.screen.width - 570) / 2;
	var top = (window.screen.height - 570) / 2;
	var params =
		"menubar=no,toolbar=no,status=no,width=570,height=570,top=" +
		top +
		",left=" +
		left;
	window.open(url, "NewWindow", params);
};

export const s3Url = response => {
	return `${response.action}/${response.key}`;
};

export const uploadToS3 = ({ response, file, onProgress = () => {} }) => {
	const formData = new FormData();
	const payload = pick(response, [
		"acl",
		"key",
		"X-Amz-Credential",
		"X-Amz-Date",
		"X-Amz-Algorithm",
		"Policy",
		"X-Amz-Signature"
	]);
	Object.keys(payload).forEach(key => formData.append(key, response[key]));
	formData.append("file", file);
	return new Promise(resolve => {
		axios
			.post(response.action, formData, {
				headers: {
					"Content-Type": file.type
				},
				onUploadProgress: ev => {
					onProgress((ev.loaded / ev.total) * 100);
				}
			})
			.then(() => {
				resolve({
					url: s3Url(response)
				});
			});
	});
};

export const formatDate = (timestamp, time = false) => {
	const hourTime = new Intl.DateTimeFormat("en-BE", {
		hour: "2-digit",
		minute: "2-digit"
	}).format(new Date(timestamp * 1000));

	const dateTime = new Intl.DateTimeFormat("en-BE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}).format(new Date(timestamp * 1000));

	return dateTime + (time ? ` ${hourTime}` : "");
};

//TODO I think it's better to create a helper for routing
export const addQueryParamToPath = (routeSearch, param, value) => {
	const params = searchParser();
	const counter = Object.keys(params).length;

	let newRouteSearch;
	const segment = `${param}=${value}`;

	if (counter === 0) {
		newRouteSearch = `?${segment}`;
	} else if (param in params) {
		const current = params[param];

		newRouteSearch = routeSearch.replace(`${param}=${current}`, segment);
	} else {
		newRouteSearch = `${routeSearch}&${segment}`;
	}

	return newRouteSearch;
};

export const removeURLParameter = (url, parameters) => {
	//prefer to use l.search if you have a location/link object
	var urlparts = url.split("?");
	if (urlparts.length >= 2) {
		var pars = urlparts[1].split(/[&;]/g);

		//reverse iteration as may be destructive
		for (var i = pars.length; i-- > 0; ) {
			//idiom for string.startsWith
			parameters.forEach(parameterItem => {
				var prefix = encodeURIComponent(parameterItem) + "=";

				if (pars[i].lastIndexOf(prefix, 0) !== -1) {
					pars.splice(i, 1);
				}
			});
		}

		return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
	}
	return url;
};
export const convertHTMLtoText = html => {
	return html.replace(/<[^>]*>/g, "");
};

export const getFullName = obj => {
	if (obj) {
		return `${obj.first_name} ${obj.last_name}`;
	}
	return "";
};

export const isElementInViewport = el => {
	var top = el.offsetTop;
	var left = el.offsetLeft;
	var width = el.offsetWidth;
	var height = el.offsetHeight;

	while (el.offsetParent) {
		el = el.offsetParent;
		top += el.offsetTop;
		left += el.offsetLeft;
	}

	return (
		top >= window.pageYOffset &&
		left >= window.pageXOffset &&
		top + height <= window.pageYOffset + window.innerHeight &&
		left + width <= window.pageXOffset + window.innerWidth
	);
};

export const removeCountryCodeFromNumber = number => {
	return number.split(" ")[1];
};

export const parseMentions = value => {
	return value.replace(
		/\?##([\w\s|(\w*)À-ÖØ-öø-ÿ]+),([\w\S]+)##\?/gi,
		"<span user-id=$2 data-lexical-mention='true'>@$1</span>"
	);
};

export const parsePlaceholders = value => {
	return value.replace(
		/(?:\*\|([a-zA-Z0-9_]*)\|\*)/gi,
		"<span data-lexical-email-placeholder='$1'>*|$1|*</span>"
	);
};

export const isHTML = text => {
	return /<\/?[a-z][\s\S]*>/i.test(text);
};

//This parses old notes created by an old text editor
export function formatNote(description) {
	let result;
	if (isHTML(description)) result = description;
	else result = parseMentions(description);
	return result;
}

export const parseOldMentions = value => {
	return value.replace(/\?##([\w\s|(\w*)À-ÖØ-öø-ÿ]+),([\w\S]+)##\?/gi, "@$1");
};

export const parseNewMentions = value => {
	const regex = /data-lexical-mention="true">/gi;
	return value.replaceAll(regex, "data-lexical-mention='true'>@");
};

export const convertNoteDescriptionToText = note => {
	if (isHTML(note)) {
		const text = parseNewMentions(note);
		return convertHTMLtoText(text);
	} else {
		return parseOldMentions(note);
	}
};

export const createArrayWithIncrementalValues = n => {
	return Array.from(Array(n).keys());
};

export const generateId = () => {
	return uuid();
};

export const getUrlHash = () => {
	const url = new URL(window.location);

	return url.hash.replace("#", "");
};

export const convertCalendarDateToMS = (date, hours, minutes) => {
	const { year, month, day } = date;
	var dateObject =
		typeof hours === "number" && typeof minutes === "number"
			? new Date(year, month - 1, day, hours, minutes)
			: new Date(`${year}/${month}/${day}`);

	return dateObject;
};

export const isStartTimeSmallThanEndTime = ({
	startTime,
	endTime,
	date,
	intervalInSeconds,
	timezone
}) => {
	const startDate = window.moment
		.tz(
			{
				year: date.year,
				month: date.month - 1, // Adjust for zero-based months in moment
				day: date.day,
				hour: startTime.hour,
				minute: startTime.minute
			},
			timezone
		)
		.unix();
	const endDate = window.moment
		.tz(
			{
				year: date.year,
				month: date.month - 1, // Adjust for zero-based months in moment
				day: date.day,
				hour: endTime.hour,
				minute: endTime.minute
			},
			timezone
		)
		.unix();

	return endDate - startDate >= intervalInSeconds;
};

export const isTimeInFuture = ({ time, date, timezone }) => {
	const now = window.moment.tz(timezone).unix();
	const startDate = window.moment
		.tz(
			{
				year: date.year,
				month: date.month - 1, // Adjust for zero-based months in moment
				day: date.day,
				hour: time.hour,
				minute: time.minute
			},
			timezone
		)
		.unix();

	return startDate > now;
};

export const formatDateToCalendarObject = (timestamp, isBrusselsTz) => {
	const date = isBrusselsTz
		? window.moment.unix(timestamp)
		: moment.unix(timestamp);
	return new CalendarDate(
		date.get("year"),
		date.get("month") + 1,
		date.get("date")
	);
};

export const convertCalendarDateToTimestamp = date => {
	const { year, month, day } = date;
	var dateObject = new Date(`${year}/${month}/${day}`);

	return dateObject;
};

export const timestampToDateCalendar = (timestamp, timezone = null) => {
	let date = moment.unix(timestamp);
	if (timezone) date = window.moment.unix(timestamp).tz(timezone);

	return new CalendarDate(
		date.get("year"),
		date.get("month") + 1,
		date.get("date")
	);
};

export const convertCalendarObjectToTimestampUsingTimezone = (
	calendarObject,
	timezone
) => {
	const { year, month, day } = calendarObject;
	const formattedMonth = String(month).padStart(2, "0");
	const formattedDay = String(day).padStart(2, "0");
	if (timezone)
		return window.moment
			.tz(`${year}-${formattedMonth}-${formattedDay}`, timezone)
			.unix();
	return window.moment.utc(`${year}-${formattedMonth}-${formattedDay}`).unix();
};

export function getTimezoneOffset(timezone) {
	return DateTime.now().setZone(timezone).offset;
}

export const removeClassesFromString = value => {
	return value.replace(/class="[^"]*"/g, "");
};

export const isOverflow = element => {
	return element.offsetWidth < element.scrollWidth;
};

export const getDomainWithoutSubdomain = url => {
	return new URL(url).host
		.split(".")
		.reverse()
		.slice(0, 2)
		.reverse()
		.join(".");
};

export const getRandomNumber = (min, max) => {
	return Math.random() * (max - min) + min;
};

export const getHTMLTextLength = html => {
	const parser = new DOMParser();
	const dom = parser.parseFromString(html, "text/html");

	return dom.body.textContent.length;
};

export const addHashToUrl = id => {
	history.pushState(null, null, `#${id}`);
};

export const generateEmptyArray = n => Array(n).fill({});

export const timestampToString = time => {
	return moment(time * 1000).format("DD/MM/YYYY");
};

export const dateToTimestamp = d => {
	return moment(d, "DD-MM-YYYY").unix();
};

export const extractVideoIdYoutube = videoUrl => {
	const videoInfo = embed.info(videoUrl);
	return videoInfo.id;
};

export const getParamFromUrl = key => {
	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get(key);
};

export const dateObjectToString = value => {
	var mm = value.getMonth() + 1;
	var dd = value.getDate();

	return [
		value.getFullYear(),
		(mm > 9 ? "" : "0") + mm,
		(dd > 9 ? "" : "0") + dd
	].join("-");
};

export const convertTStoString = (date, format = DATE_FORMAT) => {
	return moment.unix(date).format(format);
};

export const formatTimesheetPeriod = (frequency, value) => {
	if (frequency === TIMESHEET_FREQUENCY_MONTHLY && value) {
		return moment(value, "MM-YYYY").format("MMMM YYYY");
	}
	if (frequency === TIMESHEET_FREQUENCY_WEEKLY && value) {
		return `Week ${moment(value, "WW-YYYY").format("WW, YYYY")}`;
	}
	return "--";
};

export const getHourFromTS = date => {
	return moment.unix(date).format(TIME_FORMAT_24);
};

export const getFileIcon = file => {
	const fileType = getFileExtension(file);
	switch (fileType) {
		case JPG:
		case JPEG:
		case PNG:
			return ImageIcon;
		case DOCX:
		case DOC:
			return DocIcon;
		default:
			return PdfIcon;
	}
};

export const getFileName = url => {
	return url?.split("/").pop();
};

export const formatPhone = (code, number) => {
	return `${code?.toUpperCase()} ${number}`;
};
export const renderError = error => {
	let errorMessage = _get(error, "detail", "Something went wrong! Try later");
	if (error.status === 500) {
		toaster.danger("Something went wrong! Try later");
		return;
	}
	if (typeof errorMessage === "object") {
		Object.keys(errorMessage).forEach(name => {
			toaster.danger(
				`${name} : ${
					typeof _get(errorMessage, name) === "string"
						? _get(errorMessage, name)
						: _get(errorMessage, name)[0]
				}`
			);
		});
	} else {
		toaster.danger(errorMessage);
	}
};

export const downloadFileFromUrl = (url, filename) => {
	const link = document.createElement("a");
	link.href = url;
	link.download = filename;
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
};
export const removeExternalAccessToken = () =>
	localStorage.removeItem(EXTERNAL_ACCESS_TOKEN);

export const getExternalAccessToken = () =>
	localStorage.getItem(EXTERNAL_ACCESS_TOKEN);

export const redirectToNotFound = () => {
	return browserHistory.replace("/404");
};

export const convertMinsToHrsMins = minutes => {
	var h = Math.floor(minutes / 60);
	var m = minutes % 60;
	h = h < 10 ? "0" + h : h;
	m = m < 10 ? "0" + m : m;
	return h + ":" + m;
};

export const shouldDisplayFreelancerCost = data => {
	if (!data) return false;
	const { vms_settings, reporting_settings } = data;
	return (
		(vms_settings?.fee_type === "margin" && vms_settings?.margin !== 0) ||
		(vms_settings?.fee_type === "fixed_fee" &&
			((vms_settings?.daily_amount !== 0 &&
				reporting_settings?.rate_type === DAILY) ||
				(vms_settings?.hourly_amount !== 0 &&
					reporting_settings?.rate_type === HOURLY)))
	);
};

export const shouldDisplayFee = ({
	feeType,
	margin,
	dailyAmount,
	hourlyAmount,
	rateType
}) => {
	if (feeType === "margin") {
		return margin > 0;
	} else if (feeType === "fixed_fee" && rateType === DAILY) {
		return dailyAmount > 0;
	} else if (feeType === "fixed_fee" && rateType === HOURLY) {
		return hourlyAmount > 0;
	}
	return false;
};

export const setCookie = (name, value) => {
	const date = new Date();
	date.setFullYear(date.getFullYear() + 100);
	const expires = "; expires=" + date.toUTCString();
	const domain = `; domain=${
		process.env.NODE_ENV === "development"
			? "localhost"
			: getDomainWithoutSubdomain(window.location)
	}`;
	document.cookie = name + "=" + (value || "") + expires + domain + "; path=/";
};

export const deleteCookie = name => {
	if (getCookie(name)) {
		const domain = `; domain=${
			process.env.NODE_ENV === "development"
				? "localhost"
				: getDomainWithoutSubdomain(window.location)
		}`;

		document.cookie =
			name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC${domain}; path=/;`;
	}
};

export const getCookie = name => {
	const nameEQ = name + "=";
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i].trim(); // Remove leading and trailing spaces
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
};

export const getDateFromURL = url => {
	return url.split("_")[1].split(".")[0];
};

export const sortCalendarDates = (a, b) => {
	return (
		new Date(`${a.year}/${a.month}/${a.day}`).getTime() -
		new Date(`${b.year}/${b.month}/${b.day}`).getTime()
	);
};

export const timeStampToCalendarTime = (stamp, timezone) => {
	const date = momentTimezone.unix(stamp).tz(timezone);
	return new Time(date.hour(), date.minute());
};

export const timeToUTC = (time, timezone) => {
	const { hour, minute } = time;
	const currentDate = momentTimezone
		.tz(timezone)
		.startOf("day")
		.hour(hour)
		.minute(minute);
	return currentDate.utc().unix();
};
