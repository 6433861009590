import React from "react";
import styles from "./header.module.scss";
import LoadingSpinner from "common/LoadingSpinner/LoadingSpinner";
import cx from "classnames";

const Header = ({ onDiscard, isSaving, allowSave, disableActions }) => {
	return (
		<div className={styles.container}>
			<button
				type="button"
				className={styles.btn}
				onClick={onDiscard}
				disabled={disableActions}
			>
				Discard
			</button>
			<button
				type="submit"
				className={cx(styles.btn, styles.save)}
				disabled={isSaving || !allowSave || disableActions}
			>
				{isSaving ? <LoadingSpinner /> : "Save"}
			</button>
		</div>
	);
};

export default Header;
