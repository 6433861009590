import React from "react";
import get from "lodash/get";
import {
	INTERVIEW,
	TIME_FORMAT_24,
	APPLICATION,
	SHORTLIST,
	OFFER,
	HIRED,
	PHONE_SCREEN,
	CANCELLED,
	REJECTED,
	DATA_MMMM_D_YYYY,
	PHONE_SCREENING
} from "config";
import { formatDate, formatDateUsingTimezone } from "common/Functions";

import AttendeesUsers from "../AttendeesUsers";
import styles from "./accordion.module.scss";
import { Flex } from "rebass";
import { browserHistory } from "react-router";
import { useGetUser } from "hooks/useGetUser";

const Body = ({ body }) => {
	const user = useGetUser();
	const type = get(body, "type");

	switch (type) {
		case HIRED: {
			const message = get(body, "message");
			const lastStatusChangedOn = get(body, "lastStatusChangedOn");

			return (
				<div className={styles.body}>
					<div className={styles.information}>
						<div className={styles.box}>
							<p className={styles.status}>
								{message}{" "}
								{lastStatusChangedOn
									? formatDate(lastStatusChangedOn)
									: "--/--/----"}
							</p>
						</div>
					</div>
				</div>
			);
		}
		case REJECTED: {
			const date = get(body, "date");
			const reasonOfReject = get(body, "reasonOfReject");

			return (
				<div className={styles.body}>
					<div className={styles.information}>
						<div className={styles.box}>
							<span className={styles.text}>Date</span>
							<p className={styles.status}>
								{formatDate(date, true, DATA_MMMM_D_YYYY)}
							</p>
						</div>
						{reasonOfReject && (
							<div className={styles.box}>
								<span className={styles.text}>Reason of rejection</span>
								<p className={styles.status}>{reasonOfReject}</p>
							</div>
						)}
					</div>
				</div>
			);
		}
		case CANCELLED: {
			const date = get(body, "date");
			const message = get(body, "message");
			const user = get(body, "user");

			return (
				<div className={styles.body}>
					<div className={styles.information}>
						<div className={styles.box}>
							<p className={styles.status}>
								{message}
								<span>{user} </span> {date ? formatDate(date) : "--/--/----"}
							</p>
						</div>
					</div>
				</div>
			);
		}
		case OFFER: {
			const amount = get(body, "amount");
			const symbol = get(body, "symbol");
			const paymentType = get(body, "paymentType");
			const paymentTime = get(body, "paymentTime");
			const startDate = get(body, "startDate");
			const otherBenefits = get(body, "otherBenefits");

			return (
				<div className={styles.body}>
					<div className={styles.information}>
						<div className={styles.box}>
							<span className={styles.text}>Salary</span>
							<div className={styles.description}>
								<p className={styles.textGras}>
									{amount} {symbol}
								</p>
								<p>{paymentType}</p>
								<p>{paymentTime}</p>
							</div>
						</div>
						<div className={styles.box}>
							<span className={styles.text}>Date of start</span>
							<p className={styles.status}>{formatDate(startDate)}</p>
						</div>
					</div>
					{otherBenefits && (
						<div className={styles.box}>
							<span className={styles.text}>Benefits</span>
							<div className={styles.flex}>
								<p className={styles.status}> {otherBenefits}</p>
							</div>
						</div>
					)}
				</div>
			);
		}

		case INTERVIEW: {
			const startDate = get(body, "startDate");
			const endDate = get(body, "endDate");
			const attendeesUsers = get(body, "attendeesUsers");
			const locationLabel = get(body, "locationLabel");
			const location = get(body, "location");
			const description = get(body, "description");

			return (
				<div className={styles.body}>
					<div className={styles.information}>
						<div className={styles.box}>
							<span className={styles.text}>Time</span>
							<p className={styles.status}>
								{formatDateUsingTimezone(
									startDate,
									true,
									DATA_MMMM_D_YYYY,
									false,
									user.timezone
								)}{" "}
								|{" "}
								{formatDateUsingTimezone(
									startDate,
									true,
									TIME_FORMAT_24,
									false,
									user.timezone
								)}{" "}
								-{" "}
								{formatDateUsingTimezone(
									endDate,
									true,
									TIME_FORMAT_24,
									false,
									user.timezone
								)}
							</p>
						</div>
						<div className={styles.box}>
							<span className={styles.text}>{locationLabel}</span>
							<p className={styles.status}>{location}</p>
						</div>
						{attendeesUsers.length > 0 && (
							<div className={styles.box}>
								<span className={styles.text}>Attendees</span>
								<div className={styles.flex}>
									<AttendeesUsers attendeesUsers={attendeesUsers} />
								</div>
							</div>
						)}
					</div>
					{description && (
						<div className={styles.box}>
							<span className={styles.text}>Message</span>
							<p className={styles.status}>{description}</p>
						</div>
					)}
				</div>
			);
		}
		case PHONE_SCREENING:
		case PHONE_SCREEN: {
			const startDate = get(body, "startDate");
			const endDate = get(body, "endDate");
			const description = get(body, "description");

			return (
				<div className={styles.body}>
					<div className={styles.information}>
						<div className={styles.box}>
							<span className={styles.text}>Date and Time</span>
							<p className={styles.status}>
								{formatDate(startDate, true, DATA_MMMM_D_YYYY)} |{" "}
								{formatDate(startDate, true, TIME_FORMAT_24)} -{" "}
								{formatDate(endDate, true, TIME_FORMAT_24)}
							</p>
						</div>
						{description && (
							<div className={styles.box}>
								<span className={styles.text}>Message</span>
								<p className={styles.status}>{description}</p>
							</div>
						)}
					</div>
				</div>
			);
		}
		case SHORTLIST: {
			const message = get(body, "message");
			const lastUserAction = get(body, "lastUserAction");
			const statusChangedOn = get(body, "statusChangedOn");

			return (
				<div className={styles.body}>
					<div className={styles.information}>
						<div className={styles.box}>
							<p className={styles.status}>{message}</p>
						</div>
						<div className={styles.box}>
							<p className={styles.status}>By {lastUserAction}</p>
						</div>
						{statusChangedOn && (
							<div className={styles.box}>
								<p className={styles.status}>{formatDate(statusChangedOn)}</p>
							</div>
						)}
					</div>
				</div>
			);
		}
		case APPLICATION: {
			const message = get(body, "message");
			const sourceProfile = get(body, "sourceProfile");

			const onClickReferredBy = () => {
				if (body.referredBy._id) {
					browserHistory.push(`/permanent/view/${body.referredBy._id}`);
				}
			};

			return (
				<div className={styles.body}>
					{body.referredBy ? (
						<div className={styles.referralInfos}>
							<Flex style={{ gap: "0 16px" }} flexWrap="wrap">
								<div className={styles.info}>
									<span className={styles.label}>Referred by </span>
									<span className={styles.value} onClick={onClickReferredBy}>
										<span className={body.referredBy._id ? styles.isLink : ""}>
											{body.referredBy.first_name} {body.referredBy.last_name}
										</span>
									</span>
								</div>
								<div className={styles.info}>
									<span className={styles.label}>On </span>
									<span className={styles.value}>
										{formatDate(body.createdAt)}
									</span>
								</div>
								<div className={styles.info}>
									<span className={styles.label}>From </span>
									<span className={styles.value}>{body.source}</span>
								</div>
							</Flex>
						</div>
					) : (
						<div className={styles.information}>
							<div className={styles.box}>
								<p className={styles.status}>{message}</p>
							</div>
							<div className={styles.info}>
								<span className={styles.label}>From </span>
								<span className={styles.value}>{sourceProfile}</span>
							</div>
						</div>
					)}
				</div>
			);
		}
		default:
			return null;
	}
};

export default Body;
