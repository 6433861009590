import {
	getCalendarSettingsRoute,
	getCollaborativeGroupsRoute
} from "../getRouteConfig";

export const routes = [
	{
		url: getCalendarSettingsRoute(),
		label: "General Settings"
	},
	{
		url: getCollaborativeGroupsRoute(),
		label: "Collaborative Groups"
	}
];

export const BREADCRUMBS_ITEMS = [
	{ name: "Settings" },
	{ name: "Calendar Settings" }
];
export const BREADCRUMBS_ITEMS_COLLABORATIVE_GROUPS = [
	{ name: "Settings" },
	{ name: "Collaborative Groups" }
];

export const REMINDER_INTERVALS = {
	15: {
		value: "15",
		label: "15 min"
	},
	20: {
		value: "20",
		label: "20 min"
	},
	60: {
		value: "60",
		label: "1 h"
	},
	120: {
		value: "120",
		label: "2 h"
	},
	720: {
		value: "720",
		label: "12 h"
	}
};

export const STATUS_PENDING = "pending";
export const invitationOptions = {
	join: "join",
	decline: "decline"
};

export const ADMIN_ROLE = "admin";
export const DISPLAY_GROUP_TITLE_MAX = 35;
export const INPUT_GROUP_TITLE_MAX = 150;
export const DAYS_OF_WEEK = [
	{
		name: "sunday",
		shortname: "s"
	},
	{
		name: "monday",
		shortname: "m"
	},
	{
		name: "tuesday",
		shortname: "t"
	},
	{
		name: "wednesday",
		shortname: "w"
	},
	{
		name: "thursday",
		shortname: "t"
	},
	{
		name: "friday",
		shortname: "f"
	},
	{
		name: "saturday",
		shortname: "s"
	}
];
