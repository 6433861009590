import React from "react";
import styles from "./loaoding-state.module.scss";
import Skeleton from "react-loading-skeleton";

const LoadingState = () => {
	return (
		<div className={styles.content}>
			<Skeleton height={21} width={554} className={styles.title} />
			<Skeleton height={13} width={480} className={styles.desc1} />
			<Skeleton height={13} width={371} className={styles.desc2} />
			<Skeleton height={13} width={292} className={styles.desc3} />
			{[...Array(5)].map((_, i) => (
				<div className={styles.member_item} key={i}>
					<div className={styles.flex}>
						<Skeleton height={32} width={32} className={styles.avatar} />
						<div className={styles.info}>
							<Skeleton height={4} width={109} />
							<Skeleton height={2} width={109} />
						</div>
					</div>
					<Skeleton height={32} width={163} />
				</div>
			))}

			<div className={styles.policy}>
				<Skeleton height={22} width={22} />
				<div className={styles.text}>
					<Skeleton height={13} width={460} />
					<Skeleton height={13} width={371} />
					<Skeleton height={13} width={292} />
				</div>
			</div>

			<div className={styles.actions}>
				<Skeleton height={4} width={84} />
				<Skeleton height={40} width={163} />
			</div>
		</div>
	);
};

export default LoadingState;
