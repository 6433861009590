import React, { useState } from "react";
import { Text } from "rebass";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
import { stringify } from "query-string";
import Flag from "static/icons/flag.svg";
import FlagGrey from "static/icons/flag-grey.svg";
import { PokeMessage, VideoBlock, TitleBlock } from "./styled";
import { ShortlistDivider } from "modules/vacancy/components/pokeView/Styled";
import StatusLabel from "common/StatusLabel";
import { toggleHelpModal } from "../../../app/actions/ListsActions";
import InterviewDetailsModal from "./InterviewDetailsModal";
import InterviewsHistory from "./InterviewsHistory";
import Description from "common/Description";
import {
	CONFIRMED,
	CANCELLED,
	DONE,
	VIDEO_CONFERENCE,
	ACCEPTED,
	APPLIED,
	ONE_HUNDRED_AND_FIFTY,
	REJECTED,
	LOCATION_TYPE_PHONE_SCREEN,
	PERMANENT,
	HIRED
} from "config";
import { OpenVirtualRoomButton } from "common/OpenVirtualRoomButton";
import { formatDate } from "common/Functions";
import InterviewDetail from "./InterviewDetail";
import OfferDetails from "modules/vacancy/components/pokeView/client/OfferDetails";
import { historyPush } from "config/helpers";
import { getRoute as getPermanentVirtualRoomRoute } from "modules/WherebyPermanentVirtualRoom";
import { setInterviewTime } from "modules/candidate/redux/actions";
import { useGetUser } from "hooks/useGetUser";

const PokeDetails = ({
	pokeDate,
	clientName,
	companyName,
	title,
	status,
	visible_status,
	status_changed_on,
	cancelData,
	hasInterview,
	interview,
	stepperStatus,
	interviewStatus,
	interviews,
	user,
	userSenderAvatar,
	cancelInterviewAt,
	applied_at,
	cancelApplication_data,
	cancel_comments,
	offer,
	poke_type,
	setInterviewTime,
	interviewTimeSelected
}) => {
	const connectedUser = useGetUser();
	const {
		new_interview_start_date,
		new_interview_end_date,
		city,
		country,
		location_type,
		last_user_interview_action,
		street,
		zip,
		description,
		availability_slots
	} = interview || {};
	const { type } = last_user_interview_action || {};
	const canDisplayInterview =
		interview && ![CANCELLED, DONE].includes(interview.status);
	const isVideoConf = location_type === VIDEO_CONFERENCE;
	const [
		displayInterviewDetailsModal,
		setDisplayInterviewDetailsModal
	] = useState(false);
	const [selectedInterview, setSelectedInterview] = useState({});
	const hasMultipleInterviews = interviews && interviews.length > 0;

	const getDetailsText = () => {
		switch (status) {
			case HIRED:
				return (
					<>
						The client has hired you on <strong>{status_changed_on}</strong>.{" "}
						<br />
						CONGRATULATIONS!
					</>
				);

			case CANCELLED:
				return (
					<>
						<ShortlistDivider />
						Application canceled by{" "}
						<strong>
							{`${_get(cancelData, "cancelled_by.first_name")} ${_get(
								cancelData,
								"cancelled_by.last_name"
							)}`}{" "}
						</strong>{" "}
						on{" "}
						<strong>{`${formatDate(_get(cancelData, "cancelled_at"))}`}</strong>
						<br />
						{_get(cancelData, "cancel_reason") && (
							<>
								Feedback provided:
								<br />
								{_get(cancelData, "cancel_reason")}
							</>
						)}
					</>
				);
			default:
				return "";
		}
	};

	const renderAdressCompany = (city, country, street, zip) => {
		return (
			<div className="adress-grid">
				<span>{street}</span>
				<span>{`${zip} ${city}`}</span>
				<span>{country}</span>
			</div>
		);
	};

	const handleOpenRoomClick = () => {
		const stringified = stringify({
			roomUrl: interview.room_url,
			displayName: `${user.first_name} ${user.last_name}`
		});

		historyPush(`${getPermanentVirtualRoomRoute()}?${stringified}`, true);
	};

	return (
		<div>
			{Boolean(Object.keys(offer).length) && (
				<OfferDetails offer={offer} type={PERMANENT} />
			)}
			{hasInterview &&
				[ACCEPTED, DONE, CONFIRMED].includes(interview.status) &&
				isVideoConf &&
				interview.room_url && (
					<VideoBlock>
						<OpenVirtualRoomButton onClick={handleOpenRoomClick} />
					</VideoBlock>
				)}
			{hasInterview && !Array.isArray(interview) && canDisplayInterview && (
				<div className="section">
					<TitleBlock>
						{_get(interview, "location_type") !== LOCATION_TYPE_PHONE_SCREEN
							? "Interview "
							: "Phone screen"}
					</TitleBlock>
					<StatusLabel
						position="absolute"
						right={30}
						top={interview.status === ACCEPTED && isVideoConf ? null : 25}
						status={_get(interview, "status")}
						label={interviewStatus}
						px={15}
						isInterview={true}
						fromDetails={true}
					/>
					<InterviewDetail
						invitationStartTime={new_interview_start_date}
						invitationEndTime={new_interview_end_date}
						locationType={location_type}
						locationInfo={
							city
								? renderAdressCompany(city, country, street, zip)
								: "Virtual meeting room"
						}
						clientName={clientName}
						lastUserAction={type}
						descInterview={description}
						attendeesUsers={_get(interview, "attendees_users")}
						status={_get(interview, "status")}
						userSenderAvatar={userSenderAvatar}
						availabilitySlots={availability_slots}
						setInterviewTime={setInterviewTime}
						interviewTimeSelected={interviewTimeSelected}
					/>
				</div>
			)}
			{hasMultipleInterviews && (
				<InterviewsHistory
					flag={"Interview"}
					visible_status={interviewStatus}
					status={_get(interview, "status")}
					displayInterviewDetailsModal={() =>
						setDisplayInterviewDetailsModal(true)
					}
					selectInterview={setSelectedInterview}
					interviews={interviews}
					timezone={connectedUser.timezone}
				/>
			)}
			{poke_type === "application" && status === APPLIED ? (
				<div className="section appliedBlock">
					<span className="icon icon-applied">
						<span className="path1"></span>
						<span className="path2"></span>
						<span className="path3"></span>
						<span className="path4"></span>
						<span className="path5"></span>
						<span className="path6"></span>
						<span className="path7"></span>
						<span className="path8"></span>
						<span className="path9"></span>
					</span>
					<p>
						You have applied on <strong>{formatDate(applied_at)}</strong>. Your
						application has been submitted to the client, you will be informed
						of any status change.
					</p>
					<div className="flagwrapper">
						<StatusLabel
							position="absolute"
							right={0}
							status={status}
							label={visible_status}
							px={15}
							fromDetails={true}
						/>
					</div>
				</div>
			) : poke_type === "application" &&
			  (status === CANCELLED || status === REJECTED) ? (
				<div className="section appliedBlock cancelled">
					<span className="icon icon-apply-canceled">
						<span className="path1"></span>
						<span className="path2"></span>
						<span className="path3"></span>
						<span className="path4"></span>
						<span className="path5"></span>
						<span className="path6"></span>
						<span className="path7"></span>
						<span className="path8"></span>
						<span className="path9"></span>
					</span>
					<div className="infoCancel">
						<p>
							You have applied on <strong>{formatDate(applied_at)}</strong>.
						</p>
						<p>
							{status === REJECTED
								? "Application rejected by"
								: "Application cancelled by"}{" "}
							{_get(cancelApplication_data, "cancelled_by.first_name", "")}{" "}
							{_get(cancelApplication_data, "cancelled_by.last_name", "")} on{" "}
							<strong>
								{formatDate(_get(cancelApplication_data, "cancelled_at", ""))}
							</strong>
						</p>

						{Boolean(cancel_comments) && (
							<>
								<h3>Feedback provided:</h3>
								<Description
									description={cancel_comments}
									max={ONE_HUNDRED_AND_FIFTY}
									parseMarkdown={true}
								/>
							</>
						)}
					</div>

					<div className="flagwrapper">
						<StatusLabel
							status={status}
							label={visible_status}
							px={15}
							is_applied={poke_type === "application"}
							fromDetails={true}
						/>
					</div>
				</div>
			) : (
				<div className="section">
					<div className="flagwrapper">
						<div className="section-title flag">
							<img
								src={
									(hasInterview && !Array.isArray(interview)) ||
									poke_type === "application"
										? FlagGrey
										: Flag
								}
								alt=""
							/>
							<div className="text poke">
								{(hasInterview && !Array.isArray(interview)) ||
								poke_type === "application"
									? "sourced"
									: stepperStatus}
							</div>
						</div>
						<StatusLabel
							position="absolute"
							right={0}
							status={status}
							label={visible_status}
							px={15}
							fromDetails={true}
						/>
					</div>
					{poke_type === "application" ? (
						<>
							<PokeMessage>
								<Text mt={10} as={"p"} sx={{ textAlign: "center" }}>
									You have applied on <strong>{formatDate(applied_at)}</strong>.{" "}
									You will be informed of any status change.
								</Text>
								{getDetailsText()}
							</PokeMessage>
						</>
					) : (
						<PokeMessage>
							You have been sourced on <strong>{pokeDate}</strong> by{" "}
							<strong>{clientName}</strong> from <strong>{companyName}</strong>{" "}
							for the vacancy <strong>{title}</strong> detailed below. <br />
							{getDetailsText()}
						</PokeMessage>
					)}
				</div>
			)}

			{displayInterviewDetailsModal && (
				<InterviewDetailsModal
					closeModal={() => setDisplayInterviewDetailsModal(false)}
					data={selectedInterview}
					userId={user.id}
					cancelInterviewAt={cancelInterviewAt}
				/>
			)}
		</div>
	);
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ toggleHelpModal, setInterviewTime }, dispatch);
export default connect(null, mapDispatchToProps)(PokeDetails);
