import { MANAGE_INVITATION_CALENDAR_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

const send = body => {
	return client(MANAGE_INVITATION_CALENDAR_ENDPOINT, {
		body
	});
};

function useAcceptInvitation(options) {
	return useMutation(send, {
		refetchOnWindowFocus: false,
		retry: false,
		...options
	});
}

export default useAcceptInvitation;
