import { browserHistory } from "react-router";
import _get from "lodash/get";
import * as types from "./actionTypes";
import {
	CLIENT,
	CONSULTANCY,
	STATUS_ACTIVATED,
	IDENTITY_COMPLETED,
	CLIENTID,
	GRANTTYPE,
	PROJECT_MANAGER,
	PERMANENT,
	FREELANCER,
	BOT,
	COMPANY_STEP,
	OFFLINE_JOB_BOARD,
	OFFLINE_JOB_PAGE,
	JOB_BOARD,
	EBRANDING_TOKEN_COOKIE_NAME,
	EBRANDING_USER_SWITCHED_COOKIE_NAME,
	EBRANDING_COOKIE_OPTIONS
} from "config";
import { storeUserId } from "./signUpActions";
import { getSignUpSecondStepRoute, getSignUpSuccessRoute } from "../components";

import {
	setLocalUser,
	removeLocalUser,
	getLocalUser,
	setCookie
} from "config/helpers";
import AxiosHelper, { endpoints } from "config/axios-helper";
import { getAccountActivatedRoute } from "../../company/components/AccountActivated";
import { getMyConsultantsRoute } from "../../consultancy/components/MyConsultants";
import { getMissionsContainerRoute } from "../../contract/components/MissionsContainer";
import { authenticatedUserSelector } from "../../bids/selectors";
import { getResumeRoute } from "../../cv/components/ResumeContainer";
import { clearAllSearchFilters } from "modules/jobSearch/actions";
import { skipIdentity } from "modules/company/actions/identityActions";
import { APPLY_TO_VACANCY_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { v4 as uuid } from "uuid";
import { searchParser } from "config/helpers";
import { serialize } from "cookie";
import { getSignInRoute } from "../components/Authentication/Login";
import { COOKIE_COMPANY, COOKIE_TOKEN } from "config";
import { collaborativeGroupInvitationRouteRegex } from "modules/collaborative-group-invitation/collaborative-group-invitation";
import { VIRTUAL_ROOM_URL_REGEX } from "modules/calendar/utils/constants";

const applyToJob = (data, headers) =>
	client(APPLY_TO_VACANCY_ENDPOINT, { body: data, headers });

function receiveLogin(user) {
	setLocalUser(user);
	return dispatch =>
		dispatch({
			type: types.LOGIN_SUCCESS,
			data: user
		});
}

function shareTokenWithEbranding(token) {
	document.cookie = serialize(
		EBRANDING_TOKEN_COOKIE_NAME,
		token,
		EBRANDING_COOKIE_OPTIONS
	);
	document.cookie = serialize(
		EBRANDING_USER_SWITCHED_COOKIE_NAME,
		"true",
		EBRANDING_COOKIE_OPTIONS
	);
}

export function toggleWelcome() {
	return dispatch =>
		dispatch({
			type: types.TOGGLE_WELCOME
		});
}
export function toggleGetStartedDialog() {
	return dispatch =>
		dispatch({
			type: types.TOGGLE_GET_STARTED_DIALOG
		});
}

export function receiveLogout(params) {
	return dispatch => {
		removeLocalUser();
		dispatch(clearAllSearchFilters());
		if (_get(params, "admin_user_id")) {
			browserHistory.push("/switch-user");
		} else if (params && params.redirect) {
			browserHistory.push(getSignInRoute());
		}

		return dispatch({
			type: types.LOGOUT_SUCCESS,
			isLoggedIn: false
		});
	};
}

export function removeUserLocally() {
	document.cookie = serialize(
		EBRANDING_TOKEN_COOKIE_NAME,
		"",
		EBRANDING_COOKIE_OPTIONS
	);
	document.cookie = serialize(
		EBRANDING_USER_SWITCHED_COOKIE_NAME,
		"true",
		EBRANDING_COOKIE_OPTIONS
	);

	const user = getLocalUser();
	user && removeLocalUser(true);
	browserHistory.push(getSignInRoute());
}

export function getMyProfile(isLogin = false) {
	return AxiosHelper.__post({
		isLogin,
		url: endpoints.GET_MY_PROFILE,
		manageByDefault: false,
		next: receiveLogin
	});
}

export function createToken(
	creds,
	redirect = true,
	redirectTo,
	reduxFormName,
	applyParams,
	clientid
) {
	const data = {
		...creds,
		client_id: clientid ? "AdminHireMe" : CLIENTID,
		grant_type: GRANTTYPE
	};
	return AxiosHelper.__post({
		url: endpoints.CREATE_TOKEN,
		data,
		isLogin: true,
		reduxFormName,
		loadingText: "Signing in...",
		next: () => doLogin(data, redirect, redirectTo, applyParams),
		REQUEST_FAIL: types.USER_BLOCKED
	});
}

function doLogin(creds, redirect, redirectTo, applyParams) {
	return AxiosHelper.__post({
		url: endpoints.SIGN_IN,
		data: creds,
		isLogin: true,
		loadingText: "Signing in...",
		next: data => dispatch => {
			const {
				company_status,
				company_type,
				role_name,
				step,
				_id,
				first_name,
				last_name,
				email,
				type,
				progress,
				access_token,
				company_name,
				company_vat,
				is_first_login,
				active_company
			} = data;
			const searchParams = searchParser();
			const isClient = company_type === CLIENT;
			const id = uuid();

			if (step && step !== IDENTITY_COMPLETED) {
				dispatch(storeUserId(_id));
				dispatch({
					type: types.SIGN_UP_SUCCESS,
					name: `${first_name} ${last_name}`,
					email: email
				});
				removeLocalUser();
				if (step === COMPANY_STEP) {
					browserHistory.push(getSignUpSecondStepRoute(type));
				}
				if (step === BOT) {
					browserHistory.push(getSignUpSuccessRoute(type));
				}
				return Promise.resolve(true);
			}

			let URL = isClient ? "/home" : "/company";

			if (isClient) {
				setCookie(COOKIE_TOKEN, access_token);
				setCookie(COOKIE_COMPANY, active_company);
			}

			// Share user connection with employer branding (only candidate & freelancer for now)
			if (type === PERMANENT || company_type === FREELANCER) {
				shareTokenWithEbranding(access_token);
			}

			if (!redirect && type === PERMANENT) {
				URL = getResumeRoute();
				if (applyParams) {
					applyToJob(
						{
							tag:
								applyParams.source_id === JOB_BOARD
									? OFFLINE_JOB_BOARD
									: OFFLINE_JOB_PAGE,
							id,
							...applyParams
						},
						{
							Authorization: `Bearer ${access_token}`
						}
					)
						.then(() => browserHistory.push(`/pokes/${id}`))
						.catch(() => browserHistory.push(`/my-cv#2`));
				}
			}
			if (company_type === FREELANCER && redirectTo) {
				if (applyParams) {
					const { company_logo, job_title } = applyParams;
					if (company_logo && job_title) {
						URL = "/jobs/freelancer/bids";
					}
				} else {
					URL = getResumeRoute();
					if (
						!company_name &&
						!company_vat &&
						redirectTo === getAccountActivatedRoute()
					) {
						URL = "/settings/company";
					}
				}
			}
			if (
				redirect &&
				!isClient &&
				company_status === STATUS_ACTIVATED &&
				redirectTo !== getAccountActivatedRoute()
			) {
				URL = "/jobs/search";
				if (company_type === CONSULTANCY) {
					if (role_name === PROJECT_MANAGER) {
						URL = getMissionsContainerRoute();
					} else {
						URL = getMyConsultantsRoute();
					}
				}
				if (redirectTo && redirectTo !== getAccountActivatedRoute()) {
					URL = redirectTo;
				}
			}
			if (redirect && type === PERMANENT) {
				URL = "/my-cv#2";
				if (applyParams) {
					applyToJob(
						{
							tag:
								applyParams.source_id === JOB_BOARD
									? OFFLINE_JOB_BOARD
									: OFFLINE_JOB_PAGE,
							id,
							...applyParams
						},
						{
							Authorization: `Bearer ${access_token}`
						}
					)
						.then(() => browserHistory.push(`/pokes/${id}`))
						.catch(() => browserHistory.push(`/my-cv#2`));
				}
				if (searchParams.poke_id) {
					URL = `/pokes/${searchParams.poke_id}`;
				}
				if (searchParams.job_lang && searchParams.job_id) {
					URL = `/jobs/search/${searchParams.job_id}?job_lang=${searchParams.job_lang}`;
				}
				if (_get(progress, "progress") > 50) {
					URL = "/jobs/search";
				}
				if (redirectTo && redirectTo !== getAccountActivatedRoute()) {
					URL = redirectTo;
				}
			}
			if (
				redirectTo &&
				isClient &&
				redirectTo.match(collaborativeGroupInvitationRouteRegex)
			) {
				URL = redirectTo;
			}
			if (
				redirectTo &&
				(isClient || type === PERMANENT) &&
				redirectTo.match(VIRTUAL_ROOM_URL_REGEX)
			) {
				URL = redirectTo;
			}
			dispatch(receiveLogin(data));

			if (isClient && is_first_login) {
				dispatch(toggleGetStartedDialog());
				skipIdentity();
				URL = "/settings/users";
			}
			redirect && browserHistory.push(URL);
			return Promise.resolve(true);
		}
	});
}

export function logout() {
	/*
	 * userInfo.token
	 * userInfo.id
	 */
	return (dispatch, getState) => {
		const storeUser = authenticatedUserSelector(getState());
		const adminToken = _get(storeUser, "admin_token.access_token");
		removeUserLocally();
		if (storeUser) {
			dispatch(
				AxiosHelper.__post({
					url: endpoints.LOGOUT,
					data: {
						token: adminToken ? adminToken : storeUser.access_token,
						admin_user_id: adminToken && _get(storeUser, "admin_token.admin_id")
					},
					loadingText: "Logging out...",
					next: receiveLogout,
					isLogin: true
				})
			);
		}
	};
}
