import { timestampToDateCalendar } from "config/helpers";

export const groupByDate = data => {
	const grouped = {};
	if (!data) return grouped;
	data.forEach(item => {
		const calendar = timestampToDateCalendar(item.start_date);
		const key = `${calendar.year}/${calendar.month}/${calendar.day}`;
		if (!grouped[key]) {
			grouped[key] = [];
		}
		grouped[key].push(item);
	});

	return grouped;
};
export const getDate = date => {
	if (!date) return null;
	const [year, month, day] = date.split("/");
	return {
		year: parseInt(year),
		month: parseInt(month),
		day: parseInt(day)
	};
};
