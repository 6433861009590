import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import { client } from "lib/api-client";

export const SEARCH_SETTINGS_CALENDAR_COLLABORATORS =
	"SEARCH_SETTINGS_CALENDAR_COLLABORATORS";

export const searchCalendarCollaborators = ({ search }) => {
	return new Promise((resolve, reject) => {
		client(MESSAGING_TOOL_FILTER_OPTIONS, {
			body: {
				list: "calendar_groups",
				filter: "members_calendar_groups",
				search: search
			}
		})
			.then(res => {
				const attendees = res.map(
					({ _id, first_name, last_name, avatar, user_function }) => ({
						id: _id,
						label: `${first_name} ${last_name}`,
						headline: user_function,
						avatar
					})
				);

				resolve([{ id: 1, label: "Collaborators", children: attendees }]);
			})
			.catch(e => reject(e));
	});
};
