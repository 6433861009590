import React from "react";
import styles from "./vacancy-view-times-list.module.scss";
import { ReactComponent as PlusIcon } from "static/icons/plus.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-icon-grey.svg";
import NewTimePicker from "common/NewTimePicker";
import { useDateFormatter } from "react-aria";
import { getLocalTimeZone, Time } from "@internationalized/date";
import produce from "immer";
import cx from "classnames";
import { useFormContext } from "react-hook-form";
import { FieldError } from "common/FieldError";

const DEFAULT_TIME = new Time(0, 0);
const VacancyViewTimesList = ({
	date,
	times = [],
	onChange: onChangeProp,
	onAdd,
	onRemove,
	allTimes
}) => {
	const {
		formState: { errors }
	} = useFormContext();
	let formatter = useDateFormatter({ dateStyle: "long" });
	const [day, month] = formatter
		.format(date.toDate(getLocalTimeZone()))
		.split(" ");

	const onChange = (time, value) => {
		const newTimes = produce(allTimes, draft => {
			const index = draft.findIndex(({ id }) => id === time.id);
			draft[index] = {
				...time,
				...value,
				endTime: value.startTime
					? value.startTime.add({ hours: 1 })
					: value.endTime
			};
		});

		onChangeProp(newTimes);
	};

	return (
		<div className={styles.slotItem}>
			<div className={styles.slotDateValue}>
				{month} {day}
			</div>
			{times.map((time, index) => (
				<React.Fragment key={time}>
					<div className={styles.row}>
						<NewTimePicker
							inputClassName={styles.timeInput}
							onChange={value =>
								onChange(time, { startTime: value || DEFAULT_TIME })
							}
							showIcon={false}
							value={time.startTime}
							shouldForceLeadingZeros
						/>
						<span className={styles.timeSlotSeparator}>-</span>
						<NewTimePicker
							inputClassName={styles.timeInput}
							onChange={value =>
								onChange(time, { endTime: value || DEFAULT_TIME })
							}
							showIcon={false}
							value={time.endTime}
							minValue={time.startTime}
							shouldForceLeadingZeros
						/>
						{index === 0 ? (
							<button
								onClick={() => onAdd(time.date)}
								className={styles.addTimeSlotBtn}
							>
								<PlusIcon />
							</button>
						) : (
							<button
								onClick={() => onRemove(time.id)}
								className={cx(styles.addTimeSlotBtn, styles.borderless)}
							>
								<CloseIcon className={styles.closeIcon} />
							</button>
						)}
					</div>
					{Array.isArray(errors.times) && (
						<FieldError
							className={styles.error}
							error={errors.times[allTimes.indexOf(time)]}
						/>
					)}
				</React.Fragment>
			))}
		</div>
	);
};

export default VacancyViewTimesList;
