import React, { useMemo, useRef } from "react";
import Table from "common/AgTable/AgTable";
import { generateEmptyArray } from "config/helpers";
import { useNotesColumns } from "../../hooks/useNotesColumns";
import { API_DATA_TYPE, NOTES_PAGE_SIZE } from "../utils";
import NotesTableEmptyState from "../NotesTableEmptyState/NotesTableEmptyState";
import { formatNotesList } from "../utils/_helper";

const NoteTable = ({
	columns: columnsProps,
	notesList: notesListProp,
	isLoading,
	isFetching,
	handleDrawerDetails
}) => {
	const ref = useRef(null);
	const notesList = useMemo(() => {
		return formatNotesList(notesListProp ?? []);
	}, [notesListProp]);

	const onRowClick = ({ column, data }) => {
		const colId = column.colId;
		if (colId === "_selects" || colId === "website") return;
		handleDrawerDetails?.(data);
	};

	const columns = useNotesColumns({
		columns: columnsProps,
		isFetching,
		isLoading,
		tableRef: ref
	});

	return (
		<Table
			rows={
				isLoading || isFetching
					? generateEmptyArray(NOTES_PAGE_SIZE)
					: notesList
			}
			columnsSettings={columns}
			ref={ref}
			typeTag={API_DATA_TYPE}
			noRowsOverlayComponent={NotesTableEmptyState}
			onCellClicked={onRowClick}
		/>
	);
};

export default NoteTable;
