import React from "react";
import styles from "./calendar-working-day-timeslot.module.scss";
import { ReactComponent as PlusIcon } from "static/icons/plus.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-icon-grey.svg";
import NewTimePicker from "common/NewTimePicker";
import cx from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { Time } from "@internationalized/date";
import FieldError from "../../../../common/MessagingToolNewEmail/components/FieldError";
import { generateId } from "config/helpers";

const MAX_TIME_SLOT_PER_DAY = 2;

const CalendarWorkingDayTimeSlot = ({ nestedIndex, day, updateDay }) => {
	const {
		control,
		formState: { errors }
	} = useFormContext();

	const NESTED_FIELDS_NAME = `work_days[${nestedIndex}].time_slots`;

	const onAdd = () => {
		const firstTimeSlot = day.time_slots[0];
		updateDay(nestedIndex, {
			...day,
			time_slots: [
				...day.time_slots,

				{
					id: generateId(),
					start_time: new Time(
						firstTimeSlot.end_time.hour,
						firstTimeSlot.end_time.minute
					),
					end_time: new Time(firstTimeSlot.end_time.hour + 1, 0)
				}
			]
		});
	};

	const onRemove = id => {
		updateDay(nestedIndex, {
			...day,
			time_slots: day.time_slots.filter(el => el.id !== id)
		});
	};

	return (
		<div className={styles.slotItem}>
			{day.time_slots.map((time, index) => (
				<React.Fragment key={time.id}>
					<div className={styles.row}>
						<Controller
							control={control}
							name={`${NESTED_FIELDS_NAME}[${index}].start_time`}
							render={({ field: { value, onChange } }) => {
								return (
									<NewTimePicker
										inputClassName={styles.timeInput}
										onChange={val => onChange(new Time(val?.hour, val?.minute))}
										showIcon={false}
										value={value}
										shouldForceLeadingZeros
									/>
								);
							}}
						/>
						<span className={styles.timeSlotSeparator}>To</span>
						<Controller
							control={control}
							name={`${NESTED_FIELDS_NAME}[${index}].end_time`}
							render={({ field: { value, onChange } }) => {
								return (
									<NewTimePicker
										inputClassName={styles.timeInput}
										onChange={val => onChange(new Time(val?.hour, val?.minute))}
										showIcon={false}
										value={value}
										shouldForceLeadingZeros
									/>
								);
							}}
						/>
						{index === 0 && day.time_slots.length < MAX_TIME_SLOT_PER_DAY ? (
							<button
								onClick={onAdd}
								className={styles.addTimeSlotBtn}
								type="button"
							>
								<PlusIcon />
							</button>
						) : (
							<button
								onClick={() => onRemove(time.id)}
								className={cx(styles.addTimeSlotBtn, styles.borderless)}
								type="button"
							>
								<CloseIcon className={styles.closeIcon} />
							</button>
						)}
					</div>
					<FieldError
						error={
							errors?.work_days?.[nestedIndex]?.time_slots?.[index]?.start_time
						}
					/>
				</React.Fragment>
			))}
		</div>
	);
};

export default CalendarWorkingDayTimeSlot;
