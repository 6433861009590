import React, { useEffect, useState } from "react";
import styles from "./connect-with-provider-component.module.scss";
import { ReactComponent as CheckIcon } from "static/icons/check.svg";
import { Button } from "common/Button";
import { DropDownWithSearch } from "common/DropDownWithSearch";
import { OverlayProvider } from "react-aria";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";
import { PENDING } from "config";
import { SERVICES } from "../../utils/constant";
import get from "lodash/get";

const ConnectWithProviderComponent = ({
	status,
	isLoading,
	isConnected,
	email,
	setOpenDisconnectConfirmationModal,
	onConnect,
	connectedProvider,
	providerApplication,
	isDisabled,
	activeProvider,
	setActiveProvider,
	tourKey
}) => {
	const [displayProviderOptions, setDisplayProviderOptions] = useState(false);

	useEffect(() => {
		const provider = localStorage.getItem("provider") || connectedProvider;

		setActiveProvider(provider);
	}, [connectedProvider]);

	const Icon = get(
		SERVICES,
		`[${activeProvider}][${providerApplication}].icon`
	);
	const OutlookIcon = SERVICES.microsoft.email.icon;
	const GoogleIcon = SERVICES.google.email.icon;

	return (
		<div className={styles.providerWrapper}>
			<div className={styles.provider}>
				{isLoading ? (
					<Skeleton width={214} height={44} />
				) : (
					<OverlayProvider>
						<DropDownWithSearch
							onClose={() => setDisplayProviderOptions(false)}
							paperClassName={styles.paperClassName}
							dropDownInputClassName={cx(styles.dropDownInputClassName, {
								[styles.disabled]: isDisabled
							})}
							dropdown
							displaySearchInput={false}
							onInputClick={() => setDisplayProviderOptions(true)}
							displayPaper={displayProviderOptions}
							variant="parent-width"
							value={
								<div className={styles.providerValue}>
									{Icon && <Icon className={styles.providerValueIcon} />}
									<span className={styles.providerEmail}>
										{isConnected
											? email
											: `${SERVICES[activeProvider]?.label} (${get(
													SERVICES,
													`[${activeProvider}][${providerApplication}].label`
											  )})`}
									</span>
								</div>
							}
							content={
								<div className={styles.providerOptions}>
									<span
										className={styles.option}
										onClick={() => {
											setActiveProvider(SERVICES.google.value);
											setDisplayProviderOptions(false);
										}}
									>
										<GoogleIcon className={styles.providerIcon} />
										{SERVICES.google.label}
										{activeProvider === SERVICES.google.value && (
											<CheckIcon className={styles.checkIcon} />
										)}
									</span>
									<span
										className={styles.option}
										onClick={() => {
											setActiveProvider(SERVICES.microsoft.value);
											setDisplayProviderOptions(false);
										}}
									>
										<OutlookIcon className={styles.providerIcon} />
										{SERVICES.microsoft.label}
										{activeProvider === SERVICES.microsoft.value && (
											<CheckIcon className={styles.checkIcon} />
										)}
									</span>
								</div>
							}
						/>
					</OverlayProvider>
				)}

				{/*  */}
			</div>
			<span
				className={cx(styles.syncStatus, {
					[styles.notSynced]: !isConnected
				})}
			>
				{isLoading ? (
					<Skeleton width={79} height={20} />
				) : isConnected ? (
					"Synced!"
				) : (
					"Not Synced"
				)}
			</span>
			<div className={styles.syncButtonRoot}>
				{isLoading ? (
					<Skeleton width={122} height={37} />
				) : (
					<Button
						data-onboarding-step={tourKey}
						variant="outlined"
						text={
							status === PENDING && activeProvider === SERVICES.microsoft.value
								? "Reconnect"
								: isConnected
								? "Disconnect"
								: "Connect"
						}
						className={cx(styles.syncButton, {
							[styles.connected]: isConnected,
							[styles.disconnected]: !isConnected
						})}
						onClick={() =>
							isConnected
								? setOpenDisconnectConfirmationModal(providerApplication)
								: onConnect(providerApplication, activeProvider)
						}
					/>
				)}
			</div>
		</div>
	);
};

export default ConnectWithProviderComponent;
