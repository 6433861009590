import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { CANCEL_APPLICATION_ENDPOINT } from "config/api-endpoints";

const rejectApplication = body =>
	client(CANCEL_APPLICATION_ENDPOINT, {
		body
	});

export const useRejectApplication = options =>
	useMutation(rejectApplication, {
		...options
	});
