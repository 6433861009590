import React from "react";
import styles from "../collaborative-groups-page.module.scss";
import { ReactComponent as UsersIcon } from "static/icons/users.svg";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as UserAddIcon } from "static/icons/users-plus-icon.svg";
import { ReactComponent as LeaveIcon } from "static/icons/leave-icon.svg";
import Delete from "common/AgTable/icons/Delete";
import ListLoadingState from "../collaborators-list/loading-state/loading-state";

const LoadingState = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<UsersIcon />
				Collaborative Groups
			</div>
			<div className={styles.flex}>
				<div className={styles.formInput}>
					<label className={styles.label}>Select a group</label>
					<Skeleton height={40} width={393} />
				</div>
				<div className={styles.formInput}>
					<label className={styles.label}>Group Name</label>
					<Skeleton height={40} width={393} />
				</div>
			</div>
			<div className={styles.collaboratorsTitle}>
				<div className={styles.title}>
					<UsersIcon />
					Group Members
				</div>
				<div className={styles.addBtn} type="button">
					<UserAddIcon />
					Add members
				</div>
			</div>

			<ListLoadingState />

			<div className={styles.actions}>
				<button type="button" className={styles.leave}>
					<LeaveIcon />
					Leave Group
				</button>

				<button type="button" className={styles.delete}>
					<Delete height={20} width={20} color={"#fff"} />
					Delete Group
				</button>
			</div>
		</div>
	);
};

export default LoadingState;
