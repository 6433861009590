import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_CALENDAR_EVENTS_ENDPOINT } from "config/api-endpoints";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import fakeData from "modules/user-onboarding/mockData/calendar-events-list.json";

const getEventsList = body =>
	client(LIST_CALENDAR_EVENTS_ENDPOINT, {
		body
	});

export const CALENDAR_EVENTS_LIST_KEY = "CALENDAR_EVENTS_LIST_KEY";

const useGetEventsList = (body, options) => {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery([CALENDAR_EVENTS_LIST_KEY, body], () => {
			return Promise.resolve(fakeData);
		});
	}
	return useQuery([CALENDAR_EVENTS_LIST_KEY, body], () => getEventsList(body), {
		retry: 0,
		...options
	});
};

export default useGetEventsList;
