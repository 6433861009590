import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const { MESSAGING_TOOL_FILTER_OPTIONS } = require("config/api-endpoints");
const { client } = require("lib/api-client");
const { useQuery } = require("react-query");

const getVacancies = body => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body
	});
};

const useGetVacancies = (body, options) => {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery([body], () => {
			return Promise.resolve([]);
		});
	}
	return useQuery(["GET_VACANCIES", body], () => getVacancies(body), {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
};

export default useGetVacancies;
