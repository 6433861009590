import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { INPUT_GROUP_TITLE_MAX } from "../utils/constant";

export const useCollaborativeGroupsForm = () => {
	return useForm({
		shouldUnregister: false,
		resolver: yupResolver(
			yup.object().shape({
				groupName: yup
					.string()
					.trim()
					.required("Group name is required")
					.test(
						"Valid",
						`Title has more than ${INPUT_GROUP_TITLE_MAX} characters`,
						value => {
							return value.length < INPUT_GROUP_TITLE_MAX;
						}
					)
			})
		)
	});
};
