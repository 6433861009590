import React, { useEffect, useState } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import NoteTable from "./components/noteTable/noteTable";
import styles from "./notesList.module.scss";
import useGetNotesTableSettings from "./api/useGetNotesTableSettings";
import {
	API_DATA_TYPE,
	NOTES_FILTER_ID,
	NOTES_PAGE_SIZES
} from "./components/utils";
import PageSizeSelect from "common/PageSizeSelect/PageSizeSelect";
import useGetNotesList from "./hooks/useGetNotesList";

import _get from "lodash/get";

import NoteHeader from "./components/NoteHeader";
import NotesTabPagination from "./components/NotesTabPagination";
import NotesTabTotalCount from "./components/NoteTabTotalCount";

import { FilterDrawer } from "common/FilterDrawer";
import { validFiltersCount } from "common/FilterDrawer/render_utils";
import { useBreadCrumbs } from "hooks/useBreadCrumb";
import NoteDrawerDetails from "./components/NotesDrawerDetails";
import { useDisplayRecap } from "./hooks/useDisplayNotesFilterRecap";
import { useInitializeFilterDrawer } from "./hooks/useInitializeFilterDrawer";
import { isNotesFiltersFull } from "./components/utils/_helper";
import NotesFiltresRecap from "./components/NotesFiltresRecap/notesFiltresRecap";
import cx from "classnames";

export const getNoteTableRoute = () => "/notes/list";
export const getNotePermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
const NotesList = () => {
	useBreadCrumbs([{ name: "Permanent" }, { name: "Notes" }]);

	const { data: tableSettings } = useGetNotesTableSettings();
	const [currentNoteDetails, setCurrentNoteDetails] = useState(null);

	const {
		displayRecap: showFiltersRecap,
		toggleDisplayRecap
	} = useDisplayRecap();

	useInitializeFilterDrawer();
	const { getState, setLimit, setOffset, setShowDrawer } = useFilters();
	const {
		filters,
		isLoading,
		isFetching,
		data: notesList,
		limit,
		selectedCriteria,
		showDrawer
	} = getState(NOTES_FILTER_ID);

	const refetch = useGetNotesList();

	useEffect(() => {
		setLimit(_get(tableSettings, "table_settings")?.size);
	}, [tableSettings]);

	const onPageClick = page => {
		setOffset(page * notesList?.size);
	};
	const handleDrawerDetails = data => {
		setCurrentNoteDetails(data);
	};
	const closeDrawer = () => {
		setCurrentNoteDetails(null);
	};

	return (
		<div className={cx(styles.container, styles.root)}>
			<div className={styles.vacanciesContent}>
				<NoteHeader
					onFilterClick={() => setShowDrawer(true)}
					onRecapClick={toggleDisplayRecap}
					columns={tableSettings?.ordered_columns}
				/>
				{showFiltersRecap && isNotesFiltersFull(filters) && (
					<NotesFiltresRecap
						module_id={NOTES_FILTER_ID}
						filters={filters}
						selectedCriteria={selectedCriteria}
					/>
				)}
				<NotesTabTotalCount
					isLoading={isLoading}
					isFetching={isFetching}
					total={notesList?.total}
				/>
				<NoteTable
					notesList={notesList?.data}
					columns={tableSettings?.columns}
					isLoading={isLoading}
					isFetching={isFetching}
					handleDrawerDetails={handleDrawerDetails}
				/>
			</div>
			<div className={styles.containerFooter}>
				<PageSizeSelect
					limit={limit}
					setLimit={setLimit}
					refetch={refetch}
					rowsPages={NOTES_PAGE_SIZES}
					tableTag={API_DATA_TYPE}
				/>
				{notesList && notesList?.total > notesList?.size && (
					<NotesTabPagination
						total={notesList?.total}
						size={notesList?.size}
						offset={notesList?.offset}
						onPageClick={onPageClick}
					/>
				)}
				{showDrawer && (
					<FilterDrawer
						onClose={() => setShowDrawer(false)}
						displayDrawer={showDrawer}
						hideTabSaved
						onlyList
						displayAdvancedSearchHead={false}
						title={
							validFiltersCount(filters) === 0
								? "Filter"
								: `Filter (${validFiltersCount(filters)})`
						}
					/>
				)}
				{!!currentNoteDetails && (
					<NoteDrawerDetails
						currentNoteDetails={currentNoteDetails}
						closeDrawer={closeDrawer}
						notesList={notesList?.data}
						offset={notesList?.offset}
						total={notesList?.total}
						size={notesList?.size}
						setOffset={setOffset}
						noteId={currentNoteDetails.id}
					/>
				)}
			</div>
		</div>
	);
};

export default NotesList;
