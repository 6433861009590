import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";

const getAttendeesSchedule = body => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body: {
			filter: "attendees_schedule",
			keyword: "",
			limit: 10,
			offset: 0,
			...body
		}
	});
};

const useGetAttendeesSchedule = (body, options) => {
	return useQuery([body], () => getAttendeesSchedule(body), {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
};

export default useGetAttendeesSchedule;
