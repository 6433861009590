import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { LIST_LATEST_INTERVIEWS } from "config/api-endpoints";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const GET_LATEST_INTERVIEWS_QUERY_KEY = "GET_LATEST_INTERVIEWS_QUERY_KEY";

const getListLatestInterviews = (_, { vacancyId, profileId }) => {
	return client(LIST_LATEST_INTERVIEWS, {
		body: {
			profile_id: profileId,
			vacancy_id: vacancyId
		}
	});
};

export const useGetLatestInterviews = ({ vacancyId, profileId }, options) => {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery(GET_LATEST_INTERVIEWS_QUERY_KEY, () => {
			return Promise.resolve([]);
		});
	}
	const res = useQuery(
		[GET_LATEST_INTERVIEWS_QUERY_KEY, { vacancyId, profileId }],
		getListLatestInterviews,
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...options
		}
	);

	return res;
};
