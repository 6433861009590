import { ReactComponent as GoogleIcon } from "static/icons/google.svg";
import { ReactComponent as OutlookIcon } from "static/icons/outlook.svg";
import { ReactComponent as GoogleCalendarIcon } from "static/icons/google-calendar-icon.svg";
import { ReactComponent as OutlookCalendarIcon } from "static/icons/outlook-calendar-icon.svg";

export const SERVICES = {
	google: {
		label: "Google",
		value: "google",
		email: {
			icon: GoogleIcon,
			label: "Email",
			value: "email"
		},
		calendar: {
			icon: GoogleCalendarIcon,
			label: "Calendar",
			value: "calendar"
		}
	},
	microsoft: {
		label: "Outlook",
		value: "microsoft",
		email: {
			icon: OutlookIcon,
			label: "Email",
			value: "email"
		},
		calendar: {
			icon: OutlookCalendarIcon,
			label: "Calendar",
			value: "calendar"
		}
	}
};
