import React from "react";
import styles from "./groups-confirm-modal.module.scss";
import ConfirmationModal, {
	Header,
	Description,
	Footer
} from "common/ConfirmationModal";
import cx from "classnames";
import { DISPLAY_GROUP_TITLE_MAX } from "modules/calendar-settings/utils/constant";

const GroupsConfirmModal = ({
	onClose,
	btnText,
	description,
	onConfirm,
	isLoading,
	title
}) => {
	return (
		<ConfirmationModal onClose={onClose} zIndex={10000} isLoading={isLoading}>
			<Header
				title={
					title.length > DISPLAY_GROUP_TITLE_MAX
						? title.substring(0, DISPLAY_GROUP_TITLE_MAX) + `..."`
						: title
				}
			/>
			<Description>{description}</Description>
			<Footer>
				<button type="button" className={styles.btn} onClick={onClose}>
					Cancel
				</button>
				<button
					type="button"
					className={cx(styles.btn, styles.confirm)}
					onClick={onConfirm}
				>
					{btnText}
				</button>
			</Footer>
		</ConfirmationModal>
	);
};

export default GroupsConfirmModal;
