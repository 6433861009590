import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { array, object, string } from "yup";
import {
	ADD_INTERVIEW_NOTE_MAX_LENGTH,
	LOCATION_TYPES,
	MAX_LENGTH_IN_BYTES
} from "../helper/constants";
import { getDefaultValues, isTimeValid } from "../helper/utils";
import { bytesToSize, convertDateAndTimeUsingTimezone } from "config/helpers";
import { useGetUser } from "hooks/useGetUser";

export const useProposePermanentInterviewForm = ({
	candidate,
	interview,
	applicationId,
	job
}) => {
	const connectedUser = useGetUser();
	const schema = object().shape({
		dates: array().test("Required", "Please select a day", function(value) {
			return value.length > 0;
		}),
		times: array()
			.of(
				object().test("Unique time", "Please choose unique times", function(
					value
				) {
					const timesList = [];
					const getPath = time => {
						const { year, month, day } = time.date;
						const { hour, minute } = time.startTime;
						const { hour: endHour, minute: endMinute } = time.endTime;
						return `${year}-${month}-${day}-${hour}-${minute}-${endHour}-${endMinute}`;
					};
					for (const time of this.parent) {
						timesList.push(getPath(time));
					}
					return timesList.filter(p => getPath(value) === p).length > 1
						? false
						: true;
				})
			)
			.test("Required", "Time is invalid", function(value) {
				const invalidTimes = value.filter(
					item => !isTimeValid(item.startTime, item.endTime)
				);

				return invalidTimes.length === 0;
			})
			.test("Invalid", "Availability slots should be in the future", function(
				value
			) {
				const now = window.moment.tz(connectedUser.timezone).unix();
				return value.every(item => {
					const startDateTime = convertDateAndTimeUsingTimezone(
						{
							year: item.date.year,
							month: item.date.month,
							day: item.date.day,
							hour: item.startTime.hour,
							minute: item.startTime.minute
						},
						connectedUser.timezone
					).unix();

					return startDateTime > now;
				});
			}),
		attendees: array().test("Required", "Please select attendees", function(
			value
		) {
			return value?.[0]?.children.length > 0;
		}),
		locationValue: string()
			.notRequired()
			.test("Missing fields", "Please enter a valid address", function(value) {
				if (this.parent.locationType === LOCATION_TYPES.online.value)
					return true;
				else if (
					this.parent.locationType === LOCATION_TYPES.companyAddress.value
				) {
					return !!value;
				}
				if (value) {
					const address = JSON.parse(value);
					if (!address.street)
						return this.createError({ message: "Street is required" });
					if (!address.number)
						return this.createError({ message: "Number is required" });
					if (!address.city)
						return this.createError({ message: "City is required" });
					if (!address.zip)
						return this.createError({ message: "Zip is required" });
					if (!address.country)
						return this.createError({ message: "Country is required" });
				}
				return true;
			}),
		note: string().test(
			`The input is more than ${ADD_INTERVIEW_NOTE_MAX_LENGTH} characters long`,
			function(value) {
				if (!value) return true;
				else return value?.length <= ADD_INTERVIEW_NOTE_MAX_LENGTH;
			}
		),
		content: string().test(
			"content-size",
			({ value }) => {
				return `Content is too long (${bytesToSize(
					new TextEncoder().encode(value).length
				)}), max is ${bytesToSize(MAX_LENGTH_IN_BYTES)}`;
			},
			value => {
				return new TextEncoder().encode(value).length < MAX_LENGTH_IN_BYTES;
			}
		),
		...(!applicationId && {
			vacancy: object({
				label: string().required(),
				value: string()
					.nullable()
					.required()
			})
				.nullable()
				.test("Required", "Please select a vacancy", function(value) {
					return value?.value;
				}),
			candidate: object({
				_id: string()
					.nullable()
					.required()
			})
				.nullable()
				.test("Required", "Please select a candidate", function(value) {
					return value?._id;
				})
		})
	});

	const formContext = useForm({
		resolver: yupResolver(schema),
		mode: "onChange",
		defaultValues: getDefaultValues({
			interview,
			connectedUser,
			candidate,
			job
		}),
		shouldUnregister: false
	});

	return formContext;
};
