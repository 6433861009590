export const COLLABORATOR_GROUP_ROLES = {
	viewer: {
		value: "viewer",
		label: "Viewer"
	},
	admin: {
		value: "admin",
		label: "Admin"
	}
};

export const COLLABORATOR_GROUP_PERMISSIONS = {
	freeBusy: {
		value: "free_busy",
		label: "See only free/busy"
	},
	eventNames: {
		value: "event_names",
		label: "See event names"
	},
	eventDetails: {
		value: "event_details",
		label: "See all event details"
	}
};
