import React from "react";
import styles from "./calendar-collaborative-groups-empty-state.module.scss";

const EmptyState = () => {
	return (
		<div className={styles.emptyState}>
			<div className={styles.description}>
				You&apos;re not part of any collaborative group yet. Create a new one
				from the (+) button above
			</div>
		</div>
	);
};

export default EmptyState;
