import { SEARCH_ADDRESS, COMPANY_ADDRESS, PHONE_SCREEN } from "config";
import {
	FILTER_SETTINGS_ENDPOINT,
	GET_AUTHENTICATION_DETAILS
} from "config/api-endpoints";
import { client } from "lib/api-client";
import flatten from "lodash/flatten";
import _get from "lodash/get";
import { LOCATION_TYPES } from "./constants";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import { v4 as uuid } from "uuid";
import {
	convertDateAndTimeUsingTimezone,
	timestampToDateCalendar
} from "config/helpers";
import { Time } from "@internationalized/date";

export const getAttendees = (connectedUser, vacancyId) => {
	return new Promise((resolve, reject) => {
		client(FILTER_SETTINGS_ENDPOINT, {
			body: {
				tag: "temporary_user_attendees",
				job_id: vacancyId
			}
		})
			.then(res => {
				const rest = res.list_users.map(({ _id, user_name, avatar }) => ({
					id: _id,
					label: user_name,
					avatar
				}));

				const user = {
					id: connectedUser.id,
					label: `${connectedUser.first_name} ${connectedUser.last_name}`,
					avatar: connectedUser.avatar
				};

				resolve([{ id: 1, label: "Collaborators", children: [user, ...rest] }]);
			})
			.catch(e => reject(e));
	});
};

export const getCalendarEventAttendees = (
	connectedUser,
	vacancyId,
	workflowId
) => {
	if (workflowId) {
		return [];
	}
	return new Promise((resolve, reject) => {
		client(GET_AUTHENTICATION_DETAILS, {
			body: {
				filter: "calendar_event_attendees",
				keyword: "",
				limit: 10,
				offset: 0,
				vacancy_id: vacancyId
			}
		})
			.then(res => {
				const rest = res.map(
					({ _id, full_name, avatar, groups, active_role }) => ({
						id: _id,
						label: full_name,
						avatar,
						groups,
						active_role
					})
				);

				const children = [...rest];
				const user = {
					id: connectedUser.id,
					label: `${connectedUser.first_name} ${connectedUser.last_name}`,
					avatar: connectedUser.avatar
				};
				const alreadyExist = children.find(
					item => item.id === connectedUser.id
				);
				if (!alreadyExist) children.push(user);
				resolve([{ id: 1, label: "Collaborators", children }]);
			})
			.catch(e => reject(e));
	});
};
export const composeAddress = addressObj => {
	return `${_get(addressObj, "street", "")} ${_get(addressObj, "number", "")}${
		addressObj.number && addressObj.box ? " / " : " "
	}${_get(addressObj, "box", "")} ${_get(addressObj, "zip", "")} ${
		_get(addressObj, "city") ? `${_get(addressObj, "city")},` : ""
	} ${_get(addressObj, "country", "")}`
		.replace(/\s\s+/g, " ")
		.replace(/\s,+/g, ", ");
};

export const isTimeValid = (startTime, endTime) => {
	if (startTime && endTime) {
		return endTime.hour === startTime.hour
			? endTime.minute > startTime.minute
			: endTime.hour > startTime.hour;
	}
	return false;
};

export const prepareProposeInterviewPayload = ({
	formData,
	logEvent,
	companyType,
	applicationId,
	interviewId,
	isDeclineInterview,
	timezone
}) => {
	const availabilitySlots = formData.times.map(time => {
		const { year, month, day } = time.date;
		const formattedStartTime = convertDateAndTimeUsingTimezone(
			{
				year,
				month,
				day,
				hour: time.startTime.hour,
				minute: time.startTime.minute
			},
			timezone
		);

		const formattedEndTime = convertDateAndTimeUsingTimezone(
			{
				year,
				month,
				day,
				hour: time.endTime.hour,
				minute: time.endTime.minute
			},
			timezone
		);

		return {
			start_date: formattedStartTime.utc().unix(),
			end_date: formattedEndTime.utc().unix()
		};
	});

	const attendeesIds = flatten(
		formData.attendees.map(({ children }) => children.map(({ id }) => id))
	);
	const locationPayload = [SEARCH_ADDRESS, COMPANY_ADDRESS].includes(
		formData.locationType
	)
		? {
				...JSON.parse(formData.locationValue)
		  }
		: {};

	const payload = {
		id: formData.id,
		description: formData.note,
		attendees_users: attendeesIds,
		location_type: formData.locationType,
		...locationPayload,
		application_id: applicationId,
		recipient_type: companyType,
		log_event: logEvent,
		availability_slots: availabilitySlots,
		subject: formData.subject,
		content: formData.content,
		content_placeholders: formData.content_placeholders.map(placeholder => ({
			key: placeholder.value,
			value:
				placeholder.value === "INTERVIEW_LINK" && !placeholder._id
					? isDeclineInterview
						? interviewId
						: formData.id
					: placeholder._id || ""
		})),
		attachments: [],
		...(interviewId && {
			interview_id: interviewId
		})
	};
	return payload;
};

export const preparePhoneScreenPayload = ({
	formData,
	logEvent,
	companyType,
	applicationId,
	interviewId,
	isDeclineInterview
}) => {
	const availabilitySlots = formData.times.map(time => {
		const formattedDate = window
			.moment(time.date.toDate())
			.format("YYYY-MM-DD");
		const formattedStartTime = window
			.moment(time.startTime.toString(), "HH:mm:ss")
			.format("HH:mm");
		const formattedEndTime = window
			.moment(time.endTime.toString(), "HH:mm:ss")
			.format("HH:mm");
		return {
			start_date: window
				.moment(`${formattedDate} ${formattedStartTime}`)
				.utc()
				.unix(),
			end_date: window
				.moment(`${formattedDate} ${formattedEndTime}`)
				.utc()
				.unix()
		};
	});
	const attendeesIds = flatten(
		formData.attendees.map(({ children }) => children.map(({ id }) => id))
	);

	const payload = {
		id: formData.id,
		description: formData.note,
		attendees_users: attendeesIds,
		location_type: PHONE_SCREEN,
		application_id: applicationId,
		recipient_type: companyType,
		phone: `${formData.phone.countryCode.toUpperCase()} ${
			formData.phone.value
		}`,
		log_event: logEvent,
		availability_slots: availabilitySlots,
		subject: formData.subject,
		content: formData.content,
		content_placeholders: formData.content_placeholders.map(placeholder => ({
			key: placeholder.value,
			value:
				placeholder.value === "INTERVIEW_LINK" && !placeholder._id
					? isDeclineInterview
						? interviewId
						: formData.id
					: placeholder._id || ""
		})),
		attachments: [],
		...(interviewId && {
			interview_id: interviewId
		})
	};
	return payload;
};

export const formatAvailabilitySlots = (availability_slots, timezone) => {
	return availability_slots?.map(slot => {
		const today = window.moment.tz(timezone);
		const start = window.moment.unix(slot.start_date).tz(timezone);
		if (start.unix() < today.unix()) return;

		const end = window.moment.unix(slot.end_date).tz(timezone);

		return {
			id: uuid(),
			date: timestampToDateCalendar(slot.start_date, timezone),
			startTime: new Time(start.get("hours"), start.get("minutes")),
			endTime: new Time(end.get("hours"), end.get("minutes"))
		};
	});
};

export const getDefaultValues = ({
	interview,
	job,
	connectedUser,
	candidate
}) => {
	const timezone = connectedUser.timezone;
	const interviewDates = interview?.availability_slots
		?.map(slot => {
			const today = window.moment.tz(timezone).unix();
			const startDate = window.moment
				.unix(slot.start_date)
				.tz(timezone)
				.unix();
			if (startDate < today) return;
			return timestampToDateCalendar(slot.start_date, timezone);
		})
		?.filter(Boolean);

	const interviewTimes = formatAvailabilitySlots(
		interview?.availability_slots,
		timezone
	)?.filter(Boolean);

	return {
		id: uuid(),
		locationType:
			interview?.location_type || LOCATION_TYPES.companyAddress.value,
		locationValue: interview
			? JSON.stringify({
					street: interview.street || undefined,
					number: interview.number || undefined,
					box: interview.box || undefined,
					city: interview.city || undefined,
					zip: interview.zip || undefined,
					country: interview.country || undefined,
					longitude: interview.longitude || undefined,
					latitude: interview.latitude || undefined,
					iso_country: interview.iso_country || undefined,
					viewport:
						Object.keys(interview.viewport || {}).length > 0
							? interview.viewport
							: []
			  })
			: "",
		times: interviewTimes || [],
		dates: interviewDates || [],
		vacancy: job ? { value: job._id, label: job.title } : null,
		candidate: candidate._id ? candidate : null,
		note: interview?.description || "",
		attendees: [
			{
				id: 1,
				label: "Collaborators",
				children: interview?.attendees_users
					? interview?.attendees_users.map(user => ({
							id: user._id,
							label: `${user.first_name} ${user.last_name}`,
							avatar: user.avatar
					  }))
					: [
							{
								id: connectedUser.id,
								label: `${connectedUser.first_name} ${connectedUser.last_name}`
							}
					  ]
			}
		],
		...EMPTY_EMAIL,
		to: candidate ? [candidate] : []
	};
};
