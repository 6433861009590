import React, { useState, useEffect } from "react";
import globalStyles from "../styles.module.scss";
import styles from "./styles.module.scss";
import useDeleteAccountStore, {
	MAX_STEP,
	MIN_STEP
} from "./DeleteAccount/store";
import DeleteAccount from "./DeleteAccount";
import ChangePassword from "./ChangePassword";
import { ReactComponent as IconGreenKey } from "static/icons/IconGreenKey.svg";
import { ReactComponent as IconTrash } from "static/icons/IconTrash.svg";

const PrivacySettings = () => {
	const [openModal, setOpenModal] = useState(false);

	// delete account
	const {
		resetStore,
		currentStep,
		setHasNext,
		setHasPrev
	} = useDeleteAccountStore();

	useEffect(() => {
		if (currentStep === MAX_STEP) setHasNext(false);
		if (currentStep === MIN_STEP) setHasPrev(false);
		if (currentStep > MIN_STEP && currentStep < MAX_STEP) {
			setHasPrev(true);
			setHasNext(true);
		}
	}, [currentStep]);

	const onCloseDeleteUser = () => {
		setOpenModal(false);
		resetStore();
	};

	return (
		<>
			{openModal === "change-password" && (
				<ChangePassword onClose={() => setOpenModal(false)} />
			)}
			{openModal === "delete-account" && (
				<DeleteAccount onClose={onCloseDeleteUser} />
			)}
			<div
				className={globalStyles.white_box}
				style={{ marginBottom: "32px", position: "relative" }}
			>
				<div className={globalStyles.white_box__title}>Privacy</div>
				<div className={styles.privacy__settings}>
					<div className={styles.privacy__leftColumn}>
						<div
							className={styles.link + " " + styles.link__success}
							onClick={() => setOpenModal("change-password")}
						>
							<IconGreenKey />
							<span>Change password</span>
						</div>
						<div
							className={styles.link + " " + styles.link__danger}
							onClick={() => setOpenModal("delete-account")}
						>
							<IconTrash />
							<span>Delete account</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivacySettings;
