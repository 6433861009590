/* eslint-disable react/display-name */
import React, { useState, useRef, useLayoutEffect } from "react";
import {
	formatNote,
	getMentions,
	historyPush,
	parseMentions
} from "config/helpers";
import { v4 as uuid } from "uuid";
import useNotesStore from "modules/notes/notes.store";
import get from "lodash/get";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import cardStyle from "../Card/card.module.scss";
import noteStyle from "./notes-card.module.scss";
import Avatar from "common/Avatar/index";
import { ReactComponent as UserIcon } from "static/icons/custom_visibility_user.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { Scrollbars } from "react-custom-scrollbars";
import { stringify } from "query-string";
import { VACANCY_VIEW } from "modules/home/config";
import { browserHistory } from "react-router";
import useProfileView from "../DrawerFullView/useProfileView";
import { ReactComponent as EditIcon } from "static/icons/edit-filter.svg";
import { ReactComponent as DeleteIcon } from "static/icons/trash-03.svg";
import { ReactComponent as OnlyMeIcon } from "static/icons/lock-03.svg";
import { ReactComponent as CustomIcon } from "static/icons/custom-icon.svg";
import { ReactComponent as GlobeIcon } from "static/icons/globe-04.svg";
import Tooltip from "common/Tippy";

import { DELETE_MODAL } from "modules/notes/AddNote/utils";

import moment from "moment";
import { CustomAvatar } from "common/CustomAvatar";
import NoteCustomVisibilityListTags from "./components/NoteCustomVisibilityList/NoteCustomVisibilityListTags";
import loadable from "loadable-components";
import LexicalContentView from "common/LexicalContentView";
import { GROUP } from "config";

export const Portal = loadable(() => import("common/Portal"));
export const DeleteModal = loadable(() =>
	import("./components/DeleteModal/DeleteModal")
);
export const VisibilityIcon = {
	private: { icon: <OnlyMeIcon />, tooltip: "Only i can see" },
	public: { icon: <GlobeIcon />, tooltip: "Anyone in my company can see" },
	custom: { icon: <CustomIcon />, tooltip: "custom" }
};
const Note = ({
	note,
	vacancy,
	onClick,
	showText = false,
	noteContainerMaxHeight,
	noteDrawer,
	avatarSize
}) => {
	const ref = useRef();
	const noteContentContainer = useRef();

	const { setIsOpen } = useProfileView();

	useOnClickOutside(ref, () => {
		setShowAction(false);
	});

	function customSort(a, b) {
		if (a.avatar && !b.avatar) {
			return -1;
		} else if (!a.avatar && b.avatar) {
			return 1;
		} else {
			return 0;
		}
	}
	const collaborators = [...get(note, "custom_visibility.data", [])].sort(
		customSort
	);
	const fullName =
		get(note, "created_by.first_name") +
		" " +
		get(note, "created_by.last_name");
	const visibility = get(note, "visibility");
	const visible = get(note, "visible");
	const not_visible = get(note, "not_visible");
	const [, setShowAction] = useState(false);
	const [isShown, setIsShown] = useState(false);
	const [showModal, setShowModal] = useState("");
	const user = useSelector(onlineUserSelector);
	const [displaySeeMore, setDisplaySeeMore] = useState(false);
	const [displayVisibilityDetails, setDisplayVisibilityDetails] = useState(
		false
	);
	const { addNote, notes, activeNote, setActiveNote } = useNotesStore(
		({ addNote, notes, activeNote, setActiveNote }) => ({
			addNote,
			notes,
			activeNote,
			setActiveNote
		})
	);

	const noteFormatted = formatNote(note?.description);
	useLayoutEffect(() => {
		if (noteContentContainer.current) {
			setDisplaySeeMore(
				noteContentContainer.current.offsetHeight <
					noteContentContainer.current.scrollHeight
			);
		}
	}, [noteFormatted]);

	const goToProfileFromVacancy = () => {
		const profileId = get(note, "user._id");

		const payload = {
			candidateIds: [profileId],
			vacancyTitle: vacancy.name,
			vacancyId: vacancy._id
		};
		const stringified = JSON.stringify(payload);
		const params = {
			source: VACANCY_VIEW,
			payload: stringified
		};
		const paramsStringified = stringify(params);
		historyPush(`/permanent/view/${profileId}?${paramsStringified}`);
	};

	const redirectToEntity = (e, id) => {
		e.stopPropagation();

		const vacancyId = get(vacancy, "_id", "");

		if (vacancyId) {
			goToProfileFromVacancy();
		} else {
			const link = `/vacancy/${id}`;

			browserHistory.push(link);
			setIsOpen(false);
		}
	};

	const editNote = () => {
		const index = notes.findIndex(
			({ payload }) => payload.note_id === note._id
		);

		if (index === -1) {
			const description = get(note, "description", "");
			//TODO use helper isHTML
			const isDescriptionInHtml = /<\/?[a-z][\s\S]*>/i.test(description);

			let mentions = [];

			if (isDescriptionInHtml) {
				mentions = [
					...(note?.mentioned_users || []).map(item => ({
						_id: item
					})),
					...(note?.mentioned_groups || []).map(item => ({
						_id: item,
						type: GROUP
					}))
				];
			} else {
				//To support old notes in text format, the format of mention is this ?##Maxim Cornet,2678344a-371b-4f40-a748-6f67bdaf25f7##?
				const result = getMentions(description);

				mentions = (result || []).map(mention => {
					const content = mention.replace("?##", "").replace("##?", "");
					const value = content.split(",");
					return { _id: value[1] };
				});
			}

			addNote({
				id: uuid(),
				isEditNote: true,
				canSelectCandidate: get(vacancy, "_id", "") ? false : true,
				type: "QUICK_NOTE",
				payload: {
					vacancy: vacancy
						? vacancy
						: {
								_id: get(note, "job_id", ""),
								name: get(note, "job.title", "")
						  },
					candidate: {
						value: get(note, "user._id", ""),
						label: `${get(note, "user.first_name")} ${get(
							note,
							"user.last_name"
						)}`,
						profile_id: get(note, "user._id", "")
					},
					type: {
						value:
							get(note, "note_type", "") !== null
								? get(note, "note_type", "")
										?.replace(/ /g, "_")
										.toLowerCase()
								: "",
						label: get(note, "note_type", "")
					},
					comment: isDescriptionInHtml
						? description
						: `<p><span>${parseMentions(description)}</span></p>`,
					note_id: get(note, "_id", ""),
					mentions: mentions,
					visibility,
					visible,
					not_visible
				}
			});
		} else {
			const noteId = notes[index].id;

			if (activeNote !== noteId) {
				setActiveNote(noteId);
			}
		}
	};
	const renderVisibility = () => {
		if (visibility === "custom") {
			return (
				<div
					onClick={() =>
						note?.custom_visibility?.count > 0 &&
						setDisplayVisibilityDetails(true)
					}
					style={{ display: "flex" }}
				>
					<div className={noteStyle.collaboratorsContainer}>
						<div className={noteStyle.images}>
							{collaborators?.map((user, i) => {
								return Object.prototype.hasOwnProperty.call(user, "name") ? (
									<div className={noteStyle.tag} key={user._id}>
										<span className={noteStyle.departementIcon}>
											<UserIcon />
										</span>
										<span className={noteStyle.tagName}>{user.name}</span>
									</div>
								) : (
									<div className={noteStyle.avatarWrapper}>
										<CustomAvatar
											src={user.avatar}
											variant="small"
											fullName={`${user.first_name} ${user.last_name}`}
											rootClassName={cx(noteStyle.collaboratorPicture, {
												[noteStyle.firstCollaboratorPicture]: i === 0
											})}
											imgClassName={cx(noteStyle.picture)}
											avatarClassName={cx(
												noteStyle.avatarWithBorder,
												noteStyle.avatar,
												{
													[noteStyle.firstCollaboratorPicture]: i === 0
												}
											)}
											avatarSize={30}
											rootStyle={{
												zIndex: i
											}}
											avatarStyle={{
												zIndex: i
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
					{note?.custom_visibility?.count > 0 && (
						<div className={noteStyle.visibiliytyRest}>
							+{note.custom_visibility.count}
						</div>
					)}
				</div>
			);
		}
		return <p>{VisibilityIcon[visibility]?.icon}</p>;
	};

	return (
		<>
			{showModal === DELETE_MODAL && (
				<Portal>
					<DeleteModal
						onClose={() => setShowModal(false)}
						payload={{
							profile_id: get(note, "user._id", ""),
							note_id: get(note, "_id", ""),
							note_type:
								get(note, "note_type", "") !== null
									? get(note, "note_type", "")
											?.replace(/ /g, "_")
											?.toLowerCase()
									: ""
						}}
					/>
				</Portal>
			)}
			<div className={cx(noteStyle.container, cardStyle.section)}>
				<div
					className={cx(noteStyle.header__card, {
						[noteStyle.noteDrawer]: noteDrawer
					})}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: noteDrawer ? "12px" : "0"
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								marginBottom: noteDrawer ? "0" : ".75rem",
								cursor: "default"
							}}
						>
							<Avatar
								size={avatarSize || 35}
								radius={3}
								src={get(note, "created_by.avatar", "")}
								name={fullName}
							/>

							<div className={noteStyle.section}>
								<div className={noteStyle.info__condidate}>
									<div>
										<span
											className={cx(noteStyle.name__condidate, {
												[noteStyle.noteDrawer]: noteDrawer
											})}
										>{`${get(note, "created_by.first_name", "")} ${get(
											note,
											"created_by.last_name",
											""
										)}`}</span>
									</div>

									{get(vacancy, "_id", "") ? (
										<>
											<div className={noteStyle.dot}>•</div>
											<div
												className={cx(noteStyle.job__condidate, {
													[noteStyle.noteDrawer]: noteDrawer
												})}
												onMouseEnter={() => setIsShown(true)}
												onMouseLeave={() => setIsShown(false)}
												onClick={e => redirectToEntity(e)}
											>
												<>
													<UserIcon
														width={"1rem"}
														height={"1rem"}
														fill="#00857A"
													/>
													<span className={noteStyle.title}>
														{get(note, "user.first_name", "")}{" "}
														{get(note, "user.last_name", "")}
													</span>
												</>
											</div>
										</>
									) : (
										<>
											{get(note, "job.title", "") !== "" && (
												<>
													<div className={noteStyle.dot}>•</div>
													<div
														className={cx(noteStyle.job__condidate, {
															[noteStyle.noteDrawer]: noteDrawer
														})}
														onMouseEnter={() => setIsShown(true)}
														onMouseLeave={() => setIsShown(false)}
														disabled={
															// because sometimes we have _id inside job object and In previous API we still have job_id i didn't want to produce any regression. need to be fixed in backend.
															note &&
															!get(note, "job._id") &&
															!get(note, "job_id")
														}
														onClick={e =>
															redirectToEntity(
																e,
																get(note, "job_id") || get(note, "job._id")
															)
														}
													>
														<>
															<svg
																width="1.125rem"
																height="1.125rem"
																viewBox="0 0 1.125rem 1.125rem"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
																className={noteStyle.briefcase}
															>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M4.8001 4.79998V3.99998C4.8001 2.67449 5.87461 1.59998 7.20009 1.59998H8.80009C10.1256 1.59998 11.2001 2.67449 11.2001 3.99998V4.79998H12.8001C13.6837 4.79998 14.4001 5.51632 14.4001 6.39998V9.25659C12.4078 9.99576 10.252 10.3999 8.00011 10.3999C5.74821 10.3999 3.59239 9.99576 1.6001 9.25657V6.39998C1.6001 5.51632 2.31644 4.79998 3.2001 4.79998H4.8001ZM6.4001 3.99998C6.4001 3.55815 6.75827 3.19998 7.20009 3.19998H8.80009C9.24192 3.19998 9.60009 3.55815 9.60009 3.99998V4.79998H6.4001V3.99998ZM7.20009 7.99998C7.20009 7.55815 7.55827 7.19998 8.00009 7.19998H8.00809C8.44992 7.19998 8.80809 7.55815 8.80809 7.99998C8.80809 8.4418 8.44992 8.79998 8.00809 8.79998H8.00009C7.55827 8.79998 7.20009 8.4418 7.20009 7.99998Z"
																	fill={isShown ? "#006a62" : "#00857A"}
																/>
																<path
																	d="M1.6001 10.9538V12.8C1.6001 13.6836 2.31644 14.4 3.2001 14.4H12.8001C13.6837 14.4 14.4001 13.6836 14.4001 12.8V10.9538C12.3899 11.6323 10.2372 11.9999 8.00011 11.9999C5.763 11.9999 3.61033 11.6323 1.6001 10.9538Z"
																	fill={isShown ? "#006a62" : "#00857A"}
																/>
															</svg>

															<span
																className={cx(noteStyle.title, {
																	[noteStyle.noteDrawer]: noteDrawer
																})}
															>
																{`${get(note, "job.job_ref", "")}  ${get(
																	note,
																	"job.title",
																	""
																)}`}
															</span>
														</>
													</div>
												</>
											)}
										</>
									)}
								</div>
								<div className={noteStyle.phone_condidate}>
									{get(note, "note_type") && (
										<>
											{" "}
											<div
												className={cx(noteStyle.phone, {
													[noteStyle.noteDrawer]: noteDrawer
												})}
											>
												{get(note, "note_type", "")}
											</div>
											<div className={noteStyle.dot}>•</div>
										</>
									)}
									<div
										className={cx(noteStyle.date, {
											[noteStyle.noteDrawer]: noteDrawer
										})}
									>
										{moment(get(note, "created_at", "") * 1000).format(
											"DD/MM/YYYY, HH:mm"
										)}
									</div>
									{note?.visibility && (
										<>
											<div className={noteStyle.dot}>•</div>{" "}
											{VisibilityIcon[visibility]?.tooltip === "custom" ? (
												renderVisibility()
											) : (
												<Tooltip
													placement="top"
													trigger={["hover"]}
													content={VisibilityIcon[visibility]?.tooltip}
													theme="dark"
													className={noteStyle.tooltip}
												>
													{renderVisibility()}
												</Tooltip>
											)}
										</>
									)}
								</div>
							</div>
						</div>

						{user.id === get(note, "created_by._id") && (
							<div
								ref={ref}
								className={cx(noteStyle.noteActions, {
									[noteStyle.noteDrawer]: noteDrawer
								})}
							>
								<EditIcon
									className={noteStyle.trashIcon}
									onClick={() => editNote()}
								/>
								<DeleteIcon
									className={noteStyle.trashIcon}
									onClick={() => setShowModal(DELETE_MODAL)}
								/>
							</div>
						)}
					</div>
					{displayVisibilityDetails && (
						<NoteCustomVisibilityListTags
							visible={get(note, "visibility_details.visible", {})}
							not_visible={get(note, "visibility_details.not_visible", {})}
							onClose={() => setDisplayVisibilityDetails(false)}
						/>
					)}

					<div
						className={cx(noteStyle.note__section, {
							[noteStyle.noteDrawer]: noteDrawer
						})}
						id="note_section"
					>
						{showText ? (
							<Scrollbars
								autoHide
								autoHideTimeout={5000}
								autoHideDuration={200}
								autoHeight
								autoHeightMax={noteContainerMaxHeight || 765}
								autoHeightMin={0}
							>
								<LexicalContentView description={noteFormatted} />
							</Scrollbars>
						) : (
							<>
								<div className={noteStyle.noteBody}>
									<LexicalContentView
										ref={noteContentContainer}
										description={noteFormatted}
									/>
								</div>
								{displaySeeMore && (
									<button
										className={noteStyle.view__note__btn}
										onClick={() => onClick(get(note, "_id", ""))}
									>
										See more
									</button>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
Note.prototype = {
	fullName: PropTypes.string.isRequired,
	createdAt: PropTypes.number.isRequired,
	linkedTo: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired
};
export default Note;
