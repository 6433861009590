import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

const getMentions = () => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "list_mentioned_users_and_groups"
		}
	});
};

const useGetMentions = options =>
	useQuery(["getMentions", options], () => getMentions(options), {
		refetchOnWindowFocus: false,
		retry: 0
	});

export default useGetMentions;
