import React from "react";
import styles from "./members-list.module.scss";
import { Controller } from "react-hook-form";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import { CustomAvatar } from "common/CustomAvatar";
import { toArray } from "lodash";
import cx from "classnames";
import { COLLABORATOR_GROUP_PERMISSIONS } from "modules/calendar-settings/components/collaborative-groups-page/collaborators-list/utils/constant";
import Tooltip from "common/Tippy";

const MembersList = ({ control, fields }) => {
	const permissionsList = toArray(COLLABORATOR_GROUP_PERMISSIONS);
	const onPermissionSelectChange = (key, onChange) => {
		onChange(permissionsList.filter(el => el.value === key)[0]);
	};

	return (
		<table className={styles.container}>
			<tbody>
				{fields.map((element, index) => {
					return (
						<tr className={styles.row} key={element.id}>
							<td>
								<div className={styles.colUser}>
									<div className={styles.collaborator}>
										<CustomAvatar
											src={element.avatar}
											fullName={`${element.firstName} ${element.lastName}`}
											avatarClassName={styles.avatarImg}
											imgClassName={styles.avatarImg}
										/>
										<div className={styles.info}>
											<Tooltip
												content={`${element.firstName} ${element.lastName}`}
												theme="dark"
												overflow="hidden"
											>
												<div
													className={styles.name}
												>{`${element.firstName} ${element.lastName}`}</div>
											</Tooltip>
											<Tooltip
												content={element.headline}
												theme="dark"
												overflow="hidden"
											>
												<div className={styles.headline}>
													{element.headline}
												</div>
											</Tooltip>
										</div>
									</div>
								</div>
							</td>

							<td>
								<div className={styles.colPermission}>
									<Controller
										name={`collaborators.${index}.permission`}
										control={control}
										render={({ field: { value, onChange } }) => {
											return (
												<ComboBox
													isReadonlyInput={true}
													inputValue={value?.label}
													selectedKey={value?.value}
													inputRootClassName={cx(
														styles.select,
														styles.permissionSelect
													)}
													onSelectionChange={key =>
														onPermissionSelectChange(key, onChange)
													}
												>
													{permissionsList.map(({ value, label }) => {
														return <Item key={value}>{label}</Item>;
													})}
												</ComboBox>
											);
										}}
									/>
								</div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default MembersList;
