import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-plus-2.svg";
import { ReactComponent as Chevron } from "static/icons/chevron-left.svg";
import { ReactComponent as FilterIcon } from "static/icons/filter-lines.svg";
import cx from "classnames";
import { Button } from "common/Button";
import styles from "./calendar-header.module.scss";
import {
	CALENDAR_VIEWS,
	SCHEDULE_MENU,
	SYNC_STATUSES
} from "modules/calendar/utils/constants";
import { Link } from "react-router";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { ReactComponent as HourGlassIcon } from "static/icons/hourglass-02.svg";
import useCloseSyncEmailBanner from "modules/MessagingTool/api/useCloseSyncEmailBanner";
import { useDispatch } from "react-redux";
import { getMyProfile } from "modules/user/actions/authActions";
import { useGetUser } from "hooks/useGetUser";
import { renderError, timestampToDateCalendar } from "config/helpers";
import toArray from "lodash/toArray";
import { TOUR_CALENDAR } from "config/onboarding";
import { useCalendarTour } from "modules/user-onboarding/workflows/calendar";
import useCalendarStore from "modules/calendar/hooks/useCalendarStore";
import ToggleButton from "common/ToggleButton";
import PopoverTrigger, {
	POPOVER_VARIANTS
} from "common/popover-trigger/popover-trigger";
import { useOverlayTriggerState } from "react-stately";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import { ADD_INTERVIEW } from "config";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { getMyProfileRoute } from "modules/company/components/MyProfile";
import useGetAuthenticationDetails from "modules/company/components/MyProfile/api/useGetAuthenticationDetails";
import get from "lodash/get";

const CalendarHeader = ({ calendarRef }) => {
	const toggleWeekendRef = useRef();
	const state = useOverlayTriggerState({});
	const { workflowId } = useOnboardingStore();
	const toggleWeekendState = useOverlayTriggerState({});
	const dispatch = useDispatch();
	const user = useGetUser();
	const currentCalendarView = useCalendarStore(
		state => state.currentCalendarView
	);
	const setCurrentCalendarView = useCalendarStore(
		state => state.setCurrentCalendarView
	);
	const toggleWeekends = useCalendarStore(state => state.toggleWeekends);
	const setToggleWeekends = useCalendarStore(state => state.setToggleWeekends);
	const setActiveDrawer = useCalendarStore(state => state.setActiveDrawer);
	const setActiveDay = useCalendarStore(state => state.setActiveDay);
	const focusedDate = useCalendarStore(state => state.focusedDate);

	const [showBanner, setShowBanner] = useState(true);
	const [focusedDateTitle, setFocusedDateTitle] = useState("");
	const [syncClosed, setSyncClosed] = useState(
		workflowId ? false : user.sync_email_banner_closed
	);

	useEffect(() => {
		setFocusedDateTitle(
			get(calendarRef?.current, "calendar.currentData.viewTitle", "")
		);
	}, [focusedDate]);

	const { data: authenticationDetails } = useGetAuthenticationDetails();
	const setDrawerState = useVacancyStore(state => state.setDrawerState);
	const [mutate] = useCloseSyncEmailBanner();

	const onClickScheduleOption = key => {
		if (key === SCHEDULE_MENU.interview.key) {
			setDrawerState({
				open: true,
				component: ADD_INTERVIEW
			});
		} else {
			setActiveDrawer(key);
		}
	};

	useCalendarTour({
		onClickScheduleOption: () => state.open(),
		onCreateEvent: () => setActiveDrawer(SCHEDULE_MENU.default.key),
		onClose: () => setActiveDrawer(null),
		onCreateInterview: () => {
			setActiveDrawer(null);
			setDrawerState({
				open: true,
				component: ADD_INTERVIEW
			});
		}
	});

	const closeBanner = () => {
		mutate(
			{
				type: "user_settings_options",
				user_options: {
					sync_email_banner_closed: true
				}
			},
			{
				onSuccess: () => {
					dispatch(getMyProfile()).then(data => {
						setSyncClosed(data.sync_email_banner_closed);
					});
				},
				onError: renderError
			}
		);
	};

	const setCalendarView = view => {
		calendarRef?.current?.getApi().changeView(view);
		setCurrentCalendarView(view);
	};

	const renderBanner = status => {
		switch (status) {
			case SYNC_STATUSES.in_progress:
				return (
					showBanner && (
						<div className={cx(styles.banner, styles.inProgress)}>
							<HourGlassIcon />
							Your calendar is currently syncing. This may take a few moments...{" "}
							<button
								className={styles.bannerCloseIcon}
								onClick={() => setShowBanner(false)}
							>
								<CloseIcon />
							</button>
						</div>
					)
				);
			case SYNC_STATUSES.failed:
				return (
					showBanner && (
						<div className={cx(styles.banner, styles.failed)}>
							⚠️ Error: Unable to sync calendar. Please retry.
							<Link to={getMyProfileRoute()} className={styles.syncButton}>
								Sync My Calendar
							</Link>
							<button
								className={styles.bannerCloseIcon}
								onClick={() => setShowBanner(false)}
							>
								<CloseIcon />
							</button>
						</div>
					)
				);
			default:
				return workflowId ||
					(!syncClosed &&
						![
							SYNC_STATUSES.failed,
							SYNC_STATUSES.in_progress,
							SYNC_STATUSES.finished
						].includes(status)) ? (
					<div className={styles.banner}>
						🔄 Sync your Gmail or Outlook to get all your events in one calendar
						<Link
							to={getMyProfileRoute()}
							className={styles.syncButton}
							data-onboarding-step={`${TOUR_CALENDAR}-1`}
						>
							Sync My Calendar
						</Link>
						<button className={styles.bannerCloseIcon} onClick={closeBanner}>
							<CloseIcon />
						</button>
					</div>
				) : null;
		}
	};

	const onClickToday = () => {
		calendarRef?.current?.getApi().today();
		const timezone = calendarRef.current.getApi().view.dateEnv.timeZone;
		const todayAsTimestamp = window.moment.tz(timezone).unix();
		setActiveDay(timestampToDateCalendar(todayAsTimestamp, timezone));
	};

	return (
		<div className={styles.headerContainer}>
			{renderBanner(
				workflowId
					? null
					: get(
							authenticationDetails,
							"authentication_details.sync_calendar_event_status"
					  )
			)}
			<div className={styles.section}>
				<div className={styles.left}>
					<h3 className={styles.title}>Calendar</h3>
					<div className={styles.schedule}>
						<PopoverTrigger
							state={state}
							hideArrow
							variant={POPOVER_VARIANTS.parent_width}
							trigger={
								<Button
									text="Schedule"
									icon={<CalendarIcon />}
									variant="outlined"
									className={styles.scheduleButton}
									data-onboarding-step={`${TOUR_CALENDAR}-3`}
								/>
							}
						>
							<div>
								{toArray(SCHEDULE_MENU).map(opt => {
									return (
										<button
											key={opt.key}
											className={styles.scheduleMenuItem}
											onClick={() => {
												onClickScheduleOption(opt.key);
												state.close();
											}}
											data-onboarding-step={opt.tour_key}
										>
											{opt.icon}
											<span className={styles.scheduleMenuItemText}>
												{opt.label}
											</span>
										</button>
									);
								})}
							</div>
						</PopoverTrigger>
					</div>
				</div>
				<div className={styles.right}>
					<div>
						<span className={styles.calendarTitle}>{focusedDateTitle}</span>
					</div>
					<div className={styles.calendarSubHeader}>
						<Button
							text="Today"
							onClick={onClickToday}
							variant="outlined"
							className={styles.today}
						/>

						<div className={styles.views}>
							<button
								className={cx(styles.viewButton, {
									[styles.active]: currentCalendarView === CALENDAR_VIEWS.day
								})}
								onClick={() => setCalendarView(CALENDAR_VIEWS.day)}
							>
								Day
							</button>
							<button
								className={cx(styles.viewButton, {
									[styles.active]: currentCalendarView === CALENDAR_VIEWS.week
								})}
								onClick={() => setCalendarView(CALENDAR_VIEWS.week)}
							>
								Week
							</button>
							<button
								className={cx(styles.viewButton, {
									[styles.active]: currentCalendarView === CALENDAR_VIEWS.month
								})}
								onClick={() => setCalendarView(CALENDAR_VIEWS.month)}
								data-onboarding-step={`${TOUR_CALENDAR}-7`}
							>
								Month
							</button>
						</div>
						<PopoverTrigger
							state={toggleWeekendState}
							placement="bottom right"
							hideArrow
							trigger={
								<button
									className={cx(styles.filter, styles.highlightButton)}
									ref={toggleWeekendRef}
								>
									<FilterIcon
										className={cx(styles.filterIcon, styles.highlightIcon)}
									/>
								</button>
							}
						>
							<div className={styles.toggleWeekends}>
								<ToggleButton
									checked={toggleWeekends}
									onChange={e => {
										setToggleWeekends(e.target.checked);
									}}
									name={"toggle-weekends"}
									className={styles.toggle}
								/>
								Show weekends
							</div>
						</PopoverTrigger>
						<div className={styles.navigation}>
							<button
								data-id="prev"
								className={cx(styles.navigationButton, styles.highlightButton)}
								onClick={() => calendarRef?.current?.getApi().prev()}
							>
								<Chevron className={styles.highlightIcon} />
							</button>
							<button
								data-id="next"
								className={cx(
									styles.navigationButton,
									styles.next,
									styles.highlightButton
								)}
								onClick={() => calendarRef?.current?.getApi().next()}
							>
								<Chevron
									className={cx(styles.iconNext, styles.highlightIcon)}
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CalendarHeader;
