import React, { useState, useRef, useMemo } from "react";
import _debounce from "lodash/debounce";
import get from "lodash/get";
import FilterBox from "common/FilterBox";
import Table from "modules/candidate/Profile/components/Table/Table.js";
import SkeletonComponent from "../../components/Skeleton";
import useGetListFiles from "../../api/useListCandidateFiles";
import { filterList, sortBylist, columns } from "./filterHelpers";
import { formatDate } from "common/Functions";
import useFilesStore from "../../Store/useFilesStore";
import classnames from "./files.module.scss";
import avatar from "static/images/Avatar-default.png";
import Visibility from "../Files/components/Visibility";
import Actions from "./components/Actions";
import s from "./files.module.scss";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import { UPLOAD_CANDIDATE_FILES } from "config";
import { ReactComponent as CloudUpload } from "static/icons/cloud-upload.svg";
import useDeleteCandidateFile from "../../api/useDeleteCandidateFile";
import { getListCandidateFilesKey } from "modules/candidate/Profile/api/useListCandidateFiles.js";
import { queryCache } from "react-query";
import toaster from "common/Toaster";
import DisplayResults from "modules/Tasks/components/pagination/DisplayResults.jsx";
import PaginationClient from "modules/Tasks/components/pagination";
import GlobalTooltip from "common/GlobalTooltip";
import { FILES } from "modules/home/config";
import Avatar from "common/Avatar";
import useUpdateFilePrimary from "modules/candidate/Profile/api/useSetPrimary";
import { editHistoryKey } from "modules/candidate/Profile/api/useEditHistory";
import { searchParser } from "config/helpers";
import { viewOverviewKey } from "../../api/useOverview";
import { useGetUser } from "hooks/useGetUser";

export const dataSkeleton = new Array(9).fill({
	file_name: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	file_type: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	created_at: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	created_by: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	allowed_users: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	is_primary: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	action: <SkeletonComponent width={60} height={14} borderRadius={10} />
});

const RenderText = ({ text, maxSize }) =>
	get(text, "length", 0) > maxSize ? (
		<GlobalTooltip
			overlayClassName={classnames.tooltip}
			placement="top"
			trigger="hover"
			overlay={text}
		>
			<span className={classnames.title}>{text.slice(0, maxSize) + "..."}</span>
		</GlobalTooltip>
	) : (
		<span className={classnames.title}>{text}</span>
	);

const RenderAvatar = ({ src = avatar, name }) => (
	<GlobalTooltip placement="top" trigger="hover" overlay={name}>
		<Avatar className={classnames.avatar} avatar={src} name={name} size={32} />
	</GlobalTooltip>
);

const Files = ({ candidateId, cvId, hasInternal }) => {
	const [searchKeyword, setSearchKeyword] = useState("");

	const {
		filters,
		updateSearch,
		updateOffset,
		setLimit,
		limit
	} = useFilesStore();

	const { setDrawerState } = useVacancyStore();

	const { field, offset } = filters;

	const { tab } = searchParser();
	const enabled = tab === FILES;

	const { resolvedData, isFetching, isLoading } = useGetListFiles(
		{
			profile_id: candidateId,
			...filters,
			limit
		},
		{
			enabled
		}
	);

	const debouncedChangeFilterAction = useRef(_debounce(updateSearch, 500));
	const handelSearchChange = value => {
		setSearchKeyword(value);
		debouncedChangeFilterAction.current(value);
		updateOffset(0);
	};

	const [mutate] = useUpdateFilePrimary({
		onSuccess: () => {
			queryCache.invalidateQueries("@Profile/getListCandidateFiles");
			queryCache.invalidateQueries("@candidate/viewCandidate");
			queryCache.invalidateQueries(editHistoryKey);
			queryCache.invalidateQueries(viewOverviewKey);
		},
		onError: () => {
			toaster.danger("Error while saving changes.");
		}
	});

	const [deleteFile, { isLoading: isDeletingFile }] = useDeleteCandidateFile({
		onSuccess: () => {
			toaster.success("File has successfully been deleted");
			queryCache.invalidateQueries(getListCandidateFilesKey);
		}
	});

	const handleDelete = file_id => deleteFile({ file_id });

	const handleSetPrimaryRadioButtonClick = (fileId, cvId) => {
		mutate({
			cv_id: cvId,
			entity: "files",
			file_id: fileId
		});
	};

	const total = get(resolvedData, "pagination.total", []);
	const size = get(resolvedData, "pagination.size", []);
	const currentUser = useGetUser();

	const data = useMemo(
		() =>
			isLoading || isDeletingFile
				? dataSkeleton
				: get(resolvedData, "data", []).map(item => {
						const canFileBePrimary = item.file_type === "CV";

						return {
							file_name: <RenderText text={item.file_name} maxSize={30} />,
							file_type: <RenderText text={item.file_type} maxSize={30} />,
							created_at: formatDate(item.created_at),
							created_by: (
								<RenderAvatar
									src={item.created_by.avatar || null}
									name={`${item.created_by.first_name} ${item.created_by.last_name}`}
								/>
							),
							allowed_users: (
								<Visibility
									allowedUsers={get(item, "allowed_users", [])}
									fileId={item._id}
									disabledUsers={[item.created_by._id]}
									canChangeVisibility={item.created_by._id === currentUser.id}
								/>
							),
							is_primary: canFileBePrimary && (
								<span className={classnames.radio_input}>
									<input
										type="radio"
										className="styled"
										id={"poked"}
										name={"poked"}
										checked={item.is_primary}
										onClick={() =>
											!item.is_primary &&
											handleSetPrimaryRadioButtonClick(item._id, cvId)
										}
									/>
									<span className={classnames.radio_control}></span>
								</span>
							),
							action: (
								<Actions
									handleDelete={() => handleDelete(item._id)}
									file={item}
									creator={item.created_by}
									isDeletingFile={isDeletingFile}
									cvId={cvId}
								/>
							)
						};
				  }),
		[isFetching, isDeletingFile, resolvedData]
	);

	const handleOpenUploader = () =>
		setDrawerState({
			open: true,
			component: UPLOAD_CANDIDATE_FILES,
			candidateId
		});

	return (
		<div className={s.container}>
			<div className={s.uploadSection}>
				<span>All files related to candidate</span>
				<button onClick={handleOpenUploader}>
					<CloudUpload />
					<span>Upload new file</span>
				</button>
			</div>
			<div className={s.filtersContainer}>
				<FilterBox
					searchKeyword={searchKeyword}
					setSearchKeyword={handelSearchChange}
					filterList={filterList(candidateId)}
					sortBylist={sortBylist}
					store={useFilesStore}
					hideParams={true}
				/>
			</div>
			<div className={""}>
				<Table
					data={data}
					isFetching={isFetching}
					fetchedData={get(resolvedData, "tasks", [])}
					columns={columns(field, hasInternal)}
					className="files"
				/>
			</div>
			<div className={s.paginationContainer}>
				<DisplayResults
					resolvedData={resolvedData}
					isFetching={isLoading}
					setLimit={setLimit}
					limit={limit}
					offset={offset}
				/>
				<PaginationClient
					size={size}
					offset={offset}
					setOffset={updateOffset}
					total={total}
				/>
			</div>
		</div>
	);
};

export default Files;
