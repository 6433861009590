import { GENERATE_INTERVIEW_BEST_SLOTS_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";

const findBestTimes = body => {
	return client(GENERATE_INTERVIEW_BEST_SLOTS_ENDPOINT, { body });
};

const FIND_BEST_TIME_KEY = "FIND_BEST_TIME_KEY";

const useFindBestTimes = (body, options) => {
	return useQuery([FIND_BEST_TIME_KEY, body], () => findBestTimes(body), {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
};

export default useFindBestTimes;
