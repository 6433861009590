import { GET_INVITATION_INFO_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";

const fetchData = body => {
	return client(GET_INVITATION_INFO_ENDPOINT, { body });
};

const CALENDAR_SETTINGS = "CALENDAR_SETTINGS";

const useFetchInvitationDetails = (body, options) => {
	return useQuery([CALENDAR_SETTINGS, body], () => fetchData(body), {
		refetchOnWindowFocus: false,
		retry: false,
		...options
	});
};

export default useFetchInvitationDetails;
