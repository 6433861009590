import { Button } from "common/Button";
import PropTypes from "prop-types";
import * as React from "react";
import { Overlay, useOverlayTrigger, usePopover } from "react-aria";
import styles from "./popover.module.scss";
import cx from "classnames";

function Popover({
	children,
	state,
	offset = 8,
	popoverClassName,
	position, // Helps us to override default position of usePopover Hook
	arrowClassName,
	hideArrow,
	...props
}) {
	let popoverRef = React.useRef(null);
	let {
		popoverProps: popoverState,
		underlayProps,
		arrowProps,
		placement
	} = usePopover(
		{
			...props,
			shouldUpdatePosition: !position,
			offset,
			popoverRef
		},
		state
	);

	const popoverProps = {
		...popoverState,
		style: {
			...popoverState.style,
			...position
		}
	};

	return (
		<Overlay>
			<div {...underlayProps} className={styles.underlay} />
			<div
				{...popoverProps}
				ref={popoverRef}
				className={cx(styles.popover, popoverClassName)}
			>
				{!hideArrow && (
					<svg
						{...arrowProps}
						className={cx(styles.arrow, arrowClassName)}
						data-placement={placement}
						viewBox="0 0 12 12"
					>
						<path d="M0 0 L6 6 L12 0" />
					</svg>
				)}
				{children}
			</div>
		</Overlay>
	);
}

function PopoverTrigger({
	label,
	popoverClassName,
	arrowClassName,
	children,
	trigger,
	hideArrow = false,
	state,
	position,
	...props
}) {
	let ref = React.useRef(null);

	let { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		state,
		ref
	);

	return (
		<>
			{trigger ? (
				React.cloneElement(trigger, {
					...triggerProps,
					onClick: e => {
						triggerProps.onPress(e);
						trigger.props.onClick?.();
					},
					ref: ref
				})
			) : (
				<Button
					{...triggerProps}
					text={label}
					ref={ref}
					onClick={triggerProps.onPress}
				/>
			)}
			{state.isOpen && (
				<Popover
					{...props}
					position={position}
					popoverClassName={popoverClassName}
					arrowClassName={arrowClassName}
					hideArrow={hideArrow}
					triggerRef={ref}
					state={state}
				>
					{React.cloneElement(children, overlayProps)}
				</Popover>
			)}
		</>
	);
}

PopoverTrigger.prototype = {
	position: PropTypes.shape({
		top: PropTypes.number,
		bottom: PropTypes.number,
		left: PropTypes.number,
		right: PropTypes.number
	}),
	label: PropTypes.string,
	popoverClassName: PropTypes.string,
	arrowClassName: PropTypes.string,
	hideArrow: PropTypes.bool
};
export default PopoverTrigger;
