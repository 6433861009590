import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, formValueSelector, initialize } from "redux-form";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { OutlineButton } from "common/styled/buttons";
import {
	FloatedBtn,
	RadioBtnResult,
	BtnContainer,
	LocationContainer
} from "./styled";

import {
	IN_DEF_START_TIME,
	IN_DEF_END_TIME,
	SEARCH_ADDRESS,
	COMPANY_ADDRESS,
	VIDEO_CONFERENCE,
	TEXTAREA_MAX_LENGTH,
	PHONE_SCREEN
} from "config";
import TimeChooser from "common/TimeChooser";
import {
	formatDateUsingTimezone,
	isAddressComplete,
	isEmpty
} from "common/Functions";
import {
	convertDateAndTimeUsingTimezone,
	handleAddressObject
} from "config/helpers";

class ProposeInterview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startTime: IN_DEF_START_TIME,
			endTime: IN_DEF_END_TIME,
			valid: true,
			error: false,
			address: {},
			addressTypo: false,
			isCalendarChanged: false
		};
	}

	componentDidMount() {
		const { interview } = this.props;
		this.initializeReduxForm({
			location_type:
				interview && interview.location_type
					? interview.location_type
					: VIDEO_CONFERENCE
		});
		this.setState({
			address: {
				street: interview && interview.street,
				number: interview && interview.number,
				box: interview && interview.box,
				city: interview && interview.city,
				zip: interview && interview.zip,
				country: interview && interview.country,
				longitude: interview && interview.longitude,
				latitude: interview && interview.latitude
			}
		});
	}

	initializeReduxForm = data => {
		this.props.initializeReduxForm({
			...this.props.formFields,
			...data
		});
	};

	setAddress = address => {
		const newAddress = {
			street: address.street || "",
			country: address.country || "",
			zip: address.zip || "",
			city: address.city || "",
			number: address.number || "",
			box: address.box || "",
			latitude: address.latitude || "",
			longitude: address.longitude || "",
			formatted_address: address.formatted_address || ""
		};
		if (
			newAddress.country !== _get(this.state, "address.country") ||
			newAddress.city !== _get(this.state, "address.city")
		) {
			if (
				newAddress.longitude === _get(this.state, "address.city") ||
				newAddress.latitude === _get(this.state, "address.latitude")
			) {
				newAddress.latitude = 0;
				newAddress.longitude = 0;
			}
		}
		this.setState({ address: newAddress }, () => {
			this.state.error && this.setState({ error: !this.isAddressInvalid() });
		});
	};

	handlerStartTime = (value, valid) => {
		this.setState({ startTime: value, valid });
	};

	handlerEndTime = (value, valid) => {
		this.setState({ endTime: value, valid });
	};

	changeCompanyType = (event, value) => {
		const { interview } = this.props;
		if (value === SEARCH_ADDRESS) {
			this.setAddress({
				street: interview.street,
				number: interview.number,
				box: interview.box,
				city: interview.city,
				zip: interview.zip,
				country: interview.country,
				longitude: interview.longitude,
				formatted_address: interview.formatted_address,
				latitude: interview.latitude
			});
		}
		if (value === VIDEO_CONFERENCE) {
			this.setAddress({});
		}
	};

	getAddress = () => {
		const { interview } = this.props;
		return `${interview ? interview.street : ""} ${
			interview ? interview.number : ""
		} ${interview ? interview.street : ""} ${interview ? interview.zip : ""} ${
			interview ? interview.city : ""
		} ${interview ? interview.country : ""}`;
	};

	getAddressObject = () => {
		const { interview } = this.props;
		return {
			street: interview && interview.street,
			number: interview && interview.number,
			box: interview && interview.box,
			city: interview && interview.city,
			zip: interview && interview.zip,
			country: interview && interview.country,
			longitude: interview && interview.longitude,
			latitude: interview && interview.latitude
		};
	};

	formSubmitted = async formProps => {
		const { submitInterviewForm, bid_id, user } = this.props;
		const { endTime, startTime, valid, isCalendarChanged } = this.state;
		if (!valid) return;
		let payload = {
			...formProps,
			bid_id: bid_id
		};
		const year = formProps.start_date.get("year");
		const month = formProps.start_date.get("month");
		const day = formProps.start_date.get("date");
		formatDateUsingTimezone;

		const [startTimeHour, startTimeMinute] = startTime.split(":");
		const interview_start_date = convertDateAndTimeUsingTimezone(
			{
				year,
				month: month + 1,
				day,
				hour: startTimeHour,
				minute: startTimeMinute
			},
			user.timezone
		)
			.utc()
			.unix();

		const [endTimeHour, endTimeMinute] = endTime.split(":");
		const interview_end_date = convertDateAndTimeUsingTimezone(
			{ year, month: month + 1, day, hour: endTimeHour, minute: endTimeMinute },
			user.timezone
		)
			.utc()
			.unix();
		payload.new_interview_start_date = interview_start_date;
		payload.new_interview_end_date = interview_end_date;

		if (formProps.location_type === SEARCH_ADDRESS) {
			if (this.isAddressInvalid()) {
				return this.setState({ error: true });
			}
		}

		if (
			formProps.location_type !== VIDEO_CONFERENCE &&
			formProps.location_type !== PHONE_SCREEN
		) {
			const finalAddress = await handleAddressObject(this.state.address);

			if (finalAddress.addressTypo) {
				this.setState({
					error: true,
					addressTypo: true
				});
				return this.setState({
					error: false,
					addressTypo: false
				});
			}
			this.setState({
				...this.state,
				...finalAddress
			});
			payload = {
				...payload,
				...this.state.address
			};
		}
		delete payload.start_date;
		submitInterviewForm({ ...payload, isCalendarChanged });
		/* interviewDispatch(payload, is_decline, is_edit).then(() => {
			this.closeModal();
		}); */
	};

	isAddressInvalid = () => {
		const { address } = this.state;
		return (
			!_get(address, "street") ||
			!_get(address, "number") ||
			!_get(address, "city") ||
			!_get(address, "country") ||
			!_get(address, "zip")
		);
	};

	handleCalendarChanged = isChanged => {
		this.setState({ isCalendarChanged: isChanged });
	};

	render() {
		const { handleSubmit, formFields, handleBackStep, interview } = this.props;
		const { new_interview_end_date, new_interview_start_date } =
			interview || {};
		const { isCalendarChanged } = this.state;
		return (
			<div className="content">
				<div className="interview_content">
					<TimeChooser
						handleCalendarChanged={this.handleCalendarChanged}
						isProposeModal={true}
						handlerStartTime={this.handlerStartTime}
						handlerEndTime={this.handlerEndTime}
						label="Interview Date"
						dateClass="col-md-5"
						timeStartClass="col-md-3"
						timeEndClass="col-md-3"
						initialStartTime={new_interview_start_date}
						initialEndTime={new_interview_end_date}
					/>

					{formFields.location_type !== PHONE_SCREEN && (
						<LocationContainer>
							<div
								className={
									formFields.location_type === VIDEO_CONFERENCE
										? "col-md-11 location-border fixed-height"
										: "col-md-11 location-border"
								}
							>
								<div className="radio-group location-selector">
									<label htmlFor="location_type">Location</label>
								</div>

								{formFields.location_type === COMPANY_ADDRESS &&
									!isAddressComplete(this.getAddressObject()) && (
										<div key="incomplete" className="msg error-msg">
											<div className="msg-content">
												{isEmpty(this.getAddressObject())
													? "No company address has been provided for this account yet. "
													: "Your provided company address is not complete. "}
												To be able to use this option, please ask your account’s
												Super-Admin to fill the missing details in{" "}
												{/* <Link to={getCompanyRoute()}>
												SETTINGS → COMPANY → EDIT ADDRESS{" "}
											</Link> */}
											</div>
										</div>
									)}

								<RadioBtnResult>
									{formFields && formFields.location_type === SEARCH_ADDRESS && (
										<div className="form-group inner-icon left-icon">
											<div className="input-box">
												<div className="adress-var">
													{_get(interview, "street")}
												</div>
												<div className="adress-var">
													<span>{_get(interview, "zip")}</span>
													<span>{_get(interview, "city")}</span>
												</div>
												<div className="adress-var">
													{_get(interview, "country")}
												</div>
											</div>
										</div>
									)}

									{formFields.location_type === VIDEO_CONFERENCE && (
										<div className="video-conf">
											{/* 										<img src={videoIcon} alt="camera" className="camera" />
											 */}{" "}
											<div>
												<div className="text">Video Conference</div>
												<div>Virtual Meeting Room</div>
											</div>
										</div>
									)}
									{formFields.location_type === COMPANY_ADDRESS && (
										<div className="form-group inner-icon left-icon">
											<div className="input-box">
												<div className="adress-var">
													{_get(interview, "street")}
												</div>
												<div className="adress-var">
													<span>{_get(interview, "zip")}</span>{" "}
													<span>{_get(interview, "city")}</span>
												</div>
												<div className="adress-var">
													{_get(interview, "country")}
												</div>
											</div>
										</div>
									)}
								</RadioBtnResult>
							</div>
						</LocationContainer>
					)}
					<BtnContainer>
						<FloatedBtn
							type="primary"
							bordered
							onClick={handleSubmit(this.formSubmitted)}
							disabled={!isCalendarChanged}
							style={{ width: "auto" }}
						>
							Propose Another Date
						</FloatedBtn>
						<OutlineButton onClick={() => handleBackStep(false)}>
							Close
						</OutlineButton>
					</BtnContainer>
				</div>
			</div>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (
		formProps.description &&
		formProps.description.length > TEXTAREA_MAX_LENGTH
	) {
		errors.description = `This field should not exceed ${TEXTAREA_MAX_LENGTH} characters`;
	}

	if (!formProps.description || formProps.description.trim() === "") {
		errors.description = "Description can't be empty.";
	}

	return errors;
};

const selector = formValueSelector("ProposeInterview");

const mapStateToProps = state => {
	return {
		formFields: selector(state, "start_date", "location_type", "description")
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initializeReduxForm: data => dispatch(initialize("ProposeInterview", data))
	};
};

ProposeInterview.propTypes = {
	interviewDispatch: PropTypes.func.isRequired,
	profile: PropTypes.string.isRequired,
	bid_id: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	toggleInterviewModal: PropTypes.func.isRequired,
	is_edit: PropTypes.bool.isRequired,
	is_decline: PropTypes.bool.isRequired
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "ProposeInterview",
		validate,
		touchOnBlur: false
	})
)(ProposeInterview);
