import Drawer from "rc-drawer";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { Button } from "common/Button";
import cx from "classnames";
import styles from "./reject-application-drawer.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { Controller, FormProvider, useForm } from "react-hook-form";
import LeaveHook from "common/LeaveHook";
import MessagingToolNewEmailBody from "common/MessagingToolNewEmail/components/MessagingToolNewEmailBody/MessagingToolNewEmailBody";
import { SEND_VACANCY_EMAIL } from "../../../../../../SearchCandidate/components/SendVacancy/utils/constant";
import MesssagingToolNewEmailWarnings from "common/MessagingToolNewEmail/components/MesssagingToolNewEmailWarnings/MesssagingToolNewEmailWarnings";
import { generateId, renderError } from "config/helpers";
import MessagingToolNewEmailTemplates from "common/MessagingToolNewEmail/components/MessagingToolNewEmailTemplates/MessagingToolNewEmailTemplates";
import { useGetEmailSignature } from "common/MessagingToolNewEmail/api/useGetEmailSignature";
import { useGetEmailTemplate } from "common/MessagingToolNewEmail/api/useGetEmailTemplate";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import useGetCandidateRejection from "hooks/useCandidateRejection";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore/index.js";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import TextArea from "common/text-area";
import usePokeData from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";
import _get from "lodash/get";
import { useRejectApplication } from "./api/useRejectApplication";
import toaster from "common/Toaster";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FieldError from "common/MessagingToolNewEmail/components/FieldError";
import { queryCache } from "react-query";
import { KEY_FETCH_TEMP_INTERVIEWS } from "modules/Requests/RequestsInterviewsList/hooks/useFilterApplications";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import { INTERNAL_NOTE_MAX_LENGTH } from "config";

const SendVacancyDrawer = ({
	isOpen,
	onClose,
	onSuccess,
	isFromVacanciesTable,
	clearSelection
}) => {
	const placeholdersRef = useRef();

	const { data: candidateRejectionList } = useGetCandidateRejection();
	const { drawerState } = useVacancyStore();
	const { data: pokeData } = usePokeData({
		id: drawerState.application_id[0]
	});
	const rejectionOptions = useMemo(() => {
		return (candidateRejectionList?.options || [])?.map(({ value, _id }) => ({
			label: value,
			value: _id
		}));
	}, [candidateRejectionList]);
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();
	const [rejectApplication, { isLoading }] = useRejectApplication({
		onSuccess: () => {
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(_get(drawerState, "application_id[0]"));
			clearSelection?.();
			toaster.success("Application Rejected Successfully.");
			queryCache.invalidateQueries(KEY_FETCH_TEMP_INTERVIEWS);
			queryCache.invalidateQueries("RejectedList");
			queryCache.invalidateQueries("getDataPipeline");
			queryCache.invalidateQueries("getAllProcesses");
			onSuccess?.();

			drawerClose();
		},
		onError: renderError
	});

	const [showDiscardWarning, setShowDiscardWarning] = useState(false);
	const [secondPortal, setSecondPortal] = useState(null);
	const [sendVacancyTemplate, setSendVacancyTemplate] = useState(null);
	const [signature, setSignature] = useState(null);
	const [showSubjectWarning, setShowSubjectWarning] = useState(false);
	const [isPlaceholdersExtracted, setIsPlaceholdersExtracted] = useState(false);
	const [drawerVisibility, setDrawerVisibility] = useState(true);

	const methods = useForm({
		defaultValues: { ...EMPTY_EMAIL, note: "" },
		resolver: yupResolver(
			yup.object().shape({
				reason: yup.object().required("Please choose a reason")
			})
		)
	});
	const {
		formState: { dirtyFields, errors },
		reset,
		setValue,
		watch,
		getValues,
		control,
		handleSubmit
	} = methods;

	const isDirty = Object.keys(dirtyFields).length > 0;

	const contentPlaceholders = watch("content_placeholders");

	const drawerClose = () => {
		setDrawerVisibility(false);
	};

	useEffect(() => {
		if (!isOpen) {
			setShowDiscardWarning(false);
			reset();
		}
	}, [isOpen]);

	useEffect(() => {
		if (isOpen && drawerState?.candidateEmail) {
			setValue(
				"to",
				drawerState?.candidateEmail.map(el => {
					return { email: el };
				})
			);
		}
	}, [isOpen, drawerState]);

	const onClickCancel = () => {
		if (isDirty) {
			setShowDiscardWarning(true);
		} else {
			if (!isFromCardActions) {
				if (!isFromVacanciesTable) {
					setIsOpen(true);
				}
				setApplicationId(_get(drawerState, "application_id[0]"));
			}

			drawerClose();
		}
	};

	const emailId = useMemo(() => generateId(), []);
	const { data: templateDetails } = useGetEmailTemplate({
		event_type: "permanent_cancel_application_email"
	});

	useGetEmailSignature({
		enabled: !!templateDetails,
		onSuccess: data => {
			setSignature({
				signature_avatar: data.avatar || "",
				signature_address: data.address || "",
				signature_email: data.email || "",
				signature_full_name: data.full_name || "",
				signature_phone: data.phone || "",
				signature_position: data.position || "",
				signature_social_media: data.social_media || []
			});
		}
	});

	const updateNewEntity = useCallback(
		placeholders => {
			return placeholders.map(item => {
				if (item.entity === "candidate") {
					return {
						...item,
						value: item.value,
						_id: generateId(),
						readOnly: true
					};
				}
				if (item.entity === "company") {
					return {
						...item,
						value: item.value,
						label: _get(pokeData, "job.company.name", ""),
						_id: _get(pokeData, "job.company._id", "")
					};
				}
				if (item.entity === "vacancy") {
					return {
						...item,
						value: item.value,
						label: _get(pokeData, "job.title", ""),
						_id: _get(pokeData, "job._id", "")
					};
				}
				return item;
			});
		},
		[pokeData]
	);

	useEffect(() => {
		if (
			!isPlaceholdersExtracted &&
			contentPlaceholders.length > 0 &&
			pokeData
		) {
			const newContentPlaceholders = updateNewEntity(contentPlaceholders);
			setValue("content_placeholders", newContentPlaceholders);
			setIsPlaceholdersExtracted(true);
		}
	}, [contentPlaceholders, isPlaceholdersExtracted, pokeData]);

	useEffect(() => {
		if (templateDetails) {
			const mainVersion = Object.keys(templateDetails.versions)[0];
			const template = templateDetails.versions[mainVersion];
			reset({
				...getValues(),
				subject: template.subject,
				content: template.content
			});
			setSendVacancyTemplate(template);
		}
	}, [templateDetails]);

	const getReasonById = key => {
		return rejectionOptions.find(el => el.value === key);
	};

	const onSelectionChange = ({ key, onChange }) => {
		const value = {
			label: getReasonById(key)?.label ?? "",
			value: key
		};

		onChange(value);
	};

	const onInputChange = ({ val, onChange }) => {
		const valueObject = {
			label: val,
			value: val === "" ? null : watch("reason")?.value
		};
		onChange(valueObject);
	};

	const onSend = values => {
		const payload = {
			reject_reason: _get(values, "reason.label", ""),
			subject: values.subject,
			content: values.content,
			content_placeholders: values.content_placeholders.map(placeholder => ({
				key: placeholder.value,
				value: placeholder._id || ""
			})),
			vacancy_id: _get(pokeData, "job._id", ""),
			stage: _get(drawerState, "stage", ""),
			internal_comments: values.note,
			attachments: []
		};

		if (drawerState.step_id) {
			payload.step_id = _get(drawerState, "step_id");
		} else {
			payload.application_ids = _get(drawerState, "application_id");
		}

		rejectApplication(payload);
	};

	return (
		<Drawer
			open={drawerVisibility}
			width="998px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000, backgroundColor: "transparent" }}
			level={"root"}
			maskClosable={true}
			onClose={onClickCancel}
			afterVisibleChange={visibility => {
				if (!visibility) onClose();
			}}
		>
			<FormProvider {...methods}>
				<form className={styles.container} onSubmit={handleSubmit(onSend)}>
					<header className={styles.header}>
						<h3 className={styles.title}>Reject Application</h3>
						<button
							className={styles.closeBtn}
							type="button"
							onClick={onClickCancel}
						>
							<CloseIcon />
						</button>
					</header>
					{isOpen && (
						<div className={styles.body}>
							<div className={styles.container}>
								<div className={styles.row}>
									<label className={styles.label}>
										Reason of rejection <div className={styles.required}>*</div>
									</label>

									<Controller
										name="reason"
										control={control}
										render={({ field: { value, onChange } }) => {
											return (
												<ComboBox
													inputValue={value?.label}
													selectedKey={value?.value}
													inputRootClassName={styles.reminderSelect}
													popoverClassName={styles.reminderSelectPopOver}
													onSelectionChange={key =>
														onSelectionChange({ key, onChange })
													}
													onInputChange={val =>
														onInputChange({ val, onChange })
													}
												>
													{rejectionOptions.map(({ value, label }) => {
														return <Item key={value}>{label}</Item>;
													})}
												</ComboBox>
											);
										}}
									/>
									<FieldError
										className={styles.errorMessage}
										error={errors.reason}
									/>
								</div>
								<div className={styles.row}>
									<label className={styles.label}>
										Internal Note (Visible only to members of the department)
									</label>

									<Controller
										name="note"
										control={control}
										render={({ field: { value, onChange } }) => (
											<TextArea
												value={value}
												onChange={onChange}
												maxLength={INTERNAL_NOTE_MAX_LENGTH}
											/>
										)}
									/>
								</div>
								<div className={styles.row}>
									<label className={styles.label}>Compose Message</label>
									<div className={styles.banner}>
										ℹ️ Please review and customize the email below. Ensure all
										details are correct before sending
									</div>
								</div>
								<div className={styles.row}>
									<div className={styles.newMailContainer}>
										<div className={styles.body}>
											<MessagingToolNewEmailBody
												setSecondPortal={setSecondPortal}
												placeholdersRef={placeholdersRef}
												isActive={true}
												secondPortal={secondPortal}
												activeTemplate={sendVacancyTemplate}
												path={SEND_VACANCY_EMAIL}
												emailSignature={signature}
												useEmailSignaturePlugin
												readOnly
												totalItem={0}
											/>
										</div>
										<MesssagingToolNewEmailWarnings
											showSubjectWarning={showSubjectWarning}
											setShowSubjectWarning={setShowSubjectWarning}
											emailId={emailId}
										/>
										<div
											ref={placeholdersRef}
											className={cx(styles.placeholdersAndTemplates, {
												[styles.hide]: secondPortal !== "placeholders"
											})}
										/>
										{secondPortal === "templates" && (
											<div className={styles.placeholdersAndTemplates}>
												<div className={styles.header}>
													<span className={styles.title}>{secondPortal}</span>
													<button
														className={styles.headerButton}
														type="button"
														onClick={() => setSecondPortal("")}
													>
														<CloseIcon className={styles.icon} />
													</button>
												</div>
												<MessagingToolNewEmailTemplates
													setActiveTemplate={setSendVacancyTemplate}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					<footer className={styles.footer}>
						<Button
							className={styles.btn}
							text="Reject Application"
							isLoading={isLoading}
						/>
						<Button
							className={cx(styles.btn, styles.outlined)}
							text="Cancel"
							variant="outlined"
							type="button"
							onClick={onClickCancel}
						/>
					</footer>
				</form>

				{showDiscardWarning && (
					<LeaveHook
						dirty={isDirty}
						enforceWarning={showDiscardWarning}
						confirmationModal={{
							onDiscard: () => {
								if (!isFromCardActions) {
									if (!isFromVacanciesTable) {
										setIsOpen(true);
									}
									setApplicationId(_get(drawerState, "application_id[0]"));
								}

								drawerClose();
							},
							onClose: () => setShowDiscardWarning(false)
						}}
					/>
				)}
			</FormProvider>
		</Drawer>
	);
};

export default SendVacancyDrawer;
