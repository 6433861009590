/*TODO use components  CreateVacancyFieldContainer; CreateVacancyLabelContainer; CreateVacancyFieldLabel; CreateVacancyInfoIcon*/
import React, { useEffect, useState, useMemo } from "react";
import toaster from "common/Toaster";
import { Item } from "react-stately";
import { Controller, FormProvider } from "react-hook-form";
import styles from "./create-vacancy-profile-step.module.scss";
import Card from "../CreateVacancyCard/CreateVacancyCard";
import { SaveButton } from "common/SaveButton";
import { Button } from "common/Button";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import { ProfileStepFunctionsField } from "../ProfileStepFunctionsField";
import { ProfileStepSkillsField } from "../ProfileStepSkillsField";
import { ComboBox } from "common/ComboBox";
import { historyPush } from "config/helpers";
import { useGetSitesList } from "modules/vacancy/create-vacancy/api/useGetSitesList";
import { useGetDepartmentsList } from "modules/vacancy/create-vacancy/api/useGetDepartmentsList";
import { CreateVacancyCardContent as CardContent } from "../CreateVacancyCardContent";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { useProfileForm } from "modules/vacancy/create-vacancy/hooks/useProfileForm";
import { useCreateVacancyProfileInformations } from "modules/vacancy/create-vacancy/api/useCreateVacancyProfileInformations";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";
import { useEditVacancyProfileInformations } from "modules/vacancy/create-vacancy/api/useEditVacancyProfileInformations";
import LeaveHook from "common/LeaveHook";
import { CreateVacancyStepper } from "../CreateVacancyStepper";
import { PROFILE_STEP } from "../../utils/constants";
import { stringify } from "query-string";
import _get from "lodash/get";
import { ReactComponent as ArrowLeftIcon } from "static/icons/arrow-left-icon.svg";
import { goBack } from "config/helpers";
import { initializeProfileForm } from "../../utils/helper";
import { searchParser } from "config/helpers";
import useElements from "hooks/useLayoutElements";
import { useCreateVacancyTour } from "modules/user-onboarding/workflows/create-vacancy";
import { HOW_TO_CREATE_VACANCY } from "config/onboarding";
import { ProfileStepQueryLanguagesField } from "../ProfileStepQueryLanguagesField";
import useLanguagesSchema from "../../hooks/useLanguagesSchema";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useGetPrioritiesList } from "../../api/useGetPrioritiesList";
import { v4 as uuid } from "uuid";
import { VACANCY_PRIORITY_PATH } from "modules/CustomFieldsManager/utils/constants";
import { browserHistory } from "react-router";
import { getCustomFieldsManagerRoute } from "modules/CustomFieldsManager/getRouteConfig";

const CreateVacancyProfileStep = ({
	onSuccess,
	vacancyToEdit,
	onNext: onNextProp,
	hasMultiSites
}) => {
	const { mainElement } = useElements();
	const { workflowId } = useOnboardingStore();
	const { data: sitesData } = useGetSitesList({ enabled: !workflowId });
	const { data: departmentsList } = useGetDepartmentsList({
		enabled: !workflowId
	});
	const { data: prioritiesData } = useGetPrioritiesList({
		enabled: !workflowId
	});

	const prioritiesOptions = prioritiesData?.options || [];

	const methods = useProfileForm({ hasMultiSites, prioritiesOptions });

	const [displayAddDpartmentButton, setDisplayAddDpartmentButton] = useState(
		true
	);

	const [displayPriorityButton, setDisplayPriorityButton] = useState(true);

	const { languagesSchema, clearQueryLanguage } = useLanguagesSchema();

	const {
		control,
		watch,
		handleSubmit,
		reset,
		formState: { isDirty, errors },
		setValue,
		setError
	} = methods;

	const sitesList = sitesData ? sitesData.site_list : [];
	const [
		createVacancyProfileInformations,
		isCreateVacancyLoading
	] = useCreateVacancyProfileInformations(hasMultiSites);
	const [editVacancy, isEditVacancyLoading] = useEditVacancyProfileInformations(
		hasMultiSites
	);

	useEffect(() => {
		if (vacancyToEdit) {
			initializeProfileForm(reset, vacancyToEdit);
		} else if (languagesSchema.length) {
			clearQueryLanguage();
		}
	}, [vacancyToEdit]);

	useEffect(() => {
		const { departmentName, departmentId } = searchParser();

		if (departmentName) {
			setValue("department", { label: departmentName, value: departmentId });
		}
	}, []);

	const choosenCategories = watch("categories")?.map(
		({ category }) => category
	);

	const onNewDepartmentClick = () => {
		if (vacancyToEdit) {
			const params = {
				openDepartmentModal: true,
				vacancyId: vacancyToEdit._id
			};

			const stringified = stringify(params);

			historyPush(`settings/users?${stringified}`);
		} else {
			historyPush("settings/users?openDepartmentModal=true");
		}
	};

	const onNext = values => {
		if (!isDirty) onNextProp();
		else if (vacancyToEdit) {
			editVacancy(values, vacancyToEdit._id, vacancyToEdit.priority, {
				onSuccess,
				onError: onSubmitError
			});
		} else {
			createVacancyProfileInformations(values, {
				onSuccess,
				onError: onSubmitError
			});
		}
	};

	const onSubmitError = e => {
		if (e && e.status === 422) {
			let errorMsg = _get(e, "detail");
			if (errorMsg) {
				Object.keys(errorMsg).forEach(name => {
					let fieldName = name;
					if (name === "functions") {
						fieldName = "categories";
					} else if (name === "group_id") {
						fieldName = "department";
					} else if (name === "site_id") {
						fieldName = "site";
					} else if (name === "priority_id") {
						fieldName = "priority";
					}

					setError(fieldName, {
						type: "manual",
						message: _get(errorMsg, name)[0]
					});
					if (name === "number")
						toaster.danger(`${fieldName} : ${_get(errorMsg, name)[0]}`);
				});
			}
		} else {
			toaster.danger("An error has occurred");
		}
	};

	const getDartmentById = id => {
		const department = departmentsList?.find(({ _id }) => _id === id);
		return department;
	};

	let onDepartmentSelectionChange = (key, onChange) => {
		const value = {
			label: getDartmentById(key)?.name ?? "",
			value: key
		};

		if (key) {
			setDisplayAddDpartmentButton(false);
		}

		onChange(value);
	};

	let onDepartmentInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("department")?.value
		};

		setDisplayAddDpartmentButton(true);

		onChange(valueObject);
	};

	const getSiteById = id => {
		return sitesList?.find(({ _id }) => _id === id);
	};

	const onSiteSelectionChange = (key, onChange) => {
		const value = {
			label: getSiteById(key)?.name ?? "",
			value: key
		};

		onChange(value);
	};

	const onSiteInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("site")?.selectedKey
		};

		onChange(valueObject);
	};

	const onFunctionsSave = (onChange, value) => {
		const ids = value?.map(({ category }) => category.value);
		const isSkillsAvailable = watch("skills")?.length;

		if (isSkillsAvailable) {
			const skills = watch("skills")?.filter(({ categoryId }) =>
				ids.includes(categoryId)
			);

			setValue("skills", skills);
		}

		onChange(value);
	};

	const onBack = () => goBack();

	useCreateVacancyTour({
		handleFirstStepSubmit: handleSubmit(onNext)
	});

	const formattedPriorities = useMemo(() => {
		const priority = vacancyToEdit?.priority;
		const optionExists = !!prioritiesOptions?.find(
			option => option.value === priority
		);

		return optionExists || !priority
			? prioritiesOptions
			: [
					...prioritiesOptions,
					{
						value: priority,
						label: priority,
						_id: uuid()
					}
			  ];
	}, [prioritiesOptions, vacancyToEdit]);

	const onPriorityInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("priority")?.value
		};

		setDisplayPriorityButton(true);

		onChange(valueObject);
	};
	const onPrioritySelectionChange = (key, onChange) => {
		const value = {
			label: getPriorityById(key)?.value ?? key,
			value: key
		};

		if (key) {
			setDisplayPriorityButton(false);
		}

		onChange(value);
	};

	const getPriorityById = id => {
		return formattedPriorities?.find(({ _id }) => _id === id);
	};

	return (
		<>
			<CreateVacancyStepper
				onVacancyClick={handleSubmit(onNext)}
				actifStep={PROFILE_STEP}
			/>
			<div className={styles.cardsList}>
				<LeaveHook
					onOpen={() => {
						setDisplayAddDpartmentButton(false);
						setDisplayPriorityButton(false);
					}}
					onStay={() => {
						setDisplayAddDpartmentButton(true);
						setDisplayPriorityButton(true);
					}}
					dirty={isDirty}
				>
					<FormProvider {...methods}>
						<Card
							className={styles.card}
							data-onboarding-step={`${HOW_TO_CREATE_VACANCY}-2`}
						>
							<CardContent>
								<CreateVacancyFieldContainer>
									<CreateVacancyLabelContainer>
										<CreateVacancyFieldLabel>
											Department
										</CreateVacancyFieldLabel>
										<CreateVacancyInfoIcon>
											Please choose the department for which you are hiring. The
											users of the selected department will be able to see and
											interact with candidates for this vacancy (poke
											candidates, add notes on applications, etc..).
										</CreateVacancyInfoIcon>
									</CreateVacancyLabelContainer>
									<Controller
										name="department"
										control={control}
										render={({ field: { value, onChange } }) => {
											return (
												<ComboBox
													popoverContainer={mainElement}
													displayButton={displayAddDpartmentButton}
													button={
														<Button
															text="Create new department"
															variant="text"
															textClassName={styles.addNewDepartmentButtonText}
															className={styles.addDepartmentButton}
															icon={<AddIcon />}
															onClick={onNewDepartmentClick}
														/>
													}
													selectedKey={value?.value}
													inputValue={value?.label}
													onInputChange={value =>
														onDepartmentInputChange(value, onChange)
													}
													onSelectionChange={key => {
														onDepartmentSelectionChange(key, onChange);
													}}
												>
													{(departmentsList || []).map(({ _id, name }) => (
														<Item key={_id}>{name}</Item>
													))}
												</ComboBox>
											);
										}}
									/>

									<CreateVacancyFieldError error={errors.department} />
								</CreateVacancyFieldContainer>
								{hasMultiSites && (
									<div className={styles.fieldContainer}>
										<CreateVacancyFieldLabel>Site</CreateVacancyFieldLabel>
										<Controller
											name="site"
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<ComboBox
														popoverContainer={mainElement}
														inputValue={value?.label}
														selectedKey={value?.value}
														onInputChange={value =>
															onSiteInputChange(value, onChange)
														}
														onSelectionChange={key =>
															onSiteSelectionChange(key, onChange)
														}
													>
														{sitesList?.map(({ _id, name }) => {
															return <Item key={_id}>{name}</Item>;
														})}
													</ComboBox>
												);
											}}
										/>
										<CreateVacancyFieldError error={errors.site} />
									</div>
								)}
								<div className={styles.fieldContainer}>
									<Controller
										name="categories"
										control={control}
										render={({ field: { onChange } }) => {
											return (
												<ProfileStepFunctionsField
													onSave={value => onFunctionsSave(onChange, value)}
												/>
											);
										}}
									/>
									<CreateVacancyFieldError error={errors.categories} />
								</div>
								<div className={styles.fieldContainer}>
									<Controller
										name="skills"
										control={control}
										render={({ field: { onChange } }) => {
											return (
												<ProfileStepSkillsField
													choosenCategories={choosenCategories}
													onSave={onChange}
												/>
											);
										}}
									/>
									<CreateVacancyFieldError error={errors.skills} />
								</div>

								<div className={styles.fieldContainer}>
									<Controller
										name="query_languages"
										control={control}
										render={({ field: { onChange } }) => {
											return (
												<ProfileStepQueryLanguagesField onSave={onChange} />
											);
										}}
									/>
									<CreateVacancyFieldError error={errors.query_languages} />
								</div>
								<div className={styles.fieldContainer}>
									<CreateVacancyFieldLabel>Priority</CreateVacancyFieldLabel>
									<Controller
										name="priority"
										control={control}
										render={({ field: { onChange, value } }) => (
											<ComboBox
												inputValue={value?.label || ""}
												selectedKey={value?.value}
												displayButton={displayPriorityButton}
												onInputChange={value => {
													onPriorityInputChange(value, onChange);
												}}
												onSelectionChange={key => {
													onPrioritySelectionChange(key, onChange);
												}}
												button={
													<Button
														text="Add new priority"
														variant="text"
														textClassName={styles.addNewDepartmentButtonText}
														className={styles.addDepartmentButton}
														icon={<AddIcon />}
														onClick={() =>
															browserHistory.replace(
																getCustomFieldsManagerRoute(
																	VACANCY_PRIORITY_PATH
																)
															)
														}
													/>
												}
											>
												{formattedPriorities?.map(({ _id, value }) => {
													return <Item key={_id}>{value}</Item>;
												})}
											</ComboBox>
										)}
									/>
									<CreateVacancyFieldError error={errors.priority} />
								</div>
							</CardContent>
						</Card>
						<div className={styles.buttonsContainer}>
							<Button
								text="Back"
								onClick={onBack}
								icon={<ArrowLeftIcon />}
								variant="text"
								className={styles.backButton}
								textClassName={styles.backButtonText}
							/>

							<SaveButton
								leftIcon={null}
								text="Next"
								onClick={handleSubmit(onNext)}
								className={styles.nextButton}
								isLoading={isCreateVacancyLoading || isEditVacancyLoading}
								isDisabled={isCreateVacancyLoading || isEditVacancyLoading}
							/>
						</div>
					</FormProvider>
				</LeaveHook>
			</div>
		</>
	);
};

export default CreateVacancyProfileStep;
