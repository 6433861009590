import React, { useEffect, useRef, useState } from "react";
import styles from "./vacancy-view-interview-new-mail.module.scss";
import MessagingToolNewEmailBody from "common/MessagingToolNewEmail/components/MessagingToolNewEmailBody/MessagingToolNewEmailBody";
import MesssagingToolNewEmailWarnings from "common/MessagingToolNewEmail/components/MesssagingToolNewEmailWarnings/MesssagingToolNewEmailWarnings";
import MessagingToolNewEmailTemplates from "common/MessagingToolNewEmail/components/MessagingToolNewEmailTemplates/MessagingToolNewEmailTemplates";
import { useGetEmailSignature } from "common/MessagingToolNewEmail/api/useGetEmailSignature";
import cx from "classnames";
import { ReactComponent as MailIcon } from "static/icons/mail-01.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { useGetEmailTemplate } from "common/MessagingToolNewEmail/api/useGetEmailTemplate";
import { useFormContext } from "react-hook-form";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import { INTERVIEW_EMAIL_PLACEHOLDERS_CONTEXT } from "../../helper/constants";

const VacancyViewInterviewNewMail = ({
	jobData = {},
	isVisible,
	isDeclineInterview
}) => {
	const placeholdersRef = useRef();

	const { setValue, reset, getValues, watch } = useFormContext();
	const [activeTemplate, setActiveTemplate] = useState(null);
	const [secondPortal, setSecondPortal] = useState(null);
	const [showSubjectWarning, setShowSubjectWarning] = useState(false);
	const [signature, setSignature] = useState(null);
	const { data: templateDetails } = useGetEmailTemplate({
		event_type: isDeclineInterview
			? "permanent_interview_declined_email"
			: "permanent_interview_email"
	});
	const [isPlaceholdersExtracted, setIsPlaceholdersExtracted] = useState(false);
	const contentPlaceholders = watch("content_placeholders");

	useGetEmailSignature({
		enabled: !!templateDetails,
		onSuccess: data => {
			setSignature({
				signature_avatar: data.avatar || "",
				signature_address: data.address || "",
				signature_email: data.email || "",
				signature_full_name: data.full_name || "",
				signature_phone: data.phone || "",
				signature_position: data.position || "",
				signature_social_media: data.social_media || []
			});
		}
	});

	const drawerState = useVacancyStore(state => state.drawerState);
	const updateNewEntity = placeholders => {
		return placeholders.map(item => {
			if (item.entity === "candidate") {
				return {
					...item,
					value: item.value,
					_id: drawerState.profileId,
					label: drawerState.candidateName,
					readOnly: true
				};
			}
			if (item.entity === "vacancy") {
				return {
					...item,
					value: item.value,
					_id: jobData?._id,
					label: jobData?.title,
					readOnly: !!jobData?._id
				};
			}
			if (item.entity === "company") {
				return {
					...item,
					value: item.value,
					_id: jobData?.company?._id,
					label: jobData?.company?.name,
					readOnly: !!jobData?.company?._id
				};
			}
			if (item.entity === "interview") {
				return {
					...item,
					value: item.value,
					_id: isDeclineInterview ? drawerState.interviewId : getValues("id"),
					readOnly: true
				};
			}
			return item;
		});
	};

	useEffect(() => {
		if (drawerState.profileId) {
			setIsPlaceholdersExtracted(false);
		}
	}, [drawerState.profileId]);

	useEffect(() => {
		if (
			!isPlaceholdersExtracted &&
			contentPlaceholders.length > 0 &&
			jobData?._id &&
			drawerState.profileId
		) {
			const newContentPlaceholders = updateNewEntity(contentPlaceholders);
			setValue("content_placeholders", newContentPlaceholders);
			setIsPlaceholdersExtracted(true);
		}
	}, [
		contentPlaceholders,
		updateNewEntity,
		isPlaceholdersExtracted,
		jobData?._id,
		drawerState.profileId
	]);

	useEffect(() => {
		if (templateDetails) {
			const template = Object.values(templateDetails.versions)[0];
			reset({
				...getValues(),
				subject: template.subject,
				content: template.content
			});
			setActiveTemplate(template);
		}
	}, [templateDetails]);

	return (
		<div
			className={styles.container}
			style={{ display: isVisible ? "flex" : "none" }}
		>
			<div className={styles.row}>
				<label className={styles.label}>
					<MailIcon className={styles.rowIcon} />
					Compose Interview Message
				</label>
				<div className={styles.banner}>
					ℹ️ Please review and customize the email below. Ensure all details are
					correct before sending
				</div>
			</div>
			<div className={cx(styles.row, styles.editor)}>
				<div className={styles.newMailContainer}>
					<div className={styles.body}>
						<MessagingToolNewEmailBody
							setSecondPortal={setSecondPortal}
							placeholdersRef={placeholdersRef}
							isActive={true}
							secondPortal={secondPortal}
							activeTemplate={activeTemplate}
							path={INTERVIEW_EMAIL_PLACEHOLDERS_CONTEXT}
							emailSignature={signature}
							bodyClassName={styles.bodyClassName}
							useEmailSignaturePlugin
							readOnly
						/>
					</div>
					<MesssagingToolNewEmailWarnings
						showSubjectWarning={showSubjectWarning}
						setShowSubjectWarning={setShowSubjectWarning}
					/>
					<div
						ref={placeholdersRef}
						className={cx(styles.placeholdersAndTemplates, {
							[styles.hide]: secondPortal !== "placeholders"
						})}
					/>
					{secondPortal === "templates" && (
						<div className={styles.placeholdersAndTemplates}>
							<div className={styles.header}>
								<span className={styles.title}>{secondPortal}</span>
								<button
									className={styles.headerButton}
									type="button"
									onClick={() => setSecondPortal("")}
								>
									<CloseIcon className={styles.icon} />
								</button>
							</div>
							<MessagingToolNewEmailTemplates
								setActiveTemplate={setActiveTemplate}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default VacancyViewInterviewNewMail;
