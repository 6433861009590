import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "../collaborators-list.module.scss";

const LoadingState = () => {
	return (
		<table className={styles.container}>
			<thead>
				<tr className={styles.titles}>
					<th className={styles.colUser}>Collaborators</th>
					<th className={styles.colPermission}>Permissions</th>
					<th className={styles.colRole}>Roles</th>
					<th></th>
				</tr>
			</thead>

			<tbody className={styles.list}>
				{[...Array(4)].map((_, index) => {
					return (
						<tr className={styles.row} key={index}>
							<td>
								<div className={styles.colUser}>
									<Skeleton width={223} height={36} />
								</div>
							</td>

							<td>
								<div className={styles.colPermission}>
									<Skeleton width={198} height={36} />
								</div>
							</td>
							<td>
								<div className={styles.colRole}>
									<Skeleton width={95} height={36} />
								</div>
							</td>
							<td></td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default LoadingState;
