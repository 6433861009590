import React, { useState } from "react";
import useVacancyStore from "../../vacancyStore";
import Drawer from "rc-drawer";
import styles from "./vacancy-view-propose-interview-drawer.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/close-icon.svg";
import { ReactComponent as ArrowRightIcon } from "static/icons/arrow-right-1.svg";
import { ReactComponent as BackIcon } from "static/icons/IconBack.svg";
import { Button } from "common/Button";
import { useProposePermanentInterviewForm } from "../../hooks/useProposePermanentInterviewForm";
import { FormProvider } from "react-hook-form";
import cx from "classnames";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import LeaveHook from "common/LeaveHook";
import VacancyViewProposeInterviewFirstStep from "../VacancyViewProposeInterviewFirstStep/VacancyViewProposeInterviewFirstStep";
import VacancyViewInterviewNewMail from "../VacancyViewInterviewNewMail/VacancyViewInterviewNewMail";
import {
	getDefaultValues,
	prepareProposeInterviewPayload
} from "../../helper/utils";
import { useGetUser } from "hooks/useGetUser";
import { useInviteUserToInterview } from "../../hooks/useInviteUserToInterview";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { useDispatch } from "react-redux";
import { fetchPoke } from "modules/vacancy/actions";
import VacancyViewAddMailModal from "../../VacancyViewAddMailModal/VacancyViewAddMailModal";
import VacancyViewMissingPlaceholderWarning from "../../VacancyViewMissingPlaceholderWarning/VacancyViewMissingPlaceholderWarning";
import { CANCELLED, EXPIRED, DONE, INTERVIEW } from "config";
import { renderError } from "config/helpers";
import { LOCATION_TYPES } from "../../helper/constants";
import useValidateLocation from "api/useValidateLocation";
import get from "lodash/get";
import { CALENDAR_EVENTS_LIST_KEY } from "modules/calendar/api/get-events-list";
import usePokeData from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";
import isEmpty from "lodash/isEmpty";

const STEP_ONE = "STEP_ONE";
const STEP_TWO = "STEP_TWO";

export default function VancancyViewProposeInterviewDrawer({
	job,
	onClose,
	isFromVacanciesTable,
	useVacancyAndCandidateFields = false
}) {
	const [
		validateAddressMutation,
		{ isLoading: isValidateLocationLoading }
	] = useValidateLocation();
	const { drawerState, setDrawerState } = useVacancyStore();
	const connectedUser = useGetUser();
	const { data: pokeData } = usePokeData({
		id: !isEmpty(job) ? null : drawerState.applicationId
	});
	const jobData = isEmpty(job) ? pokeData?.job : job;
	const isDeclineInterview =
		drawerState.isApplicantHasAnOffer &&
		drawerState.interviewStatus !== CANCELLED &&
		drawerState.interviewStatus !== DONE &&
		drawerState.interviewStatus !== EXPIRED;
	const [showDiscardWarning, setShowDiscardWarning] = useState(false);
	const [showAddMailModal, setShowAddMailModal] = useState(false);
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();
	const [currentStep, setCurrentStep] = useState(STEP_ONE);
	const [
		showMissingPlaceholderWarning,
		setShowMissingPlaceholderWarning
	] = useState(false);
	const authenticatedUser = useGetUser();
	const formContext = useProposePermanentInterviewForm({
		candidate: {
			email: drawerState.candidateEmail,
			_id: drawerState.profileId,
			first_name: get(drawerState, "profile.headline.first_name", ""),
			last_name: get(drawerState, "profile.headline.last_name", ""),
			avatar: get(drawerState, "profile.headline.avatar", ""),
			account_type: get(drawerState, "profile.headline.type", "")
		},
		interview: drawerState.interview,
		applicationId: drawerState.applicationId,
		job
	});
	const {
		formState: { dirtyFields },
		watch,
		setValue,
		getValues,
		setError,
		reset
	} = formContext;
	const isDirty = Object.keys(dirtyFields).length > 0;
	const [
		mutate,
		{ isLoading: isProposeInterviewLoading }
	] = useInviteUserToInterview();
	const dispatch = useDispatch();
	React.useEffect(() => {
		if (pokeData) {
			if (Object.keys(pokeData.interview).length > 0)
				reset({
					...getDefaultValues({
						candidate: {
							email: get(pokeData, "profile.headline.email", ""),
							_id: pokeData.profile._id,
							first_name: get(pokeData, "profile.headline.first_name", ""),
							last_name: get(pokeData, "profile.headline.last_name", ""),
							avatar: get(pokeData, "profile.headline.avatar", ""),
							account_type: get(pokeData, "profile.headline.type", "")
						},
						interview: pokeData.interview,
						job: pokeData.job,
						connectedUser
					}),
					content_placeholders: getValues("content_placeholders"),
					subject: getValues("subject"),
					content: getValues("content")
				});
			setDrawerState({
				...drawerState,
				open: true,
				component: INTERVIEW,
				interview: pokeData.interview,
				interviewId: pokeData.interview._id,
				interviewStatus: pokeData.interview.status,
				candidateEmail: get(pokeData, "profile.headline.email", ""),
				candidateName: `${get(
					pokeData,
					"profile.headline.first_name",
					""
				)} ${get(pokeData, "profile.headline.last_name", "")}`,
				accountType: get(pokeData, "profile.headline.type", ""),
				profileId: pokeData.profile._id,
				profile: pokeData?.profile,
				isApplicantHasAnOffer: Boolean(Object.keys(pokeData.interview).length),
				job: pokeData.job,
				poke: pokeData.poke
			});
		}
	}, [pokeData]);

	const closeDrawer = () => {
		if (isFromCardActions) {
			setDrawerState({
				open: false,
				component: ""
			});
		} else {
			setDrawerState({
				open: false,
				component: ""
			});
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(drawerState.applicationId);
		}
	};

	const onClickSubmit = async () => {
		const locationType = getValues("locationType");
		try {
			if (locationType !== LOCATION_TYPES.online.value) {
				await validateAddress({
					locationValue: getValues("locationValue"),
					locationType: getValues("locationType")
				});
			}
			if (currentStep === STEP_ONE) {
				let fieldsToValidate = [
					"times",
					"dates",
					"attendees",
					"locationValue",
					"note"
				];
				if (!drawerState.applicationId) {
					fieldsToValidate = [...fieldsToValidate, "vacancy", "candidate"];
				}
				formContext
					.trigger(fieldsToValidate, {
						shouldFocus: true
					})
					.then(isValid => {
						if (isValid) {
							if (currentStep === STEP_ONE && !watch("to")[0]?.email) {
								setShowAddMailModal(true);
							} else {
								setCurrentStep(STEP_TWO);
							}
						}
					});
			} else {
				formContext.handleSubmit(onSubmit)();
			}
		} catch (error) {
			setError("locationValue", { message: "Please enter a valid address" });
		}
	};

	const validateAddress = async ({ locationValue, locationType }) => {
		if (
			[
				LOCATION_TYPES.other.value,
				LOCATION_TYPES.companyAddress.value
			].includes(locationType)
		) {
			return await new Promise((resolve, reject) => {
				validateAddressMutation(
					{ location: JSON.parse(locationValue) },
					{
						onSuccess: resolve,
						onError: reject
					}
				);
			});
		}
	};

	const onSubmit = (data, args = {}) => {
		const { logEvent = false } = args;
		const payload = prepareProposeInterviewPayload({
			formData: data,
			logEvent: logEvent,
			companyType: drawerState.companyType,
			applicationId: drawerState.applicationId,
			profileId: drawerState.profileId,
			interviewId: drawerState.interviewId,
			isDeclineInterview,
			timezone: connectedUser.timezone
		});
		mutate(
			{
				...payload,
				isDeclineInterview
			},
			{
				onSuccess: () => {
					toaster.success("Interview invite successfully sent!");
					setShowAddMailModal(false);
					onClose();
					if (!isFromVacanciesTable) {
						setIsOpen(true);
					}
					setApplicationId(drawerState.applicationId);
					queryCache.invalidateQueries("getDataPipeline");
					queryCache.invalidateQueries(CALENDAR_EVENTS_LIST_KEY);
					if (pokeData?._id) return dispatch(fetchPoke({ id: pokeData._id }));
				},
				onError: renderError
			}
		);
	};

	const onSaveAsLogged = () => {
		if (getValues("times").length > 1) {
			toaster.danger(
				"You can only choose one date and time for logged interviews."
			);
		} else {
			formContext.handleSubmit(onSubmit)({ logEvent: true });
		}
	};

	const onClickCancel = () => {
		if (isDirty) {
			setShowDiscardWarning(true);
		} else {
			onClose();
		}
	};

	const onClickBack = () => {
		setCurrentStep(STEP_ONE);
	};

	const isLoading =
		formContext.isValidating ||
		isProposeInterviewLoading ||
		isValidateLocationLoading;

	return (
		<Drawer
			open={drawerState.open}
			width="1040px"
			height="100%"
			placement={"right"}
			style={{ zIndex: 1000, backgroundColor: "transparent" }}
			level={"root"}
			maskClosable
			onClose={onClickCancel}
		>
			<FormProvider {...formContext}>
				<div className={styles.container}>
					<div className={styles.header}>
						<div className={styles.title}>
							{isDeclineInterview
								? "Propose Another Interview"
								: "Propose Interview"}
						</div>
						<button
							className={styles.closeButton}
							onClick={onClickCancel}
							disabled={isLoading}
						>
							<CloseIcon />
						</button>
					</div>
					<div className={styles.body}>
						<VacancyViewProposeInterviewFirstStep
							isVisible={currentStep === STEP_ONE}
							useVacancyAndCandidateFields={useVacancyAndCandidateFields}
							timezone={connectedUser.timezone}
							job={jobData}
						/>
						<VacancyViewInterviewNewMail
							isVisible={currentStep === STEP_TWO}
							isDeclineInterview={isDeclineInterview}
							jobData={jobData}
						/>
					</div>
					<div className={cx(styles.footer, isLoading && styles.disabled)}>
						<div className={styles.group}>
							<Button
								text="Cancel"
								onClick={onClickCancel}
								className={styles.cancelBtn}
							/>
						</div>
						<div className={styles.group}>
							{currentStep === STEP_TWO ? (
								<Button
									text="Back"
									onClick={onClickBack}
									className={styles.backBtn}
									icon={<BackIcon />}
								/>
							) : (
								<Button
									onClick={onSaveAsLogged}
									text="Save as Logged"
									className={styles.saveAsLoggedBtn}
								/>
							)}
							<Button
								onClick={onClickSubmit}
								text={
									isDeclineInterview
										? "Propose Another Interview"
										: "Propose Interview"
								}
								rightIcon={currentStep === STEP_ONE ? <ArrowRightIcon /> : null}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
				<LeaveHook
					dirty={isDirty}
					enforceWarning={showDiscardWarning}
					confirmationModal={{
						onDiscard: onClose,
						onClose: () => setShowDiscardWarning(false),
						isLoading: isLoading,
						disabled: isLoading
					}}
				/>
				{showAddMailModal && (
					<VacancyViewAddMailModal
						logInterviewHandler={onSaveAsLogged}
						closeDrawer={closeDrawer}
						profileId={drawerState.profileId}
						vacancyId={jobData?._id}
						activeCompany={authenticatedUser?.active_company}
						setShowAddMailModal={setShowAddMailModal}
						onEmailAdded={email => {
							setValue("to", [{ email, _id: drawerState.profileId }]);
							onClickSubmit();
						}}
					/>
				)}
				{showMissingPlaceholderWarning && (
					<VacancyViewMissingPlaceholderWarning
						onClose={() => setShowMissingPlaceholderWarning(false)}
						placeholderLabel="interview.link"
					/>
				)}
			</FormProvider>
		</Drawer>
	);
}
