import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { GET_GROUP_INFO_ENDPOINT } from "config/api-endpoints";

const fetchSettings = body => {
	return client(GET_GROUP_INFO_ENDPOINT, {
		body
	});
};

export const CALENDAR_COLLABORATIVE_GROUPS_SETTINGS_DETAILS =
	"CALENDAR_COLLABORATIVE_GROUPS_SETTINGS_DETAILS";

const useFetchCollaborativeGroupsSettings = (body, options) => {
	return useQuery(
		[CALENDAR_COLLABORATIVE_GROUPS_SETTINGS_DETAILS, body],
		() => fetchSettings(body),
		{
			refetchOnWindowFocus: false,
			retry: false,
			...options
		}
	);
};

export default useFetchCollaborativeGroupsSettings;
