import React from "react";
import styles from "./text-area.module.scss";
import cx from "classnames";

const TEXT_AREA_PLACEHOLDER = "Start typing...";
const TEXT_AREA_MAX_LENGTH = 2000;

const TextAreaLengthComponent = ({ value, maxLength, lengthClassName }) => {
	return (
		<span className={cx(styles.length, lengthClassName)}>
			{value.length}/{maxLength}
		</span>
	);
};

const MemoizedTextAreaLenghtComponent = React.memo(TextAreaLengthComponent);

const TextArea = ({
	value,
	onChange: onChangeProps,
	placeholder = TEXT_AREA_PLACEHOLDER,
	maxLength = TEXT_AREA_MAX_LENGTH,
	wrapperClassName,
	textAreaClassName,
	lengthClassName,
	...props
}) => {
	const onPaste = e => {
		e.preventDefault();

		const pastedText = e.clipboardData.getData("Text");
		const processedText = pastedText.trim().slice(0, maxLength);
		onChangeProps(processedText);
	};

	const onChange = e => {
		if (e.target.value.length <= maxLength) onChangeProps(e.target.value);
	};

	return (
		<div className={cx(styles.textAreaWrapper, wrapperClassName)}>
			<textarea
				placeholder={placeholder}
				className={cx(styles.textarea, textAreaClassName)}
				value={value}
				onChange={onChange}
				onPaste={onPaste}
				{...props}
			/>
			{props.children ? (
				props.children
			) : (
				<MemoizedTextAreaLenghtComponent
					maxLength={maxLength}
					value={value}
					lengthClassName={lengthClassName}
				/>
			)}
		</div>
	);
};

export default TextArea;
