import React from "react";
import styles from "./layout.module.scss";
import SideBar from "../side-bar/side-bar";

const Layout = ({ children }) => {
	return (
		<div className={styles.container}>
			<div className={styles.sideBar}>
				<SideBar />
			</div>
			<div className={styles.contentWrapper}>{children}</div>
		</div>
	);
};

export default Layout;
