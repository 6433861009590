import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./vacancy-view-send-offer-new-mail.module.scss";
import MessagingToolNewEmailBody from "common/MessagingToolNewEmail/components/MessagingToolNewEmailBody/MessagingToolNewEmailBody";
import MesssagingToolNewEmailWarnings from "common/MessagingToolNewEmail/components/MesssagingToolNewEmailWarnings/MesssagingToolNewEmailWarnings";
import MessagingToolNewEmailTemplates from "common/MessagingToolNewEmail/components/MessagingToolNewEmailTemplates/MessagingToolNewEmailTemplates";
import { useGetEmailSignature } from "common/MessagingToolNewEmail/api/useGetEmailSignature";
import cx from "classnames";
import { ReactComponent as MailIcon } from "static/icons/mail-01.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { useGetEmailTemplate } from "common/MessagingToolNewEmail/api/useGetEmailTemplate";
import { useFormContext } from "react-hook-form";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import { VACANCY_VIEW_PROPOSE_OFFER } from "../../utils/constant";
import isEmpty from "lodash/isEmpty";

const VacancyViewSendOfferNewMail = ({ jobData, isVisible }) => {
	const placeholdersRef = useRef();

	const { setValue, getValues, watch, reset } = useFormContext();
	const [secondPortal, setSecondPortal] = useState(null);
	const [showSubjectWarning, setShowSubjectWarning] = useState(false);
	const [signature, setSignature] = useState(null);
	const [offerTemplate, setOfferTemplate] = useState(null);
	const { data: templateDetails } = useGetEmailTemplate({
		event_type: "offer_email"
	});
	const [isPlaceholdersExtracted, setIsPlaceholdersExtracted] = useState(false);
	const contentPlaceholders = watch("content_placeholders");

	useGetEmailSignature({
		enabled: !!templateDetails,
		onSuccess: data => {
			setSignature({
				signature_avatar: data.avatar || "",
				signature_address: data.address || "",
				signature_email: data.email || "",
				signature_full_name: data.full_name || "",
				signature_phone: data.phone || "",
				signature_position: data.position || "",
				signature_social_media: data.social_media || []
			});
		}
	});

	const {
		drawerState: { profile_id, candidateName }
	} = useVacancyStore();

	const updateNewEntity = useCallback(
		placeholders => {
			return placeholders.map(item => {
				if (item.entity === "candidate") {
					return {
						...item,
						value: item.value,
						label: candidateName,
						_id: profile_id,
						readOnly: true
					};
				} else if (item.entity === "vacancy") {
					return {
						...item,
						value: item.value,
						label: jobData?.title,
						_id: jobData?._id,
						readOnly: !!jobData?._id
					};
				} else if (item.entity === "offer") {
					return {
						...item,
						value: item.value,
						_id: profile_id,
						readOnly: true
					};
				}
				return item;
			});
		},
		[profile_id, candidateName, jobData]
	);

	useEffect(() => {
		if (
			!isPlaceholdersExtracted &&
			contentPlaceholders.length > 0 &&
			!isEmpty(jobData)
		) {
			const newContentPlaceholders = updateNewEntity(contentPlaceholders);
			setValue("content_placeholders", newContentPlaceholders);
			setIsPlaceholdersExtracted(true);
		}
	}, [contentPlaceholders, updateNewEntity, isPlaceholdersExtracted, jobData]);

	useEffect(() => {
		if (templateDetails) {
			const mainVersion = Object.keys(templateDetails.versions)[0];
			const template = templateDetails.versions[mainVersion];
			reset({
				...getValues(),
				subject: template.subject,
				content: template.content
			});
			setOfferTemplate(template);
		}
	}, [templateDetails]);

	return (
		<div
			className={styles.container}
			style={{ display: isVisible ? "flex" : "none" }}
		>
			<div className={styles.row}>
				<label className={styles.label}>
					<MailIcon className={styles.rowIcon} />
					Compose Offer Message
				</label>
				<div className={styles.banner}>
					ℹ️ Please review and customize the email below. Ensure all details are
					correct before sending
				</div>
			</div>
			<div className={cx(styles.row, styles.editor)}>
				<div className={styles.newMailContainer}>
					<div className={styles.body}>
						<MessagingToolNewEmailBody
							setSecondPortal={setSecondPortal}
							placeholdersRef={placeholdersRef}
							isActive={true}
							secondPortal={secondPortal}
							activeTemplate={offerTemplate}
							path={VACANCY_VIEW_PROPOSE_OFFER}
							emailSignature={signature}
							bodyClassName={styles.bodyClassName}
							useEmailSignaturePlugin
							readOnly
						/>
					</div>
					<MesssagingToolNewEmailWarnings
						showSubjectWarning={showSubjectWarning}
						setShowSubjectWarning={setShowSubjectWarning}
					/>
					<div
						ref={placeholdersRef}
						className={cx(styles.placeholdersAndTemplates, {
							[styles.hide]: secondPortal !== "placeholders"
						})}
					/>
					{secondPortal === "templates" && (
						<div className={styles.placeholdersAndTemplates}>
							<div className={styles.header}>
								<span className={styles.title}>{secondPortal}</span>
								<button
									className={styles.headerButton}
									type="button"
									onClick={() => setSecondPortal("")}
								>
									<CloseIcon className={styles.icon} />
								</button>
							</div>
							<MessagingToolNewEmailTemplates
								setActiveTemplate={setOfferTemplate}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default VacancyViewSendOfferNewMail;
