export const getCalendarSettingsRoute = () => {
	return "/settings/calendar-settings";
};

export const getCalendarSettingsPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export const getCollaborativeGroupsRoute = () => {
	return "/settings/collaborative-groups";
};

export const getCollaborativeGroupsPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
