import { UPDATE_CALENDAR_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

const saveCalendarSettings = body => {
	return client(UPDATE_CALENDAR_SETTINGS_ENDPOINT, {
		body
	});
};

function useSaveCalendarSettings(options) {
	return useMutation(saveCalendarSettings, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
}

export default useSaveCalendarSettings;
