import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./calendar-working-days.module.scss";

const LoadingState = () => {
	return (
		<div className={styles.content}>
			<div className={styles.workingDays}>
				{[...Array(7)].map((_, index) => (
					<Skeleton key={index} height={34} width={34} />
				))}
			</div>

			<div className={styles.timeSlots}>
				{[...Array(5)].map((_, index) => (
					<Skeleton key={index} height={21} width={428} />
				))}
			</div>
		</div>
	);
};

export default LoadingState;
