import React, { useEffect } from "react";
import Portal from "common/Portal";
import { TOUR_CALENDAR } from "config/onboarding";
import TourContentComponent from "modules/user-onboarding/components/TourContentComponent";
import styles from "modules/user-onboarding/components/TourContentComponent/tour-content-component.module.scss";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useTour } from "@reactour/tour";
import { browserHistory } from "react-router";
import isElementLoaded from "modules/user-onboarding/helpers/isElementLoaded";

const selectorForStep = stepNumber =>
	`[data-onboarding-step=${TOUR_CALENDAR}-${stepNumber}]`;

const steps = [
	{
		selector: selectorForStep(0),
		title: `Click on the calendar icon`,
		description: `Access the calendar and begin scheduling or viewing events.`,
		stepInteraction: false,
		position: p => [p.left + 115, p.top + 364],
		arrow: "left-bottom"
	},
	{
		selector: selectorForStep(1),
		title: `Sync your calendar`,
		description: `Ensure your external calendar (Outlook, Google, etc.) is in sync with Wiggli to manage all events in one place. This helps centralize your scheduling.`,
		stepInteraction: false,
		position: p => [p.right - 30, p.top + 35],
		arrow: "top-left"
	},
	{
		selector: selectorForStep(2),
		title: `Sync your calendar`,
		description: `Ensure your external calendar (Outlook, Google, etc.) is in sync with Wiggli to manage all events in one place. This helps centralize your scheduling.`,
		stepInteraction: false,
		position: p => [p.left - 416, p.top - 186],
		arrow: "right-bottom"
	},
	{
		selector: selectorForStep(3),
		title: `Click on schedule`,
		description: `Quickly schedule interviews or other events by selecting the appropriate event type from the scheduling menu.`,
		stepInteraction: false,
		position: p => [p.left + 152, p.top + 15],
		arrow: "left-top"
	},
	{
		selector: selectorForStep(4),
		title: `Choose event`,
		description: `Set up your event by entering necessary information like title, date, time, and attendees in the event creation modal.`,
		stepInteraction: false,
		position: p => [p.left + 152, p.top + 15],
		arrow: "left-top"
	},

	{
		selector: selectorForStep(5),
		title: `Choose interview`,
		description: `Select "Interview" as the event type and fill in details such as candidates, attendees, and interview date/time.`,
		stepInteraction: false,
		position: p => [p.left + 152, p.top + 15],
		arrow: "left-top"
	},
	{
		selector: selectorForStep(6),
		title: `Create a collaborative group`,
		description: `Set up a group by inviting members with roles like viewer or editor, enabling team collaboration and shared access to events.`,
		stepInteraction: false,
		position: p => [p.left + 50, p.top + 15],
		arrow: "left-top"
	},
	{
		selector: selectorForStep(7),
		title: `Change the view of your calendar `,
		description: `Switch between daily, weekly, or monthly views to see your upcoming schedule and tasks clearly at different scales.`,
		stepInteraction: false,
		position: p => [p.left - 390, p.top + 30],
		arrow: "right-top"
	}
];
export const useCalendarTour = (props = {}) => {
	const store = useOnboardingStore();
	const tour = useTour();

	useEffect(() => {
		if (store.workflowId === TOUR_CALENDAR) {
			if (!tour.isOpen && store.currentStep === 0) {
				tour.setSteps(steps);
				browserHistory.push("/calendar");
			}

			if (!tour.isOpen && store.currentStep === 0) {
				tour.setCurrentStep(0);
				tour.setIsOpen(true);
			}

			if (store.currentStep === 1 && tour.currentStep === 0) {
				isElementLoaded(selectorForStep(1)).then(() => {
					tour.setCurrentStep(1);
				});
			}
			if (store.currentStep === 2 && tour.currentStep === 1) {
				browserHistory.push("/settings/myprofile");
				isElementLoaded(selectorForStep(2)).then(() => {
					tour.setCurrentStep(2);
				});
			}

			if (store.currentStep === 3 && tour.currentStep === 2) {
				browserHistory.push("/calendar");
				props.onClickScheduleOption?.();
				isElementLoaded(selectorForStep(3)).then(() => {
					tour.setCurrentStep(3);
				});
			}
			if (store.currentStep === 4 && tour.currentStep === 3) {
				props.onClickScheduleOption?.();
				props.onCreateEvent?.();
				isElementLoaded(selectorForStep(4)).then(() => {
					tour.setCurrentStep(4);
				});
			}
			if (store.currentStep === 5 && tour.currentStep === 4) {
				props.onClickScheduleOption?.();
				props.onCreateInterview?.();
				isElementLoaded(selectorForStep(5)).then(() => {
					tour.setCurrentStep(5);
				});
			}
			if (store.currentStep === 6 && tour.currentStep === 5) {
				props.onCreateCollaborativeGroup?.(true);
				isElementLoaded(selectorForStep(6)).then(() => {
					tour.setCurrentStep(6);
				});
			}
			if (store.currentStep === 7 && tour.currentStep === 6) {
				props.onClose?.();
				isElementLoaded(selectorForStep(7)).then(() => {
					tour.setCurrentStep(7);
				});
			}
		}
	}, [store.workflowId, store.currentStep, tour.currentStep, tour.isOpen]);
	return null;
};

export const providerConfig = {
	ContentComponent: TourContentComponent,
	onClickMask: () => null,
	className: styles.popover,
	maskClassName: styles.mask,
	padding: 0,
	scrollSmooth: true,
	disableKeyboardNavigation: true,
	Wrapper: ({ children }) => <Portal>{children}</Portal>
};
