import React, { useCallback, useState } from "react";
import loadable from "loadable-components";
import { TasksListTable } from "./components/TasksListTable";
import useGetTasksTableSettings from "./api/useGetTasksTableSettings";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import {
	API_DATA_TYPE,
	TASKS_LIST_FILTER_ID,
	TASKS_LIST_ROWS_ACTIONS,
	TASKS_PAGE_SIZES
} from "./utils/constant";
import useGetTasksList, {
	GET_TASKS_LIST_QUERY_KEY
} from "./api/useGetTasksList";
import { useInitializeTasksFilterDrawer } from "./hooks/useInitializeTasksFilterDrawer";
import { TasksListPagination } from "./components/TasksListPagination";
import PageSizeSelect from "common/PageSizeSelect/PageSizeSelect";
import styles from "./tasks-list.module.scss";
import { TasksListHead } from "./components/TasksListHead";
import TasksListSecondaryHeader from "./components/TasksListSecondaryHeader/TasksListSecondaryHeader";
import useOpenTemplateEvaluationDrawer from "./components/TaskDetailsEvaluationTemplateDrawer/hooks/useOpenTemplateEvaluationDrawer";
import useOpenTaskDetailsDrawer from "./components/TaskDetailsDrawer/hooks/useOpenTaskDetailsDrawer";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

import { queryCache } from "react-query";
import useDeleteTask from "./api/useDeleteTask";
import { useDisplayRecap } from "./hooks/useDisplayRecap";
import useMarkTaskCompleted from "./api/useMarkTaskCompleted";
import { isAllFiltersEmpty } from "common/FilterDrawer/utils";
import { TasksListTasksCounter } from "./components/TasksListCounter";
import ConfirmationModalAction from "./components/confirmationModal/ConfirmationModalAction";

const TaskDetailsEvaluationTemplateDrawer = loadable(() =>
	import("./components/TaskDetailsEvaluationTemplateDrawer")
);
const TaskDetailsDrawer = loadable(() =>
	import("./components/TaskDetailsDrawer")
);

const FilterDrawer = loadable(() => import("common/FilterDrawer/FilterDrawer"));

const TasksListFilterRecap = loadable(() =>
	import("./components/TasksListFilterRecap")
);

const DeleteTaskConfirmationModal = loadable(() =>
	import("./components/DeleteTaskConfirmationModal/DeleteTaskConfirmationModal")
);

export const getTasksListRoute = () => {
	return `/tasks`;
};
export const getTaskDetailsRoute = id => {
	return `/tasks/${id ? id : ":id"}`;
};

export const getTasksListPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const TasksList = () => {
	const {
		isOpen: isOpenTemplateEvaluation
	} = useOpenTemplateEvaluationDrawer();
	const { isOpen: isOpenTaskDetails } = useOpenTaskDetailsDrawer();
	const { setIsOpen: setTaskIsOpen, setTaskData } = useAddNewTaskStore();

	const [clickedTask, setClickedTask] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showMarkTaskCompletedModal, setShowMarkTaskCompletedModal] = useState(
		false
	);
	const { displayRecap, toggleDisplayRecap } = useDisplayRecap();
	const [deleteTask, { isLoading: isDeleting }] = useDeleteTask();
	const [
		markTaskCompleted,
		{ isLoading: isMarkingTaskCompletedLoading }
	] = useMarkTaskCompleted();
	const { data: tableSettings } = useGetTasksTableSettings();
	useInitializeTasksFilterDrawer();
	const { refetch } = useGetTasksList();
	const { getState, setLimit, setOffset, setShowDrawer } = useFilters();
	const { data: tasksList, limit, filters, showDrawer } = getState(
		TASKS_LIST_FILTER_ID
	);
	const total = tasksList?.pagination?.total;
	const size = tasksList?.pagination?.size;
	const offset = tasksList?.pagination?.offset;

	const onPageClick = page => {
		setOffset(page * size);
	};

	const onDeleteConfirm = () => {
		deleteTask(
			{
				task_id: clickedTask._id
			},
			{
				onSuccess: () => {
					queryCache.invalidateQueries(GET_TASKS_LIST_QUERY_KEY);
					setShowDeleteModal(false);
				}
			}
		);
	};

	const onActionsCellClick = useCallback((id, row) => {
		setClickedTask(row);

		if (id === TASKS_LIST_ROWS_ACTIONS.delete.key) {
			setShowDeleteModal(true);
		} else if (id === TASKS_LIST_ROWS_ACTIONS.markAsComplete.key) {
			setShowMarkTaskCompletedModal(true);
		} else if (id === TASKS_LIST_ROWS_ACTIONS.edit.key) {
			setTaskData({ ...row, ...row.initial_record });
			setTaskIsOpen(true);
		}
	}, []);

	const onMarkTaskCompletedConfirm = () => {
		markTaskCompleted(
			{
				task_id: clickedTask._id
			},
			{
				onSuccess: () => {
					queryCache.invalidateQueries(GET_TASKS_LIST_QUERY_KEY);
					setShowMarkTaskCompletedModal(false);
				}
			}
		);
	};

	return tableSettings ? (
		<div className={styles.root}>
			<TasksListHead onAddTask={() => setTaskIsOpen(true)} />
			<div className={styles.horizentalLine} />
			<TasksListSecondaryHeader
				onFilterClick={() => setShowDrawer(true)}
				columns={tableSettings.ordered_columns}
				onRecapClick={toggleDisplayRecap}
				showRecap={displayRecap}
			/>
			{displayRecap && !isAllFiltersEmpty(filters) && <TasksListFilterRecap />}
			<TasksListTasksCounter />
			<TasksListTable
				onActionsCellClick={onActionsCellClick}
				columns={tableSettings.columns}
			/>
			{total > 0 && (
				<div className={styles.containerFooter}>
					<PageSizeSelect
						limit={limit}
						setLimit={setLimit}
						refetch={refetch}
						rowsPages={TASKS_PAGE_SIZES}
						tableTag={API_DATA_TYPE}
					/>
					{total > size && (
						<TasksListPagination
							total={total}
							size={size}
							offset={offset}
							onPageClick={onPageClick}
						/>
					)}
				</div>
			)}

			{showDrawer && (
				<FilterDrawer
					displayDrawer
					onClose={() => setShowDrawer(false)}
					hideTabSaved
					onlyList
					displayAdvancedSearchHead={false}
				/>
			)}
			{showDeleteModal && (
				<DeleteTaskConfirmationModal
					onCancel={() => setShowDeleteModal(false)}
					onConfirm={onDeleteConfirm}
					isDeleteLoding={isDeleting}
				/>
			)}
			{showMarkTaskCompletedModal && (
				<ConfirmationModalAction
					onClose={() => setShowMarkTaskCompletedModal(false)}
					onClick={onMarkTaskCompletedConfirm}
					modalName={"mark-as-resolved-task"}
					title={`Are you sure you want to mark task as completed?`}
					type={"success"}
					textAction={"Yes, mark as completed"}
					loading={isMarkingTaskCompletedLoading}
					typeAction={"success"}
				/>
			)}
			{isOpenTaskDetails && <TaskDetailsDrawer />}
			{isOpenTemplateEvaluation && <TaskDetailsEvaluationTemplateDrawer />}
		</div>
	) : null;
};
export default TasksList;
